import React from 'react';
import { useTranslation } from 'react-i18next';
import {Btn, BtnCircle, BtnText, ContButton, ButtonAnchor, StyledCTA} from './ButtonSpecial.styles';
import AddIcon from '@mui/icons-material/Add';

const ButtonSpecial = ({text, linkTo, type = '', location = 'normal', theme = 'primary', onClick, target = '_blank'}) => {
  const { t } = useTranslation();
  const fnGoto = (link) => {
    onClick();
  };

  return (
    <ContButton location={location} id={'ContButton'}>
      {type === '#'
        ? (
          <Btn theme={theme} onClick={() => fnGoto(linkTo)}>
            <BtnCircle theme={theme}/>
            <BtnText>{t(text)}</BtnText>
            <AddIcon fontSize={'small'}/>
          </Btn>
          
        ) : (
          <ButtonAnchor href={linkTo}>
            <StyledCTA theme={theme}>
              <span>{t(text)}</span>
              <svg width="13px" height="10px" viewBox="0 0 13 10">
                <path d="M1,5 L11,5"></path>
                <polyline points="8 1 12 5 8 9"></polyline>
              </svg>
            </StyledCTA>
          </ButtonAnchor>
        )
      }
    </ContButton>
  )
}

export default ButtonSpecial;
