import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContInfoProcesosSeguros,
  ContProcesosSeguros,
  TextProcesosSeguros,
  TitleProcesosSeguros,
} from './ProcesosSeguros.styles';
import {Grid} from '@mui/material';
import ButtonSpecial from '../ButtonSpecial';
import {ContButton, ContImgProcesosSeguros} from '../ReduceCostos/ReduceCostos.styles';
import ImgProcesosSeguros from '../../assets/images/Imag-procesos-seguros.webp';

export const ProcesosSeguros = () => {
  const { t } = useTranslation();
  return (
    <ContProcesosSeguros className={'container-fit'} id={'ContProcesosSeguros'}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={7} order={{ xs: 2, sm: 2, md: 1 }}>
          <ContInfoProcesosSeguros>
            <TitleProcesosSeguros>
            {t('Tus procesos seguros y confiables')}
            </TitleProcesosSeguros>
            <TextProcesosSeguros>
            {t('Nos comprometemos a proporcionar una plataforma que opera bajo todas las normas de seguridad y requerimientos solicitados ')}
            <span>{t('por la Comisión Nacional Bancaria y de Valores (CNBV).')}</span>
            </TextProcesosSeguros>
            <ContButton>
              <ButtonSpecial text={'Ver más'} type={'/'} linkTo={''} theme={'primary'}/>
            </ContButton>
          </ContInfoProcesosSeguros>
        </Grid>
        <Grid item xs={12} sm={12} md={5} order={{ xs: 2, sm: 2, md: 1 }}>
          <ContImgProcesosSeguros>
            <img src={ImgProcesosSeguros} alt={'Cumplimiento normativo'}/>
          </ContImgProcesosSeguros>
        </Grid>
      </Grid>
    </ContProcesosSeguros>
  )
}
