import React from 'react';
import { useTranslation } from 'react-i18next';
import {ContCounter, ContStatisticsCounter, Counter, CounterSmall, Label, 
  Plus, Subtitle, SubtitleSmall, LabelSmall
} from './StatisticsCounter.styles';

export const StatisticsCounter = (
  {
    counter = 0,
    label = '',
    subtitle = '',
    small = false,
  }) => {
  const { t } = useTranslation();
  return (
    small === true ? (
      <ContStatisticsCounter>
        <ContCounter>
          <CounterSmall>{counter}</CounterSmall>
          <SubtitleSmall>{subtitle}</SubtitleSmall>
          <Plus>+</Plus>
        </ContCounter>
        <LabelSmall>
          {t(label)}
        </LabelSmall>
      </ContStatisticsCounter>
    ) : (
      <ContStatisticsCounter>
        <ContCounter>
          <Counter>{counter}</Counter>
          <Subtitle>{subtitle}</Subtitle>
          <Plus>+</Plus>
        </ContCounter>
        <Label>{t(label)}</Label>
      </ContStatisticsCounter>
    )
  )
}
