import styled, {createGlobalStyle} from 'styled-components';
//import {Grid} from '@mui/material';
import {GlobalColors} from '../../globals';

export const HeaderGlobalStyles = createGlobalStyle`
  .header-mobile{
    padding-top: 0 !important;
    right: 0 !important;
    @media(min-width: 300px) and (max-width: 1023px){
      display: none;
    }  
  }
`

export const ContHeaderFirst = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9;
`
export const ContHeader = styled.div`
  width: auto;
  height: 110px;
  //padding: 0 25px;
  background-color: transparent;
  // border-bottom: 1px solid ${GlobalColors.colorBorder};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  & .MuiGrid-item {
    padding-top: 0 !important;
  }

  @media(min-width: 300px) and (max-width: 1079px){
    height: 64px;
    margin: 0 20px !important;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    height: 80px;
    margin: 0 30px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    height: 80px;
    margin: 0 70px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    height: 80px;
    margin: 0 70px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    height: 80px;
    margin: 0 100px !important;
  }
  @media(min-width: 1920px) and (max-width: 3200px){
    height: 90px;
    margin: 0 200px !important;
  }
`
// export const ContHeaderInner = styled(Grid)`
export const ContHeaderInner = styled.div`
  width: 100% !important;
  height: 100% !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  flex-direction: row !important;
`
export const ContLogo = styled.div`
  width: auto;
`
export const ContMenu = styled.div`
  width: 100%;
  
  @media(min-width: 300px) and (max-width: 767px){
    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin-right: 25px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin-right: 25px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-right: 25px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-right: 25px;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    margin-right: 25px;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    margin-right: 25px;
  }
  @media(min-width: 1920px){
    margin-right: 35px;
  }
`
export const ContBtn = styled.div`
  width: auto;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  flex-direction: row !important;

  @media(min-width: 300px) and (max-width: 1023px){
    display: none !important;
  }
`
export const ContBtnMenuMobile = styled.div`
  display: none;

  @media(min-width: 300px) and (max-width: 1023px){
    display: block;
  }
`

export const ContLenguage = styled.div`
  display: block;

  @media(min-width: 300px) and (max-width: 1023px){
    display: none;
  }
`
