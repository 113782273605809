import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {ContTitleSitemap, ContTitleSitemap2, ContMapping, GlobalStylesLegals} from './Legals.styles';

export const Sitemap = () => {

  return (
    <div className={'section-first'}>
      <GlobalStylesLegals/>
      <div className={'Container'}>
        <div className={'ContainerSitemap'}>
          <ContTitleSitemap>
            Sitemap
          </ContTitleSitemap>
          <ContTitleSitemap2>
            Pages
          </ContTitleSitemap2>
          <div className='containerStylist'>
            <ContMapping id={'ContMapping'}>
              <div className="row">
                <div className="col-md-4 mt-3">
                  <div>
                    <RouterLink to={'/'} className="routerLink">Home</RouterLink>
                  </div>
                  <div className="mt-3">
                    <RouterLink to={'/beneficios'} className="routerLink">Beneficios</RouterLink>
                  </div>
                </div>
                <div className="col-md-4 mt-3">
                  <div>
                    <RouterLink to={'/contacto'} className="routerLink">Contacto</RouterLink>
                  </div>
                </div>
                <div className="col-md-4 mt-3">
                  <div>
                    <RouterLink to={'/aviso-de-privacidad'} className="routerLink">Aviso de Privacidad</RouterLink>
                  </div>
                  <div className="mt-3">
                    <RouterLink to={'/terminos-y-condiciones'} className="routerLink">Terminos y condiciones</RouterLink>
                  </div>
                </div>
                
              </div>
            </ContMapping>
          </div>
          <div className="custom-hr" />
        </div>
      </div>
    </div>
  );
};
