import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContItemClients,
  ContItemsClients,
  ContOurClients,
  TitleOurClients,
} from './OurClients.styles';
import CityCellGray from '../../assets/images/ourClients/citycell-gray.webp';
import CityCellColor from '../../assets/images/ourClients/citycell-color.webp';
import EnpagosGray from '../../assets/images/ourClients/enpagos-gray.webp';
import EnpagosColor from '../../assets/images/ourClients/enpagos-color.webp';
import PinpeoGray from '../../assets/images/ourClients/pinpeo-gray.webp';
import PinpeoColor from '../../assets/images/ourClients/pinpeo-color.webp';
import LesPagoGray from '../../assets/images/ourClients/lespago-gray.png';
import LesPagoColor from '../../assets/images/ourClients/lespago-color.png';
import AQGray from "../../assets/images/ourClients/aq-gray.png";
import AQColor from "../../assets/images/ourClients/aq-hover.png";
import FortradeGray from "../../assets/images/ourClients/fortrade-gray.png";
import FortradeColor from "../../assets/images/ourClients/fortrade-hover.png";

const ItemClient = ({imgGray = '', imgColor = '', alt = ''}) => {
  const [client, setClient] = useState(imgGray);

  return (
    <img
      src={client}
      alt={alt}
      width={'100%'} 
      height={'auto'}
      onMouseOver={() => {
        setClient(imgColor);
      }}
      onMouseOut={() => {
        setClient(imgGray);
      }}
    />
  );
};

export const OurClients = () => {
  const { t } = useTranslation();
  return (
    <ContOurClients>
      <div className={'section-first container-fit'}>
        <TitleOurClients>
          {t('Clientes')}
        </TitleOurClients>
        <ContItemsClients id={'ContItemsClients'}>
          <ContItemClients>
            <ItemClient imgGray={AQGray} imgColor={AQColor} alt={'Logo AQ'}/>
          </ContItemClients>
          <ContItemClients>
            <ItemClient imgGray={CityCellGray} imgColor={CityCellColor} alt={'Logo Citycell'}/>
          </ContItemClients>
          <ContItemClients>
            <ItemClient imgGray={FortradeGray} imgColor={FortradeColor} alt={'Logo '}/>
          </ContItemClients>
          <ContItemClients>
            <ItemClient imgGray={EnpagosGray} imgColor={EnpagosColor} alt={'Logo Enpagos'}/>
          </ContItemClients>
          <ContItemClients>
            <ItemClient imgGray={PinpeoGray} imgColor={PinpeoColor} alt={'Logo Pinpeo'}/>
          </ContItemClients>
          <ContItemClients>
            <ItemClient imgGray={LesPagoGray} imgColor={LesPagoColor} alt={'Logo Lespago'}/>
          </ContItemClients>
        </ContItemsClients>
      </div>
    </ContOurClients>
  );
};
