import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material';
import '../../globals/services-styles.css';
import {ContCampaignNotiPage, BtnOnbard, ContButton, IndentityPhoneImg, } from './CampaignNotiPage.styles';
import { IdentityCont, ContTextIdentity, TextIdentitySummary, ContCardIdentity, ContOnb, ContHowWork, TitleHowWork, ContTextDescHowWork, TextDescHowWork } from '../globalpages.styles';
import ButtonSpecial from '../../components/ButtonSpecial';
import ActionDemo from '../../components/ActionDemo/index';
import phoneIdentity from '../../assets/images/Servicios/img-notificaciones.webp';
import StepsServices from '../../components/StepsServices/index';
import ServicesIntegrations from '../../components/ServicesIntegrations/index';
import ServicesBeneficios from '../../components/ServicesBeneficios/index';
import NotificationServices from '../../assets/images/Servicios/img-noti-beneficios.webp';
import msgImage from '../../assets/images/Servicios/msg-service.webp';
import SmsImage from '../../assets/images/Servicios/sms-service.webp';
import WhatsAppImage from '../../assets/images/Servicios/whatsapp-services.webp';

const CampaignNotiItems = [
  {
    title: 'Envío de mensajes masivos en cualquier momento',
  },
  {
    title: 'Facilidad de configuración y envío',
  },
  {
    title: 'Contacto directo con tus clientes',
  },
  {
    title: 'Campaña de cobranza',
  },
  {
    title: 'Notificaciones de pago automáticas',
  }
];
const stepsCampaignNoti = [
  { number: 1, title: 'Genera tu mensaje', subtitle: 'Crea una plantilla del mensaje que deas enviar.' },
  { number: 2, title: 'Medio de pago', subtitle: 'Determina el horario.' },
  { number: 3, title: 'Condiciones del mensaje', subtitle: 'Selecciona bajo que condiciones y perfil de cliente deseas enviar el mensaje.'},
  { number: 4, title: 'Envío exitoso', subtitle: 'Realiza el envío y tus clientes recibiran el mensaje en su dispositivo.' }
];

export const CampaignNotiPage = () => {
  const { t } = useTranslation();
  const imagesToShow = [
    { src: msgImage, visible: true, alt: 'Icono email', },
    { src: WhatsAppImage, visible: true, alt: 'Logo WhatsApp', },
    { src: SmsImage, visible: true, alt: 'Icono SMS', },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ContCampaignNotiPage id={'ContCampaignNotiPage'}>

      <ContCardIdentity id={'ContCardIdentity'}>
        <IdentityCont>
          <Grid container spacing={2} className={'ContCardIdentity GridBlueCard'}>
            <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 1 }} style={{paddingLeft: '5%'}}>
              <ContOnb>
                <BtnOnbard>
                {t('Campaña de notificaciones')}
                </BtnOnbard>
              </ContOnb>
              <ContTextIdentity>
                {t('Crea una estrategia de comunicación con tus clientes')}
              </ContTextIdentity>
              <TextIdentitySummary>
                {t('Facilitamos la herramienta que te ayudará a mejorar el acercamiento con tus clientes a través de medios de mensajería directa.')}
              </TextIdentitySummary>
              <ContButton>
                <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'secondary'}/>
              </ContButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} 
              order={{ xs: 2, sm: 2, md: 2 }}
              className={'GridStylesCardImage'}
            >
              <IndentityPhoneImg src={phoneIdentity} alt={'Campaña de notificaciones Dynamicore'} visible={true} width={430}/>
            </Grid>
          </Grid>
        </IdentityCont>
      </ContCardIdentity>

      <ContHowWork id={'ContHowWork'}>
        <TitleHowWork>
          {t('¿Cómo funciona?')}
        </TitleHowWork>
        <ContTextDescHowWork>
          <TextDescHowWork>
            {t('Al generar y configurar tu campaña de notificaciones te comunicas de manera directa con tus clientes estableciendo bajo que condiciones se realiza el envío de mensajes SMS, Email y WhatsApp.')}
          </TextDescHowWork>
        </ContTextDescHowWork>

        <StepsServices steps={stepsCampaignNoti} version={2}/>

        <ContButton>
          <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'primary'}/>
        </ContButton>
      
      </ContHowWork>

      <ServicesBeneficios items={CampaignNotiItems} image={NotificationServices} alt={'Recepción de mensaje automatizado'}/>

      <ServicesIntegrations imagesToShow={imagesToShow} text={'Utiliza distintos canales de comunicación con tus clientes.'}/>

      <ActionDemo />
    </ContCampaignNotiPage>
  )
}
