import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContBtn,
  ContImgApi,
  ContInfoApi,
  ContOurApi,
  ContOurApiBack,
  GlobalStylesOurApi,
  TextApi,
  TitleApi,
} from './OurApi.styles';
import {Grid} from '@mui/material';
import Api from '../../assets/images/img-api-1.webp';
import ButtonSpecial from '../ButtonSpecial';

export const OurApi = () => {
  const { t } = useTranslation();
  return (
    <ContOurApi id={'ContOurApi'}>
      <GlobalStylesOurApi/>
      <ContOurApiBack/>
      <div className={'container-fit'}>
        <Grid container spacing={2} className={'contZIndexOurApi'} id={'contZIndexOurApi'}>
          <Grid item xs={12} sm={12} md={6}>
            <ContInfoApi>
              <TitleApi>
                {t('Conéctate con otras plataformas con nuestra API')}
              </TitleApi>
              <TextApi>
                {t('Extiende funcionalidades mediante integraciones con otras plataformas en el ecosistema tecnológico financiero con nuestra plataforma API.')}
              </TextApi>
              <ContBtn>
                <ButtonSpecial
                  text={'Ver más'}
                  type={'/'}
                  location={'/marketplace'}
                  theme={'secondary'}
                />
              </ContBtn>
            </ContInfoApi>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ContImgApi>
              <img src={Api} alt={'Sitema API Dynamicore'} width={'auto'} height={'auto'}/>
            </ContImgApi>
          </Grid>
        </Grid>
      </div>
    </ContOurApi>
  )
}
