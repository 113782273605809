import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  ContFooter, ContainerFit,
  ContTextFooterCenter, ContTextFooterLeft, ContTextFooterRight, TextFooterCont, TextFooterIcon, ContRightService,
  TextFooter, TextFooterLeft,  TextCenterPages, TextCenterPagesServices, NotitleContent, FintechLogo,
} from './FooterSitemap.styles';
import {Grid} from '@mui/material';
import { Logo } from "../Logo";
import Ubicacion from '../../assets/images/ubi-icon.png';
import Telefono from '../../assets/images/phone-icon.png';
import Fintech from '../../assets/images/fintech-logo.svg';

const FooterSitemap = () => {
  const { t } = useTranslation();
  const handleLinkClick = () => {
    // Scroll hacia arriba cuando se hace clic en un enlace
    window.scrollTo(0, 0);
  };

  return (
    <ContFooter id={'ContFooter'}>
      <ContainerFit id={'ContainerFit'}>
        <Grid container spacing={2} style={{marginLeft: '0px !important'}}>
          <Grid item xs={6} sm={6} md={3} className={'no-padding'}>
            <ContTextFooterLeft>
              <Logo />
              <TextFooterLeft id={'TextFooterLeft'}>
                <TextFooterCont>
                  <TextFooterIcon src={Ubicacion} alt='Ubicación de Dynamicore' title='ubicación' />
                  <TextFooter>Eugenia 516, Col del Valle Centro, Benito Juárez, 03100 Ciudad de México, CDMX</TextFooter>
                </TextFooterCont>
                <TextFooterCont>
                  <TextFooterIcon src={Telefono} alt='Ubicación de Dynamicore' title='ubicación' />
                  <TextFooter>(+52) 55 9297 5307</TextFooter>
                </TextFooterCont>
              </TextFooterLeft>
              <FintechLogo src={Fintech} alt='Fintech México' />
            </ContTextFooterLeft>
          </Grid>
          <Grid item xs={6} sm={6} md={5} className={'no-padding'}>
            <ContTextFooterCenter id='ContTextFooterCenter'>
              <Grid container spacing={0}>
                <Grid item xs={6} sm={6} md={6} order={{ xs: 2, sm: 2, md: 1 }} className={'no-padding'}>
                  
                </Grid>
                <Grid item xs={6} sm={6} md={6} order={{ xs: 1, sm: 1, md: 2 }} className={'no-padding'}>
                  <div style={{textAlign: 'start', justifyContent: 'center', paddingLeft: '60px'}}>
                    <div style={{alignContent: 'flex-start', padding: '20px 0px 15px 0'}}>
                      <Link to="/" onClick={handleLinkClick} style={{textDecoration: 'none', color: 'inherit'}}> 
                        <TextCenterPages>Home</TextCenterPages>
                      </Link>
                    </div>
                    <div style={{alignContent: 'flex-start', padding: '20px 0px 15px 0'}}>
                      <Link to="/nosotros" onClick={handleLinkClick} style={{textDecoration: 'none', color: 'inherit'}}> 
                        <TextCenterPages>
                        {t('Nosotros')}
                        </TextCenterPages>
                      </Link>
                    </div>
                  </div>
                  <div style={{textAlign: 'start', justifyContent: 'center', paddingLeft: '60px'}}>
                    <div style={{alignContent: 'center',  padding: '20px 0px 15px 0'}}>
                      <Link to="/beneficios" onClick={handleLinkClick} style={{textDecoration: 'none', color: 'inherit'}}> 
                        <TextCenterPages>{t('Beneficios')}  </TextCenterPages>
                      </Link>
                    </div>
                    <div style={{alignContent: 'center',  padding: '20px 0px 0px 0'}}>
                      <TextCenterPages href="https://blog.dynamicore.io/" target="_blank" rel="noopener noreferrer">
                         Blog
                      </TextCenterPages>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </ContTextFooterCenter>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className={'no-padding'}>
          <ContTextFooterRight id='ContTextFooterRight'>
              <Grid container spacing={0}>
                <Grid item xs={6} sm={6} md={6} className={'no-padding'}>
                  <div style={{textAlign: 'start', justifyContent: 'center'}}>
                    <div style={{alignContent: 'flex-start', padding: '20px 0px 15px 0'}}>
                      <TextCenterPages>{t('Servicios')}</TextCenterPages>
                    </div>
                    <div style={{display: 'block'}}>
                      <TextCenterPagesServices>
                        {t('Administracion de clientes')}
                      </TextCenterPagesServices>
                      <TextCenterPagesServices>
                        <Link to="/onboarding" onClick={handleLinkClick} style={{textDecoration: 'none', color: 'inherit'}}> 
                        {t('Onboarding Digital')}
                        </Link>
                      </TextCenterPagesServices>
                      <TextCenterPagesServices>
                        <Link to="/analisis-crediticio" onClick={handleLinkClick} style={{textDecoration: 'none', color: 'inherit'}}> 
                        {t('Análisis de crédito')}
                        </Link>
                      </TextCenterPagesServices>
                      <TextCenterPagesServices>
                        {t('Administración de Productos')}
                      </TextCenterPagesServices>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6} className={'no-padding'}>
                  <ContRightService>
                    <NotitleContent>
                      <TextCenterPages></TextCenterPages>
                    </NotitleContent>
                    <div style={{display: 'block'}}>
                      <TextCenterPagesServices>
                        {t('Automatización de Operaciones')}
                      </TextCenterPagesServices>
                      <TextCenterPagesServices>
                        <Link to="/dispersion-pagos" onClick={handleLinkClick} style={{textDecoration: 'none', color: 'inherit'}}> 
                          {t('Dispersión')}
                        </Link>
                      </TextCenterPagesServices>
                      <TextCenterPagesServices>
                        <Link to="/cobranza" onClick={handleLinkClick} style={{textDecoration: 'none', color: 'inherit'}}> 
                          {t('Cobranza')}
                        </Link>
                      </TextCenterPagesServices>
                      <TextCenterPagesServices>{t('Amplia tu oferta')}</TextCenterPagesServices>
                      <TextCenterPagesServices>
                        {t('Reportes')}
                      </TextCenterPagesServices>
                    </div>
                  </ContRightService>
                </Grid>
              </Grid>
            </ContTextFooterRight>
          </Grid>
        </Grid>
      </ContainerFit>
      <script type="text/javascript">
      {`
        _linkedin_partner_id = "5799980";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        </script><script type="text/javascript">
        (function(l) {
        if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
        window.lintrk.q=[]}
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);})(window.lintrk);
      `}
      </script>
      <noscript>
        <img height="1" width="1" style={{ display: 'none' }} alt="" src="https://px.ads.linkedin.com/collect/?pid=5799980&fmt=gif" />
      </noscript>
    </ContFooter>
  );
};

export default FooterSitemap;
