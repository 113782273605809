import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContDatatoggle = styled.div`
  width: 100%;
  height: auto;
  background: #FFFFFF;
  z-index: 4;
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  vertical-align: middle;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 25px;
  //border: 2px solid #CEE1F8;
  padding: 0px 10px;
  &:hover {
    background-color: #CEE1F8;
  }
`;

export const Icon = styled.div`
  margin-right: 10px;
  & > img {
    width: 40px;
    height: 40px;
  }
`;

export const Text = styled.div`
  font-family: ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorPrimaryDark};
  font-size: 14px;
  text-align: left;
`;