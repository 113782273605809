export default {
  '¡Somos mejor que un core bancario!': 'We are better than Banking Core!',
  'Te ayudamos a integrar procesos automatizados y productos financieros digitales.': 'We assist you in integrating automated processes and digital financial products.',
  'Términos y condiciones': 'Terms and Conditions',
  'Aviso de privacidad': 'Privacy Notice',
  'Administracion de clientes': 'Customer administration',
  'Análisis de crédito': 'Credit analysis',
  'Administración de Productos': 'Product Management',
  'Automatización de Operaciones': 'Operations Automation',
  'Dispersión': 'Dispersion',
  'Cobranza': 'Payments',
  'Amplia tu oferta': 'Expand your offer',
  'Reportes': 'Reports',
};