import styled from 'styled-components';

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto; /* Centrar el contenedor horizontalmente */
  position: relative;
`;
export const ImagesPack = styled.img`
  width: 90%;
  height: auto;
  margin: 0 auto;

  @media(min-width: 100px) and (max-width: 767px){
    display: none;
  }

  @media (min-width: 768px) and (max-width: 4000px) {
    display: block; 
  }

  //@media(min-width: 100px) and (max-width: 767px){
  //  display: none;
  //}
`
export const Image1 = styled.img`
  width: 234.11px;
  height: 291.13px;
  margin: 80px 10px 0 20px;

  @media(min-width: 300px) and (max-width: 349px){
    width: 154.11px;
    height: 211.13px;
    margin: 80px 10px 0 0px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media(min-width: 350px) and (max-width: 399px){
    width: 164.11px;
    height: 221.13px;
    margin: 0px 10px 0 0px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media(min-width: 400px) and (max-width: 419px){
    width: 194.11px;
    height: 251.13px;
    margin: 0px 10px 0 0px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media(min-width: 420px) and (max-width: 499px){
    width: 204.11px;
    height: 261.13px;
    margin: 0px 10px 0 0px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media(min-width: 500px) and (max-width: 599px){
    width: 204.11px;
    height: 261.13px;
    margin: 80px 10px 0 0px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media(min-width: 600px) and (max-width: 767px){
    width: 244.11px;
    height: 301.13px;
    margin: 80px 10px 0 0px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media(min-width: 768px) and (max-width: 10000px){
    display: none !important;
  }
`;

export const Image2 = styled.img`
  width: 234.11px;
  height: 291.13px;
  margin: 20px 20px 0 20px;

  @media(min-width: 300px) and (max-width: 349px){
    width: 154.11px;
    height: 211.13px;
    margin: 20px 0px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }

  @media(min-width: 350px) and (max-width: 399px){
    width: 164.11px;
    height: 221.13px;
    margin: -20px 0px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }

  @media(min-width: 400px) and (max-width: 419px){
    width: 194.11px;
    height: 251.13px;
    margin: -30px 0px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }

  @media(min-width: 420px) and (max-width: 499px){
    width: 204.11px;
    height: 261.13px;
    margin: -30px 0px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }

  @media(min-width: 500px) and (max-width: 599px){
    width: 204.11px;
    height: 261.13px;
    margin: 20px 10px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }
  @media(min-width: 600px) and (max-width: 767px){
    width: 244.11px;
    height: 301.13px;
    margin: 20px 10px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }
  @media(min-width: 768px) and (max-width: 10000px){
    display: none !important;
  }
`;

export const Image3 = styled.img`
  width: 234.11px;
  height: 291.13px;
  margin: 120px 20px 0 20px;

  @media(min-width: 100px) and (max-width: 767px){
    display: none;
  }
  @media(min-width: 768px) and (max-width: 819px){
    width: 164.11px;
    height: 221.13px;
    margin: 120px 10px 0 10px;
  }
  @media(min-width: 820px) and (max-width: 889px){
    width: 184.11px;
    height: 241.13px;
    margin: 120px 10px 0 10px;
  }
  @media(min-width: 890px) and (max-width: 1023px){
    width: 204.11px;
    height: 261.13px;
    margin: 120px 10px 0 10px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 234.11px;
    height: 291.13px;
    margin: 120px 10px 0 10px;
  }
  @media(min-width: 1080px) and (max-width: 1120px){
    width: 244.11px;
    height: 301.13px;
    margin: 120px 10px 0 10px;
  }
  @media(min-width: 1121px) and (max-width: 1150px){
    width: 244.11px;
    height: 301.13px;
    margin: 120px 15px 0 15px;
  }
  @media(min-width: 1151px) and (max-width: 1279px){
    width: 244.11px;
    height: 301.13px;
    margin: 120px 20px 0 20px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 254.11px;
    height: 301.13px;
    margin: 120px 20px 0 20px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 264.11px;
    height: 321.13px;
    margin: 120px 20px 0 20px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 264.11px;
    height: 321.13px;
    margin: 120px 20px 0 20px;
  }
`;

export const Image4 = styled.img`
  width: 234.11px;
  height: 291.13px;
  margin: 0px 20px 0 20px;
  
  @media(min-width: 100px) and (max-width: 767px){
    display: none;
  }
  @media(min-width: 768px) and (max-width: 819px){
    width: 164.11px;
    height: 221.13px;
    margin: 0px 10px 0 10px;
  }
  @media(min-width: 820px) and (max-width: 889px){
    width: 184.11px;
    height: 241.13px;
    margin: 0px 10px 0 10px;
  }
  @media(min-width: 890px) and (max-width: 1023px){
    width: 204.11px;
    height: 261.13px;
    margin: 0px 10px 0 10px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 234.11px;
    height: 291.13px;
    margin: 0px 10px 0 10px;
  }
  @media(min-width: 1080px) and (max-width: 1120px){
    width: 244.11px;
    height: 301.13px;
    margin: 0px 10px 0 10px;
  }
  @media(min-width: 1121px) and (max-width: 1150px){
    width: 244.11px;
    height: 301.13px;
    margin: 0px 15px 0 15px;
  }
  @media(min-width: 1151px) and (max-width: 1279px){
    width: 244.11px;
    height: 301.13px;
    margin: 0px 15px 0 20px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 244.11px;
    height: 301.13px;
    margin: 0px 20px 0 20px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 264.11px;
    height: 321.13px;
    margin: 0px 20px 0 20px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 264.11px;
    height: 321.13px;
    margin: 0px 20px 0 20px;
  }
`;
