import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import Back1 from '../assets/images/back-home-1.webp';
import SEO from '../components/SEO';
import ScoringPage from '../pages/ScoringPage';

export const Scoring = () => {
  const { t } = useTranslation();
  return (
    <Layout backgroundimage={Back1} actionDemo={false}>
      <SEO
        title="Score Alternativo: Evaluación Integral de Riesgo Crediticio"
        description={t('Descubre cómo un score alternativo optimiza la evaluación de riesgo crediticio y te brinda nuevas oportunidades financieras.')}
        works={t('Core Financiero, Fintech, Onboardig digital, Aprobación de crédito, Gestión de cobranza, Análisis de crédito, Core Bancario, Automatización de procesos, Procesos digitales, Producto financiero, Servicios financieros, Digitalización de procesos, Software financiero, Dispersión, Reportes automatizados, Corebanking, Sector financiero, SOFIPO, SOFOM, CNBV, Condusef, Servicios financieros digitales, Plataforma financiera')}
        bolImage={false}
        ogImage={`dynamicore-preview.png`}
        twitterImage={`dynamicore-preview.png`}
        canonicalUrl="https://dynamicore.io/score-alternativo"
      />
      <ScoringPage/>
    </Layout>
  )
}