import * as Yup from 'yup';

export const dataClient = Yup
  .object()
  .shape({
    name: Yup
      .string()
      .required('Este campo es requerido'),
    company: Yup
      .string()
      .required('Este campo es requerido'),
    city: Yup
      .string()
      .required('Este campo es requerido'),
    email: Yup
      .string()
      .email('Ingrese un email valido')
      .trim()
      .lowercase()
      .required('Este campo es requerido'),
    phone: Yup
      .string('Ingrese un número valido')
      //.matches(/^[0-9]+$/)
      //.length(12)
      .required('Este campo es requerido'),
    typeInstitution: Yup
      .string()
      .required('Seleccione una opción'),
    interest: Yup
      .string()
      .required('Seleccione una opción'),
    message: Yup
      .string()
      .required('Este campo es requerido'),
    check_nocredit: Yup
      .bool()
      .test('isRequired', 'Este campo es requerido', value => value === true),
  });
