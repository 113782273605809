import React from 'react';
import {Helmet} from "react-helmet";
import Layout from '../components/Layout';
import Back1 from '../assets/images/back-home-1.webp';
//import SEO from '../components/SEO';
import CobranzaPage from '../pages/CobranzaPage';

export const Cobranza = () => {

  const metaCia = 'Dynamicore';
  const metaUrl = '';
  const metaTwitterSite = '';
  const metaTwitterCreator = '';

  const title = '';
  const description = '';
  const lang = 'es_MX';
  const meta = {}; 
  const onlytitle = false;

  //const metaOgImage      = bolImage ? metaUrl + '/images/metas/' + 'beneficios_f.jpg' : 'beneficios_f.jpg';
  //const metaTwitterImage = bolImage ? metaUrl + '/images/metas/' + 'beneficios_t.jpg' : 'beneficios_t.jpg';

  return (
    <Layout backgroundimage={Back1} actionDemo={false}>
      {/*<SEO
        //title="Cobranza"
        //description="Automatiza procesos financieros"
        //works='Core Financiero, Fintech, Onboardig digital, Aprobación de crédito, Gestión de cobranza, Análisis de crédito, Core Bancario, Automatización de procesos, Procesos digitales, Producto financiero, Servicios financieros, Digitalización de procesos, Software financiero, Dispersión, Reportes automatizados, Corebanking, Sector financiero, SOFIPO, SOFOM, CNBV, Condusef, Servicios financieros digitales, Plataforma financiera'
        bolImage={false}
        ogImage={`beneficios_f.jpg`}
        //twitterImage={`beneficios_t.jpg`}
        //canonicalUrl="https://dynamicore.io/cobranza"
      />*/}

    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={onlytitle ? `Gestión de Cobranza Eficiente para Instituciones Financieras` : `Gestión de Cobranza Eficiente para Instituciones Financieras | ${metaCia}`}
      titleTemplate={onlytitle ? `Gestión de Cobranza Eficiente para Instituciones Financieras` : `Gestión de Cobranza Eficiente para Instituciones Financieras | ${metaCia}`}
      meta={[
        {
          name: `description`,
          content: 'Cobranza',
        },
        {
          name: `robots`,
          content: 'all',
        },
        {
          name: `lang`,
          content: 'es',
        },
        {
          property: `keywords`,
          content: 'Core Financiero, Fintech, Onboardig digital, Aprobación de crédito, Gestión de cobranza, Análisis de crédito, Core Bancario, Automatización de procesos, Procesos digitales, Producto financiero, Servicios financieros, Digitalización de procesos, Software financiero, Dispersión, Reportes automatizados, Corebanking, Sector financiero, SOFIPO, SOFOM, CNBV, Condusef, Servicios financieros digitales, Plataforma financiera',
        },
        {
          property: `og:title`,
          content: `${title} | ${metaCia}`,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: `${title} | ${metaCia}`,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:local`,
          content: 'en_US',
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${metaCia}`,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:url`,
          content: metaUrl,
        },
        {
          name: `twitter:site`,
          content: metaTwitterSite,
        },
        {
          name: `twitter:creator`,
          content: metaTwitterCreator,
        },
      ].concat(meta)}
    >
      <meta name="description" content={'Automatiza procesos financieros'} />
      <meta name="keywords" content={'Core Financiero, Fintech, Onboardig digital, Aprobación de crédito, Gestión de cobranza, Análisis de crédito, Core Bancario, Automatización de procesos, Procesos digitales, Producto financiero, Servicios financieros, Digitalización de procesos, Software financiero, Dispersión, Reportes automatizados, Corebanking, Sector financiero, SOFIPO, SOFOM, CNBV, Condusef, Servicios financieros digitales, Plataforma financiera'} />
      <meta name="robots" content='INDEX, FOLLOW, MAX-SNIPPET:-1, MAX-VIDEO-PREVIEW:-1, MAX-IMAGE-PREVIEW:LARGE' />
      <meta name="author" content={'Dynamicore'} />
      <link rel="canonical" href={'https://dynamicore.io/cobranza'} />
      <script src="https://www.googletagmanager.com/gtag/js?id=AW-10979614629"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-10979614629');
        `}
      </script>
      <script> 
        {`
          gtag('event', 'conversion', {'send_to': 'AW-10979614629/hJ5gCMCPpZgYEKW_vvMo'}); 
        `}
      </script>
      <script>
        {`
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3476732,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </script>
      
      <script>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NXK7DRW');
        `}
      </script>
    </Helmet>

      <CobranzaPage/>
    </Layout>
  )
}