import React from 'react';
import CoockieImg from '../../assets/images/cookie.webp';
import { CookieBannerWrapper, ContBanner, TitleBanner, IcoBanner, SubTitleBanner, ContButtons, CookieButton, } from "./CookieBanner.styles";


const CookieBanner = ({ acceptCookie, rejectCookie }) => {
 
  const acceptCookies = () => {
    acceptCookie();
  };

  const rejectCookies = () => {
    rejectCookie();
  };

  return (
      <CookieBannerWrapper>
        <ContBanner>
          <TitleBanner>
            <IcoBanner src={CoockieImg} alt={'Coockie Imagen'} />
            Esta web almacena cookies en tu ordenador.
          </TitleBanner>
          <SubTitleBanner>
            Estas cookies se utilizan para mejorar tu experiencia y proporcionar servicios más personalizados, 
            tanto en esta web como a través de otros medios. Para saber más sobre las 
            cookies que utilizamos, consulta nuestra Política de Cookies.
          </SubTitleBanner>
          <ContButtons>
            <CookieButton onClick={acceptCookies} type={'primary'}>Aceptar</CookieButton>
            <CookieButton onClick={rejectCookies} type={'secondary'}>Declinar</CookieButton>
          </ContButtons>
        </ContBanner>
      </CookieBannerWrapper>
  );
};

export default CookieBanner;
