import styled from 'styled-components';

export const SemiTransparentBackgroundMain = styled.div`
  background-color: rgba(0, 0, 0, 0.25); /* Negro con opacidad 0.25 */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  //backdrop-filter: blur(10px); /* Efecto de desenfoque en el fondo */
`;