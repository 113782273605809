import styled from 'styled-components';
//import {GlobalColors, GlobalFonts} from '../../globals';

export const CarouselContainer = styled.div`
  max-width: 100%; /* Ajusta el ancho según tus necesidades */
  margin: 0 auto;
`;

export const CarouselImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-right: 20px;
  opacity: 0.4;
`;
