import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import Home from '../components/Home';
import Back1 from '../assets/images/back-home-1.webp';
import MarketPlaceSummary from '../components/MarketPlaceSummary';
import TransformationStartsNow from '../components/TransformationStartsNow';
import Statistics from '../components/Statistics';
import OurApi from '../components/OurApi';
import OurClients from '../components/OurClients';
import SEO from '../components/SEO';
import SemiTransparentBackground from '../components/SemiTransparentBackground';

export const Index = () => {
  const { t } = useTranslation();
  return (
    <Layout backgroundimage={Back1} actionDemo={true}>
      <SemiTransparentBackground /> 
      <SEO
        title="Core Bancario"
        metaCia='Soluciones Digitales para Entidades Financieras'
        description={t('Descubre nuestra plataforma de core bancario digital diseñada para optimizar los procesos, automatizar operaciones y digitalizar productos financieros.')}
        works={t('Core Financiero, Fintech, Onboardig digital, Aprobación de crédito, Gestión de cobranza, Análisis de crédito, Soluciones financieras, Automatización de procesos, Procesos digitales, Producto financiero, Servicios financieros, Digitalización de procesos, Software financiero, Dispersión, Reportes automatizados, Corebanking, Sector financiero, SOFIPO, SOFOM, CNBV, Condusef, Servicios financieros digitales, Plataforma financiera')}
        bolImage={false}
        ogImage={`dynamicore-preview.png`}
        twitterImage={`dynamicore-preview.png`}
        canonicalUrl="https://dynamicore.io/"
      />
      <Home/>
      <MarketPlaceSummary/>
      <TransformationStartsNow/>
      <Statistics/>
      <OurApi/>
      <OurClients/>
    </Layout>
  )
}
