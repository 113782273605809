import React, {useState, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import {ContBtnLanguage, ContLanguage, ContGLobe, ContArrowLenguage} from './Language.styles';
import GlobeIcon from '../../assets/images/globe-icon.png';
import ExpandIcon from '../../assets/images/expand-lenguage-icon.png';
import {
  Popover,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

const Language = () => {
  const { i18n } = useTranslation();
  const anchorEl = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = function () {
    setOpen(true);
  };

  const handleClose = function () {
    setOpen(false);
  };

  const handleChangeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    handleClose();
  };
  return (
    <>
      <ContBtnLanguage onClick={handleOpen} ref={anchorEl}>
        <ContLanguage>
          <ContGLobe>
            <img src={GlobeIcon} alt={'Globe icon'} width={30} />
          </ContGLobe>
          <ContArrowLenguage>
            <img src={ExpandIcon} alt={'arrow expand'} />
          </ContArrowLenguage>
        </ContLanguage>
      </ContBtnLanguage>

      <Popover
        anchorEl={anchorEl.current}
        anchorPosition={{ top: 20 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
        open={isOpen}
      >
        <List disablePadding>
          <ListItem onClick={() => handleChangeLanguage('en')} style={{cursor: 'pointer'}}>
            <ListItemText primary="English" />
          </ListItem>
          <ListItem onClick={() => handleChangeLanguage('es')} style={{cursor: 'pointer'}}>
            <ListItemText primary="Español" />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default Language;
