import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import Back1 from '../assets/images/back-home-1.webp';
import SEO from '../components/SEO';
import ServiciosPage from '../pages/ServiciosPage';

export const Servicios = () => {
  const { t } = useTranslation();
  return (
    <Layout backgroundimage={Back1} actionDemo={false}>
      <SEO
        title="Onboarding Digital Eficiente para Entidades Financieras"
        description={t('Descubre cómo nuestra solución de onboarding digital agiliza el proceso de incorporación de clientes, mejorando la experiencia y la seguridad.')}
        bolImage={false}
        works={t('Core Financiero, Fintech, Onboardig digital, Aprobación de crédito, Gestión de cobranza, Análisis de crédito, Core Bancario, Automatización de procesos, Procesos digitales, Producto financiero, Servicios financieros, Digitalización de procesos, Software financiero, Dispersión, Reportes automatizados, Corebanking, Sector financiero, SOFIPO, SOFOM, CNBV, Condusef, Servicios financieros digitales, Plataforma financiera')}
        ogImage={`dynamicore-preview.png`}
        twitterImage={`dynamicore-preview.png`}
        canonicalUrl="https://dynamicore.io/onboarding"
      />
      <ServiciosPage/>
    </Layout>
  )
}