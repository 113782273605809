import React, { useState, useEffect } from 'react';
//import posthog from 'posthog-js';
import { SemiTransparentBackgroundMain } from "./SemiTransparentBackground.styles";
import CookieBanner from '../CookieBanner';

const SemiTransparentBackground = () => {
  //const [acceptedCookies, setAcceptedCookies] = useState(
  //  localStorage.getItem('cookiesAccepted') === 'true'
  //);

  const [showBanner, setShowBanner] = useState(true)

  const acceptCookies = () => {
    document.cookie = "cookiesAccepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
    // Tu lógica para aceptar cookies aquí
    setShowBanner(false);
  };

  const rejectCookies = () => {
    document.cookie = "cookiesAccepted=false; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
    // Tu lógica para rechazar cookies aquí
    setShowBanner(false);
  };

  useEffect(() => {
    // Lee la cookie "cookiesAccepted" al cargar la página.
    const cookiesAccepted = document.cookie
      .split(';')
      .map(cookie => cookie.trim())
      .find(cookie => cookie.startsWith('cookiesAccepted='));

    // Si la cookie existe y su valor es "true" o "false", oculta el banner.
    if (cookiesAccepted) {
      const value = cookiesAccepted.split('=')[1];
      if (value === 'true' || value === 'false') {
        setShowBanner(false);
      }
    }
  }, []); // El segundo argumento [] asegura que esto solo se ejecute una vez al cargar el componente.


  return (
    <>
    {showBanner && (
      <>
        <SemiTransparentBackgroundMain>
          <CookieBanner acceptCookie={acceptCookies} rejectCookie={rejectCookies} />
        </SemiTransparentBackgroundMain>
      </>
    )}
    </>
  );
};

export default SemiTransparentBackground;
