import React, {useEffect} from 'react';
import {
  ContStatistics,
  ContStatisticsBack,
  TextStatistics,
  TitleStatistics,
  ContStatisticsCounters,
} from './Thankspage.styles';
import ButtonSpecial from '../../components/ButtonSpecial';

export const Thankspage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ContStatisticsBack>
      <div className={'section-first container-fit'}>
        <ContStatistics id={'ContStatistics'}>
          <TitleStatistics>
            ¡Gracias por proporcionarnos sus datos!
          </TitleStatistics>
          <TextStatistics>
            Valoramos su interés en nuestros servicios. 
            Hemos recibido su solicitud y nos pondremos en contacto.
          </TextStatistics>
          <ContStatisticsCounters>
          <ButtonSpecial text={'Ir a inicio'} type={'/'} linkTo={'/'} target={'_self'}/>
          </ContStatisticsCounters>
        </ContStatistics>
      </div>
    </ContStatisticsBack>
  );
};
