import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';
//import backImage from '../../assets/images/use-caseform-back.png';

export const ContUseCasesPage = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media(min-width: 0px) and (max-width: 650px){
    padding-top: 70px !important;
    padding-left: 1px;
    padding-right: 1px;
  }

  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 70px !important;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 100px !important;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding-top: 100px !important;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    padding-top: 100px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding-top: 100px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding-top: 110px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding-top: 110px !important;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    padding-top: 110px !important;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    padding-top: 110px !important;
  }
  @media(min-width: 1920px){
    padding-top: 130px !important;
  }
`;

export const ContInfoUseCasesPage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 60px;
  background: white;
  border-radius: 50px;
  padding-bottom: 30px;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0px 15px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`

export const ContBtnTitle = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;

  @media(min-width: 300px) and (max-width: 767px){
    margin-top: 20px;
  }
  @media(min-width: 768px) and (max-width: 1024px){
    margin-top: 20px;
  }
`;
export const ContBtnTitle2 = styled.div`
  margin: 30px 0;
  align-items: center;
  justify-content: center;
`;
export const ContSecondaryBtnTitle = styled.div`
  width: 15%;
  margin: 0 auto;
  @media(min-width: 300px) and (max-width: 1024px){
    width: 35%;
  }
`;
export const BtnTitle = styled.div`
  padding: 10px 20px;
  background-color: ${(props) => (props.secondary ? '#6DA6EB' : '#0B6BDD')};
  color: #FFFFFF;
  border-radius: 30px;
  font-family: ${GlobalFonts.fontBold};
  font-size: 15px;
  text-align: center;
  align-items: center;
`;
export const DescUseCases = styled.div`
  width: 90%;
  margin-bottom: 30px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${GlobalColors.colorText};

  z-index: 1;
  
  & > span {
    font-family: ${GlobalFonts.fontBold};
  }

  @media(min-width: 200px) and (max-width: 599px){
    width: 90%;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
  @media(min-width: 600px) and (max-width: 767px){
    width: 90%;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 90%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 90%;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 90%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 90%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 90%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 90%;
  }
  @media(min-width: 1920px){
    width: 90%;
  }
`;

export const ContButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 20px;

  @media(min-width: 200px) and (max-width: 599px){
    margin-bottom: 10px;
    justify-content: center;
  }
  @media(min-width: 600px) and (max-width: 767px){
    margin-bottom: 10px;
    justify-content: start;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 35px;
    justify-content: center;
  }
`;

export const ContainerResolve = styled.div`
  width: 100%;
  background: #0C3A72;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: 30px 25px;
  margin-bottom: 60px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  @media(min-width: 0px) and (max-width: 600px){
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;

export const ContainerResolveTitle = styled.div`
  
`

export const ContModulesTabs = styled.div`
  margin-top: 20px;
`;

export const ContainerCarrousel = styled.div`
  height: auto;
  margin-top: 20px;
  margin-bottom: ${(props) => (props.marginBottom ? '30px' : '0px')};
`;

export const ContInfoModulesPage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  border-radius: 50px;
  margin-bottom: 60px;
  padding: 10px 0px;

  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    display: flex;
    align-items: cneter;
    justify-content: center;
    margin-bottom: 20px;
  }
`;
export const TiltleModules = styled.div`
  font: 40px ${GlobalFonts.fontBold};
  color: ${(props) => (props.white ? '#FFFFFF' : '#000000')};
  text-align: center;
  margin-top: 30px;
  margin-botom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  width: ${(props) => (props.width ? `${props.width}%` : '100%')};
`;
export const TiltleModules2 = styled.h2`
  font: 40px ${GlobalFonts.fontBold};
  color: ${(props) => (props.white ? '#FFFFFF' : '#000000')};
  text-align: center;
  margin-top: 30px;
  width: ${(props) => (props.width ? `${props.width}%` : '100%')};
  margin-botom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  @media(min-width: 200px) and (max-width: 500px){
    font: 30px ${GlobalFonts.fontBold};
  }
`;

export const TiltleModules3 = styled.h3`
  font: 40px ${GlobalFonts.fontBold};
  color: ${(props) => (props.white ? '#FFFFFF' : '#000000')};
  text-align: center;
  margin-top: 30px;
  width: ${(props) => (props.width ? `${props.width}%` : '100%')};
  margin-botom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  @media(min-width: 200px) and (max-width: 500px){
    font: 30px ${GlobalFonts.fontBold};
  }
`;
export const TiltleModules4 = styled.h4`
  font: 40px ${GlobalFonts.fontBold};
  color: ${(props) => (props.white ? '#FFFFFF' : '#000000')};
  text-align: center;
  margin-top: 30px;
  width: ${(props) => (props.width ? `${props.width}%` : '100%')};
  margin-botom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  @media(min-width: 200px) and (max-width: 500px){
    font: 30px ${GlobalFonts.fontBold};
  }
`;
export const TiltleModules5 = styled.h5`
  font: 48px ${GlobalFonts.fontBold};
  line-height: 45px;
  color: ${(props) => (props.white ? '#FFFFFF' : '#000000')};
  text-align: center;
  margin-top: 30px;
  width: ${(props) => (props.width ? `${props.width}%` : '100%')};
  margin-botom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  @media(min-width: 200px) and (max-width: 500px){
    font: 30px ${GlobalFonts.fontBold};
  }
`;
export const TiltleModules6 = styled.h6`
  font: 40px ${GlobalFonts.fontBold};
  color: ${(props) => (props.white ? '#FFFFFF' : '#000000')};
  text-align: center;
  margin-top: 30px;
  width: ${(props) => (props.width ? `${props.width}%` : '100%')};
  margin-botom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  @media(min-width: 200px) and (max-width: 500px){
    font: 30px ${GlobalFonts.fontBold};
  }
`;
export const SubTiltleModules = styled.div`
  font: 20px ${GlobalFonts.fontRegular};
  color: ${(props) => (props.white ? '#FFFFFF' : '#282828')};
  text-align: center;
  margin-top: 20px;
  width: ${(props) => (props.width ? `${props.width}%` : '100%')};
`;

export const ContIntegrations = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 60px;
  padding: 20px 0px;

  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
`;

export const ContItemsClients = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
`

export const ContCreditPersonal = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  border-radius: 50px;
  margin-bottom: 60px;
  padding: 20px 0px;

  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    display: flex;
    align-items: center
    justify-content: center;
    margin-bottom: 20px;
  }
`;

export const ContainerColumnsCredit = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const Column = styled.div`
  flex: 1;
`;

export const ListItem = styled.li`
  list-style-type: disc;
  margin: 5px 0;
  color: #282828;
  font: 20 ${GlobalFonts.fontRegular};
`;

export const ContTexts = styled.div`
  display: block;
  text-align: left;
  padding-left: 15%;
  @media(min-width: 0px ) and (max-width: 900px) {
    padding-left: 5%;
  }
`;

export const ContTextsSection = styled.div`
  margin: 30px 0;
`;

export const ContHyperTitle = styled.div`
  display: flex;
  text-align: left;
  vertical-align: middle;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const HyperImage = styled.img`
  width: 32px;
  height: 32px;
`;

export const LinkTextCredit = styled.div`
  text-decoration: none;
  font: 28px ${GlobalFonts.fontBold};
  cursor: pointer;
  color: #282828;
`

export const DescriptionCredit = styled.div`
  font-family: ${GlobalFonts.fontRegular};
  font-size: 18px;
  font-weight: 400;
  color: #282828;
  line-height: 25px;
  @media(min-width: 900px ) and (max-width: 1023px) {
    font-size: 14px;
    line-height: 18px;
  }
  @media(min-width: 1024px) and (max-width: 1099px){
    font-size: 14px;
    line-height: 20px;
  }
  @media(min-width: 1100px) and (max-width: 1200px){
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ContImgCredit = styled.div`
  padding: 0 10px;
  text-align: center;
  vertical-align: middle;
`;

export const ImageCredit = styled.img`
  width: 80%;
  @media(min-width: 900px) and (max-width: 979px) {
    width: 85%;
    height: 550px;
  }
  @media(min-width: 980px) and (max-width: 1023px) {
    width: 85%;
    height: 530px;
  }
  @media(min-width: 1024px) and (max-width: 1085px) {
    height: 560px;
  }
  @media(min-width: 1086px) and (max-width: 1099px) {
    height: 530px;
  }
  @media(min-width: 1100px) and (max-width: 1103px) {
    height: 580px;
  }
  @media(min-width: 1104px) and (max-width: 1200px) {
    height: 550px;
  }
  @media(min-width: 1201px) and (max-width: 1227px) {
    height: 650px;
  }
  @media(min-width: 1228px) and (max-width: 1299px) {
    height: 630px;
  }
  @media(min-width: 1300px) and (max-width: 1399px) {
    height: 600px;
  }
  @media(min-width: 1400px) and (max-width: 1425px) {
    height: 650px;
  }
  @media(min-width: 1426px) and (max-width: 1500px) {
    height: 600px;
  }
    @media(min-width: 1501px) and (max-width: 1600px) {
    height: 590px;
  }
`;

export const ContButtonsCompanies = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
  @media(min-width: 0px) and (max-width: 767px){
    display: block;
  }
  @media (max-width: 1119px) {
    flex-direction: column;
  }
`;

export const ButtonCompanie = styled.button`
  display: flex;
  align-items: center;
  padding: 12px 30px;
  padding: 12px 30px; /* Espacio interno de cada botón (puedes personalizarlo) */
  background-color: #FFFFFF; /* Color de fondo del botón (puedes personalizarlo) */
  color: #0C3A72;
  font: 30px ${GlobalFonts.fontBold};
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin: 5px;
  @media(min-width: 0px) and (max-width: 349px){
    width: 100%;
    margin: 5px auto;
    font: 20px ${GlobalFonts.fontBold};
  }
  @media(min-width: 350px) and (max-width: 389px){
    width: 100%;
    margin: 5px auto;
  }
  @media(min-width: 390px) and (max-width: 449px){
    width: 90%;
    margin: 5px auto;
  }
  @media(min-width: 450px) and (max-width: 549px){
    width: 80%;
    margin: 5px auto;
  }
  @media(min-width: 550px) and (max-width: 619px){
    width: 70%;
    margin: 5px auto;
  }
  @media(min-width: 620px) and (max-width: 767px){
    width: 60%;
    margin: 5px auto;
  }
  @media(min-width: 768px) and (max-width: 1119px) {
    width: 40%;
    margin: 5px auto;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  @media (max-width: 1119px) {
    margin-left: auto;
  }
`;

export const ContInfoContactPage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  border-radius: 50px;
  margin-bottom: 60px;
  padding: 20px 0px;

  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
`;
export const ContTitleContact = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  display: block;
  padding-left: 15%;
`;

export const TitleContact = styled.div`
  font: 48px ${GlobalFonts.fontBold};
  color: #282828;
  margin-bottom: 30px;
  @media(min-width: 0px) and (max-width: 299px){
    font: 36px ${GlobalFonts.fontBold};
  }
  @media(min-width: 300px) and (max-width: 767px){
    font: 42px ${GlobalFonts.fontBold};
  }
`;

export const SubtitleContact = styled.div`
  font: 30px ${GlobalFonts.fontRegular};
  color: #282828;
  margin-bottom: 30px;
`;

export const AdvisorContact = styled.div`
  font: 18px ${GlobalFonts.fontRegular};
  color: #575757;
  margin-bottom: 30px;
`;