import React from 'react';
import {ContTitleLegals, ContLegals, GlobalStylesLegals} from './Legals.styles';

export const AvisoDePrivacidad = () => {

  return (
    <div className={'section-first'}>
      <GlobalStylesLegals/>
      <ContTitleLegals>
        Aviso de privacidad
      </ContTitleLegals>
      <div className={'container-fit'}>
        <ContLegals>
          <div className="col">
            <div className="row mt-3">
              <div className="text-left"><b>I. General</b></div>
              <div className="text-justify mt-2">
                Al ingresar y utilizar este portal de internet, cuyo nombre de dominio es:
                dynamicore.io (la
                “Plataforma”), propiedad de Medici &amp; Satoshi, S.A.P.I. de C.V., (“DynamiCore”),
                usted (el
                “Titular”) declara que está aceptando los términos y las condiciones contenidos en
                el presente aviso
                (el “Aviso de Privacidad”) y declara y otorga expresamente su aceptación y
                consentimiento utilizando
                para tal efecto medios electrónicos, en términos de lo dispuesto por el artículo
                1803 del código civil
                federal. Si el Titular no acepta en forma absoluta y completa los términos y
                condiciones de este Aviso
                de Privacidad, deberá abstenerse de acceder, ver y hacer uso de la Plataforma. Para
                el caso que el
                Titular continúe en el uso de la Plataforma, sea en forma total o parcial, dicha
                acción se considerará
                como su absoluta y expresa aceptación a los términos y condiciones aquí estipulados.
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>II. Responsable del Tratamiento de sus Datos Personales</b>
              </div>
              <div className="text-justify mt-2">
                DynamiCore, con domicilio ubicado en Eugenia 516, Piso 4, Col. Del Valle, C.P.
                03100, Benito
                Juárez, Ciudad de México, es el responsable de recabar sus datos personales, del
                tratamiento que se
                le dé a los mismos, así como de su protección, y pone a su disposición el presente
                Aviso de Privacidad
                en cumplimiento a los artículos 15 y 16 de la Ley Federal de Protección de Datos
                Personales en
                Posesión de los Particulares (en lo sucesivo la “Ley”). Los datos personales que
                usted ha
                proporcionado a DynamiCore de manera directa o por conducto de tercero debidamente
                autorizado
                para ello, a través de medios electrónicos, han sido recabados y serán tratados por
                DynamiCore de
                conformidad con los principios de licitud, consentimiento, información, calidad,
                finalidad, lealtad,
                proporcionalidad y responsabilidad, en términos de lo dispuesto en la Ley.
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>III. Datos Personales Sujetos a Tratamiento</b></div>
              <div className="text-justify mt-2">
                Para llevar a cabo las finalidades descritas en el presente Aviso de Privacidad, los
                datos personales
                del Titular que serán recabados y sujetos a tratamiento son:
                <ul>
                  <li>Datos de identificación</li>
                  <li>Datos de contacto</li>
                </ul>
                De igual manera, DynamiCore podrá recabar su dirección de IP (Internet Protocol)
                para ayudar a
                diagnosticar problemas relacionados con los servicios prestados, y relacionados con
                el uso y
                administración de la Plataforma. Una dirección de IP es un número que se le asigna a
                su computadora
                cuando usa Internet. Su dirección de IP también es utilizada para ayudar a
                identificar al Titular dentro
                de una sesión particular y para recolectar información demográfica y estadística
                general.
                <br />
                  Los datos personales podrán ser recabados de manera directa del Titular al
                  registrarse y/o ingresar a
                  la Plataforma al utilizar los servicios prestados por DynamiCore; o de manera
                  indirecta a través de
                  fuentes de información de acceso público o por conducto de un tercero debidamente
                  autorizado por
                  el Titular para transferir sus datos personales a DynamiCore para las finalidades
                  previstas en el
                  presente Aviso de Privacidad. La Plataforma podrá recabar información del Titular de
                  manera
                  constante sin que medie aviso adicional.
                  En caso de que los datos personales sean transferidos por un tercero, el tercero en
                  este acto manifiesta
                  que cuenta con el consentimiento expreso y por escrito del Titular para transferir a
                  DynamiCore sus
                  datos personales y sus datos personales patrimoniales y financieros, para las
                  finalidades previstas en
                  el presente Aviso de Privacidad.
                  <br />
                    DynamiCore y/o sus afiliadas, subsidiarias, sociedades controladoras, asociadas, o
                    comisionistas
                    podrán, mas no estarán obligados a, realizar las investigaciones y acciones que
                    consideren necesarias,
                    a efecto de comprobar a través de cualquier tercero, dependencia o autoridad, la
                    veracidad de los
                    datos que les fueron proporcionados, o de la facultad para transferirlos, así como
                    suspender temporal
                    o definitivamente los servicios en favor de aquellos usuarios cuyos datos no hayan
                    podido ser
                    confirmados.
                    <ul className="mt-2">
                      <li>Datos Patrimoniales o Financieros</li>
                    </ul>
                    DynamiCore podrá recabar los siguientes datos personales patrimoniales y financieros
                    del Titular: (i)
                    información fiscal y clave CIEC; (ii) historial crediticio; (iii) información sobre
                    ingresos y egresos; y
                    (iv) información sobre cuentas bancarias, tarjetas de crédito, seguros, afores y
                    fianzas.
                    <ul className="mt-2">
                      <li>Datos Sensibles</li>
                    </ul>
                    DynamiCore no recabará datos personales sensibles. Sin perjuicio de lo anterior, en
                    el supuesto de
                    que DynamiCore requiera recabar datos personales sensibles, solicitará el
                    consentimiento expreso y
                    por escrito del Titular para su tratamiento.
                    <br />
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>IV. Finalidades del Tratamiento</b></div>
              <div className="text-justify mt-2">
                Sus datos personales serán utilizados para las siguientes finalidades principales:
                <ul>
                  <li>Para brindarle un servicio de manera personalizada y acorde con sus necesidades particulares.</li>
                  <li>Prestar los servicios de administración de la Plataforma.</li>
                  <li>Realizar investigaciones en fuentes de acceso públicas, gratuitas o con costo, sobre la
                    situación jurídica, financiera y patrimonial del Titular
                  </li>
                  <li>Determinar la capacidad de pago o endeudamiento del Titular.</li>
                  <li>Para administrar la cuenta de usuario del Titular en la Plataforma.</li>
                  <li>Para generar una mejor experiencia de usuario en la Plataforma</li>
                  <li>Contactar al usuario para cualquier cuestión relacionada con su cuenta o los servicios
                    proporcionados por DynamiCore
                  </li>
                  <li>Contactar al usuario en relación con las solicitudes de Derechos ARCO (según se define más
                    adelante) que formule.
                  </li>
                  <li>Realizar y dar seguimiento a consultas, investigaciones y/o revisiones de las actividades,
                    operaciones, quejas y/o reclamaciones del Titular relacionados con los servicios prestados
                    por DynamiCore.
                  </li>
                  <li>Verificar y/o confirmar los datos proporcionados del Titular.</li>
                  <li>Proveer los servicios y/o productos que ha solicitado.</li>
                  <li>Dar cumplimiento a cualquier disposición legal aplicable.</li>
                </ul>
                Las finalidades anteriores son necesarias para el cumplimiento de las obligaciones a cargo de
                DynamiCore derivadas de la relación con usted, por lo que en caso de que el Titular no acepte el
                tratamiento de sus datos personales (incluyendo los financieros y/o patrimoniales), podremos vernos
                imposibilitados para prestar los servicios solicitados.
                <br />
                  El Tratamiento de datos personales será el que resulte necesario, adecuado y relevante en relación a
                  las finalidades previstas en el presente Aviso de Privacidad, así como con aquellos fines que resulten
                  compatibles o complementarios relacionados con los servicios que proporciona DynamiCore.
                  <br />
                    Finalidades Secundarias
                    <br />
                      Adicionalmente, DynamiCore podrá utilizar los datos personales del Titular para ofrecerle, en su
                      caso, otros productos o servicios propios o de cualquiera de sus afiliadas, subsidiarias,
                      sociedades
                      controladoras, asociadas, y/o comisionistas, así como para notificarle sobre nuevos servicios o
                      productos que tengan relación con los ya contratados o adquiridos, informar sobre iniciativas
                      destacadas; comunicarle sobre cambios en los mismos; elaborar estudios y programas para determinar
                      hábitos de consumo; realizar evaluaciones periódicas de nuestros productos y servicios a efecto de
                      mejorar la calidad de los mismos; y evaluar la calidad del servicio que brindamos; finalidades que
                      no
                      son indispensables para el cumplimiento de la relación jurídica que dio origen al producto o
                      servicio
                      contratado, pero que ayudan a la mejora en la prestación de nuestros servicios en su favor.
                      <br />
                        En caso de que no desee que los datos personales sean utilizados para las finalidades
                        secundarias
                        antes mencionadas, puede enviarnos un correo electrónico a la dirección support@dynamicore.io,
                        manifestando que no desea que sus datos personales sean utilizados para las finalidades
                        secundarias
                        conforme al presente Aviso de Privacidad.
                        <br />
                          La negativa para el uso de los datos personales para las finalidades secundarias mencionadas
                          en el
                          párrafo anterior no podrá ser motivo para que le neguemos nuestros servicios.
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>V. Transferencias de Datos</b></div>
              <div className="text-justify mt-2">
                DynamiCore podrá transferir sus datos personales a terceros mexicanos o extranjeros cuando la
                transferencia: (i) esté prevista en una ley o Tratado en los que México sea parte; (ii) sea necesaria
                para la prevención o el diagnóstico médico, la prestación de asistencia sanitaria, tratamiento médico
                o la gestión de servicios sanitarios; (iii) sea efectuada a sociedades controladoras, subsidiarias o
                afiliadas bajo el control común de DynamiCore, a una sociedad matriz o a sus asociadas; (iv) sea
                necesaria por virtud de un contrato celebrado o por celebrar en interés del Titular, por DynamiCore y
                un tercero o por el Titular y un tercero; (v) sea necesaria o legalmente exigida para la salvaguarda de
                un interés público, o para la procuración o administración de justicia; (vi) sea precisa para el
                reconocimiento, ejercicio o defensa de un derecho en un proceso judicial; y (vii) sea precisa para el
                mantenimiento o cumplimiento de una relación jurídica entre el Titular y DynamiCore, incluyendo
                para llevar a cabo las investigaciones sobre la situación jurídica, financiera y patrimonial del Titular
                solicitadas.
                <br />
                  En cualquier otro caso, DynamiCore requerirá al Titular su autorización para realizar la transferencia
                  de datos que resulte necesaria para la prestación de los servicios solicitados.
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>VI. Uso de Cookies</b></div>
              <div className="text-justify mt-2">
                Las Cookies son pequeños archivos que se instalan en el disco rígido, con una duración limitada en
                el tiempo que ayudan a personalizar los servicios. Las Cookies permiten reconocer a los usuarios,
                detectar el ancho de banda que han seleccionado, identificar la información más destacada, calcular
                el tamaño de la audiencia y medir algunos parámetros de tráfico.
                <br />
                  Le informamos que en la Plataforma utilizamos Cookies, web beacons y otras tecnologías análogas a
                  éstas, a través de las cuales es posible monitorear su comportamiento como usuario de internet, así
                  como brindarle un mejor servicio y experiencia de usuario al navegar en la Plataforma. El Titular
                  puede desactivar el ahorro de cookies en su navegador, borrarlos o gestionar su uso a través de la
                  configuración de su navegador, en el entendido que dichas acciones podrían ocasionar una deficiencia
                  en el rendimiento y/o funcionamiento de la Plataforma, así como también ofrecemos ciertas
                  funcionalidades que sólo están disponibles mediante el empleo de Cookies
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>VII. Derechos ARCO</b></div>
              <div className="text-justify mt-2">
                El Titular tiene derecho en cualquier momento de acceder, rectificar, y/o cancelar los datos personales
                que DynamiCore hubiere recabado, así como a oponerse a su tratamiento de manera total o parcial
                (“Derechos ARCO”). Para efecto de lo anterior, el Titular deberá enviar vía correo electrónico a la
                siguiente dirección support@dynamicore.io la solicitud respectiva, misma que deberá contener, al
                menos lo siguiente: (i) nombre completo, y domicilio o correo electrónico del Titular para
                comunicarle la respuesta; (ii) copia del documento que acredite la identidad o, en su caso, la
                representación legal del Titular; (iii) la descripción de los datos personales de los que se busca
                ejercer
                algún Derecho ARCO; (iv) cualquier otro elemento que facilite la localización de los datos
                personales; (v) el Derecho ARCO que pretende ejercer; y (vi) un breve relato de los motivos o razones
                por las que pretende ejercer el Derecho ARCO.
                <br />
                  DynamiCore le comunicará la respuesta a la misma, dentro de un plazo de 20 (veinte) días hábiles
                  siguientes a la presentación de su solicitud. La cancelación de sus datos estará sujeta a lo previsto
                  en
                  el artículo 26 de la Ley.
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>VIII. Revocación del Consentimiento</b></div>
              <div className="text-justify mt-2">
                El Titular puede revocar el consentimiento para el tratamiento de sus datos personales, así como
                oponerse al tratamiento de los mismos para las finalidades secundarias, a efecto de lo cual, deberá
                enviar la solicitud respectiva vía correo electrónico a la dirección support@dynamicore.io,
                acompañada de la información y documentación mencionada en el apartado anterior bajo el rubro
                “Derechos ARCO”. Sin embargo, es importante que tenga en cuenta que, para ciertos fines, la
                revocación de su consentimiento podría implicar que DynamiCore no se encuentre en posibilidades
                de seguir prestando el servicio que solicitó, o la conclusión de su relación con DynamiCore.
                Asimismo, deberá considerar que no en todos los casos podremos atender su solicitud o detener el
                uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando
                sus datos personales por un plazo adicional.
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>IX. Limitación del Uso o Divulgación de los Datos Personales</b></div>
              <div className="text-justify mt-2">
                El Titular podrá limitar el uso y/o divulgación de sus datos personales, incluyendo sin limitar, su
                negativa a recibir comunicados y promociones por parte de DynamiCore por cualquiera de los
                siguientes medios: (i) mediante solicitud por escrito conforme al procedimiento descrito en el
                apartado denominado “Derechos ARCO” de este documento; o (ii) mediante su inscripción en el
                Registro Público para Evitar Publicidad, que está a cargo de la Procuraduría Federal del Consumidor
                (“PROFECO”) y/o en el Registro Público de Usuarios a cargo de la Comisión Nacional para la
                Protección y Defensa de los Usuarios de Servicios Financieros (“CONDUSEF”), con la finalidad de
                que sus datos personales no sean utilizados para recibir publicidad o promociones de empresas de
                bienes o servicios. Para más información sobre este registro, usted puede consultar el portal de
                Internet de la Profeco y de la CONDUSEF, o bien ponerse en contacto directo con éstas.
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>X. Modificaciones</b></div>
              <div className="text-justify mt-2">
                DynamiCore se reserva el derecho de realizar cambios o modificaciones al presente Aviso de
                Privacidad en cualquier momento, en cuyo caso, comunicará dicha modificación través de la
                Plataforma, o mediante correo electrónico a la dirección que nos sea proporcionada. El Titular contará
                con un plazo de 10 (diez) días hábiles contados a partir de la publicación de las modificaciones en la
                Plataforma o, en su caso, de la recepción del correo electrónico respectivo, para manifestar cualquier
                objeción o inconformidad a dichas modificaciones.
                <br />
                  Cualquier duda o comentario con respecto al presente Aviso de Privacidad, así como al ejercicio de
                  cualesquiera otros derechos que por la Ley le corresponden, deberán dirigirse al encargado de Avisos
                  de Privacidad al correo electrónico support@dynamicore.io, así como también podrá comunicarse al
                  área de atención a usuarios en la sección de Contacto ubicada en la Plataforma para cualquier duda o
                  aclaración.
                  <br />
                    Sin perjuicio de lo anterior, en caso de que usted considere que su derecho a la protección de sus
                    datos
                    personales ha sido lesionado por alguna conducta u omisión de nuestra parte, o presume alguna
                    violación a las disposiciones previstas en la Ley, podrá acudir al Instituto Nacional de
                    Transparencia,
                    Acceso a la Información y Protección de Datos Personales (“INAI”). Para mayor información, le
                    sugerimos visitar su página oficial de Internet www.inai.org.mx.
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>XI. Ley Aplicable y Jurisdicción</b></div>
              <div className="text-justify mt-2">
                El presente Aviso de Privacidad, se regirá por las disposiciones legales aplicables en los Estados
                Unidos Mexicanos. En caso de que exista una disputa o controversia, derivada de la interpretación,
                ejecución o cumplimiento del mismo o de cualquiera de los documentos que del mismo se deriven, o
                que guarden relación con éste, las partes amigablemente, buscarán llegar a un acuerdo dentro de un
                plazo de treinta (30) días naturales, contados a partir de la fecha en que surja cualquier diferencia y
                se notifique por escrito sobre dicho evento a la contraparte, deduciendo el proceso de mediación ante
                el Centro de Justicia Alternativa de la CMDX, llevándose al amparo de la Ley de Justicia Alternativa
                del Tribunal Superior de Justicia de la CMDX y su Reglamento Interno, vigente al momento de que
                se presente la controversia.
                <br />
                  En caso de que las partes no lleguen a un acuerdo, convienen en este acto en someter todas las
                  desavenencias que deriven del presente Aviso de Privacidad o de cualquiera de los documentos que
                  del mismo se deriven, o que guarden relación con éste o con aquéllos, serán resueltas de manera
                  definitiva se someten a la competencia y leyes de las autoridades administrativas federales o
                  tribunales de la Ciudad de México, renunciando expresamente a cualquier fuero distinto que por
                  razones de sus domicilios presentes o futuros, o por cualquier otra causa pudieren corresponderles.
              </div>
            </div>
            <div className="custom-hr" />
            <div className="row mt-3">
              <div className="text-justify mt-2">
                El Titular consiente que sus datos personales sean tratados para los fines y conforme a los
                lineamientos contenidos en el presente Aviso de Privacidad.
                <br />
                  El Titular consiente el tratamiento de sus datos personales patrimoniales y financieros para los
                  fines
                  y conforme a los lineamientos contenidos en el presente Aviso de Privacidad
              </div>
              <div className="text-right mt-4">
                Última actualización 15 de Enero del 2023
              </div>
            </div>
          </div>
        </ContLegals>
      </div>
    </div>
  );
};
