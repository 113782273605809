import PropTypes from 'prop-types';
import {ContButtonSend, ContButton, ButtonAnchorBtn, StyledCTA} from './ButtonSpecial.styles';

function ButtonControl(props) {
  const {
    //color,
    //form,
    //label,
    //processing,
    id = '',
    type,

    text, 
    //linkTo, 
    location = 'normal', 
    theme = 'primary', 
    //onClick, 
    //target = '_blank',
  } = props;

  return (
  
  <>
    
    <ContButtonSend id={'ContButtonSend'}>
      <ContButton location={location}>
        {type === '#'
          ? (
            <ButtonAnchorBtn theme={theme} type={type}>
              <StyledCTA theme={theme}>
                <span>{text}</span>
                <svg width="13px" height="10px" viewBox="0 0 13 10">
                  <path d="M1,5 L11,5"></path>
                  <polyline points="8 1 12 5 8 9"></polyline>
                </svg>
              </StyledCTA>
            </ButtonAnchorBtn>
          ) : (
            <ButtonAnchorBtn id={id} theme={theme} type={type}>
              <StyledCTA theme={theme}>
                <span>{text}</span>
                <svg width="13px" height="10px" viewBox="0 0 13 10">
                  <path d="M1,5 L11,5"></path>
                  <polyline points="8 1 12 5 8 9"></polyline>
                </svg>
              </StyledCTA>
            </ButtonAnchorBtn>
          )
        }
      </ContButton>
    </ContButtonSend>
  </>
  );
}

ButtonControl.propTypes = {
  color: PropTypes.string,
  form: PropTypes.string,
  label: PropTypes.string,
  processing: PropTypes.bool,
  type: PropTypes.string,
};

export default ButtonControl;
