import React from 'react';
import {ContTitleSpecial, ContTitleSpecial2, GlobalStylesTitleSpecial} from './TitleSpecial.styles';


export const TitleSpecial = ({children, isCenter = false, useCasesView = false}) => {
  return (
  useCasesView ? (
      <>
      <GlobalStylesTitleSpecial/>
      <ContTitleSpecial2 isCenter={isCenter}>{children}</ContTitleSpecial2>
    </>
    ) : (
      <>
        <GlobalStylesTitleSpecial/>
        <ContTitleSpecial isCenter={isCenter}>{children}</ContTitleSpecial>
      </>
    )
  )
}
