import React from 'react';
import {ContLayout} from './Layout.styles';
import Header from '../Header';
import {FontFiles, GlobalStyles} from '../../globals';
import ActionDemo from '../ActionDemo';
import Footer from '../Footer';
import FooterSitemap from '../FooterSitemap';
// import ImageBack1 from '../../assets/images/back-home-1.webp';

const Layout = ({children, backgroundimage = '', actionDemo = true, footer = true}) => {
  return (
    <ContLayout backgroundimage={backgroundimage}>
      <FontFiles/>
      <GlobalStyles/>
      <Header/>
      {children}
      {actionDemo && <ActionDemo/>}
      {footer && 
      <>
        <FooterSitemap />
        <div style={{backgroundColor: '#CEE1F8', marginTop: 10, border: '1px solid #CEE1F8'}} />
        <Footer/>  
      </>}
    </ContLayout>
  )
}

export default Layout;
