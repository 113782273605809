import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import Back1 from '../assets/images/back-home-1.webp';
import SEO from '../components/SEO';
import DispersionPage from '../pages/DispersionPage';

export const Dispersion = () => {
  const { t } = useTranslation();
  return (
    <Layout backgroundimage={Back1} actionDemo={false}>
      <SEO
        title="Dispersión de Recursos Simplificada y Rápida"
        description={t('Maximiza la eficiencia de tus recursos con nuestra plataforma de dispersión de pagos. Simplifica los pagos a terceros y optimiza la gestión de transacciones.')}
        works={t('Core Financiero, Fintech, Onboardig digital, Aprobación de crédito, Gestión de cobranza, Análisis de crédito, Core Bancario, Automatización de procesos, Procesos digitales, Producto financiero, Servicios financieros, Digitalización de procesos, Software financiero, Dispersión, Reportes automatizados, Corebanking, Sector financiero, SOFIPO, SOFOM, CNBV, Condusef, Servicios financieros digitales, Plataforma financiera')}
        bolImage={false}
        ogImage={`beneficios_f.jpg`}
        twitterImage={`beneficios_t.jpg`}
        canonicalUrl="https://dynamicore.io/dispersion-pagos"
      />
      <DispersionPage/>
    </Layout>
  )
}