import styled from 'styled-components';
import {GlobalFonts} from '../../globals';

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  @media(min-width: 0px) and (max-width: 870px){
    display: block;
  }
`;

export const Card = styled.div`
  width: 33%;
  max-width: 33%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin: 10px;
  padding: ${(props) => (props.isCenter ? '30px' : '40px 30px')};
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isCenter ? 'center' : 'flex-start')};
  text-align: ${(props) => (props.isCenter ? 'center' : 'flex-start')};
  @media(min-width: 0px) and (max-width: 750px){
    height: auto;
  }
  @media(min-width: 0px) and (max-width: 870px){
    padding: 30px 10px;
    max-width: none;
    width: 91%;
  }
  @media(min-width: 820px) and (max-width: 970px) {
    padding: 20px;
  }
`;

export const Icon = styled.img`
  width: auto;
  height: 70px;
  margin-right: 10px;
  @media(min-width: 0px) and (max-width: 399px){
    height: 30px;
  }
  @media(min-width: 400px) and (max-width: 1024px){
    height: 40px;
  }
  @media(min-width: 1025px) and (max-width: 1270px){
    height: 50px;
  }
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  margin: 0 !important;
  padding-left: ${(props) => (props.isCenter ? '20px' : '')};
  text-align: ${(props) => (props.isCenter ? 'center' : 'flex-start')};
  justify-content: ${(props) => (props.isCenter ? 'center' : 'flex-start')}; 
`;

export const Title = styled.div`
  font-size: 26px;
  font-family: ${GlobalFonts.fontBold};
  color: #0C3A72;
  margin: ${(props) => (props.isCenter ? '0!important' : '0px 20px 0px 0px')};
  text-align: ${(props) => (props.isCenter ? 'center' : 'flex-start')};

  @media(min-width: 0px) and (max-width: 399px){
    font-size: 20px;
  };

  @media(min-width: 400px) and (max-width: 767px){
    font-size: 22px;
  };
  @media(min-width: 768px) and (max-width: 1024px){
    font-size: 22px;
  }
  @media(min-width: 1025px) and (max-width: 1200px){
    font-size: 22px;
  }
`;

export const CardDescription = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-family: ${GlobalFonts.fontRegular};
  color: #282828;
  vertical-align: middle;
  margin-top: ${(props) => (props.isCenter ? '20px' : '30px')};
  text-align: ${(props) => (props.isCenter ? 'center' : 'flex-start')};

  @media(min-width: 400px) and (max-width: 767px){
    font-size: 18px;
    line-height: 20px;
  }
  @media(min-width: 768px) and (max-width: 1024px){
    font-size: 18px;
    line-height: 20px;
  }
  @media(min-width: 1025px) and (max-width: 1200px){
    font-size: 18px;
    line-height: 20px;
  }
`;

export const RoundButton = styled.button`
  background-color: #0073e6;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
