import React from 'react';
//import { hydrate, render } from "react-dom";
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import headerTranslations from '../src/locales/header';
import homeTranslations from '../src/locales/home';
import aboutusTranslations from '../src/locales/aboutus';
import benefitsTranslations from '../src/locales/benefits'; 
import servicesTranslations from '../src/locales/services'; 
import contactTranslations from '../src/locales/contact'; 
import footerTranslations from '../src/locales/footer';
import seoTranslations from '../src/locales/seo';

i18n
  .use(LanguageDetector)
  .init({
    resources: {
      //es: {
      //  translation: {
      //    'Contáctanos': 'Contact Us',
      //  },
      //},
      en: {
        translation: {
          ...headerTranslations,
          ...homeTranslations,
          ...aboutusTranslations,
          ...benefitsTranslations,
          ...servicesTranslations,
          ...contactTranslations,
          ...footerTranslations,
          ...seoTranslations,
        },
      },
    },
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false,
    },
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

/*const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
} */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
