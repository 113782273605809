import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  GSFormContact,
  ContFirstFormContact,
  ContFormContact,
  //ContButtonSend,
  //ContFormContactItem,
  ContAlertContact,
} from './FormContact.styles';
import {
  Alert,
  Collapse,
  //FormControl,
  //FormHelperText,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
//import ButtonSpecial from '../ButtonSpecial';
import {useWindowDimensions} from '../../utils';
import {useFormContact} from './useFormContact';
import DynamicForm from '../DynamicForm/index';
import { dataClient } from './validationSchema';

export const FormContact = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sizeInput, setSizeInput] = useState('small');
  const [reset, setReset] = useState(false);
  const [form, setForm] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    interest: '',
    message: '',
  });
  //const [value, setValue] = useState({
  //  name: '',
  //  company: '',
  //  email: '',
  //  phone: '',
  //  interest: '',
  //  message: '',
  //});
  const [emailSend, setEmailSend] = useState(false);
  const {
    onSubmit,
    //erName,
    //erCompany,
    //erEmail,
    //erPhone,
    //erInterest,
    //erMessage,
    closeAlert,
    respSend,
  } = useFormContact();
  const {width} = useWindowDimensions();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmitAccion = (values) => {
    if (!formSubmitted) { 
      const payload = {
        ...values,
        subject: values.interest,
      }
      const sendValues = onSubmit(payload);
      if (sendValues === true ) {
        setEmailSend(true);
        setFormSubmitted(true);
        setTimeout(() => {
          navigate('/gracias');
        }, 2000);
      }
    }
  }

  const resetForm = () => {
    setForm({
      name: '',
      company: '',
      email: '',
      phone: '',
      interest: '',
      message: '',
    });
    setReset(true);
  }
  //const handleValidatePhone = (event) => {
  //  const value = event.target.value;
  //  if (/^\d{0,10}$/.test(value) && !isNaN(value)) {
  //    return value;
  //  } else {
  //    return null;
  //  }
  //};

  useEffect(() => {
    if ( width > 1023 ) {
      setSizeInput('small');
    } else {
      setSizeInput('small');
    }
  }, [width]);

  useEffect(() => {
    if(emailSend === true){
      resetForm();
    }
  }, [emailSend]);

  useEffect(() => {
  }, [reset]);

  return (
    <ContFirstFormContact id={'ContFirstFormContact'}>
      <GSFormContact/>
      <ContAlertContact>
        <Collapse in={respSend.openAlert}>
          <Alert
            severity={respSend.status}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  closeAlert();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 6, }}
          >
            <div dangerouslySetInnerHTML={{__html: respSend.message}} />
          </Alert>
        </Collapse>
      </ContAlertContact>
      <ContFormContact id={'ContFormContact'}>
        {form && (
        <DynamicForm
          actions={[
            {
              color: 'primary',
              label: t('Enviar'),
              type: 'submit',
              typeButton: 'login',
              form: 'formContact',
              processing: formSubmitted,
              fn: () => {
                handleSubmitAccion();
              },
            },
          ]}
          fields={[
            {
              label: t('Nombre completo'),
              name: 'name',
            },
            {
              label: t('Nombre de la empresa'),
              name: 'company',
            },
            {
              label: t('Email de tu empresa'),
              name: 'email',
              placeholder: 'example@company.com',
              type: 'email',
            },
            {
              label: t('Télefono'),
              name: 'phone',
              type: 'phone_number',
            },
            {
              label: t('Ciudad'),
              name: 'city',
            },
            {
              label: t('¿A qué institución financiera representas?'),
              name: 'typeInstitution',
              type: 'select',
              settings: {
                emptyElement: t('¿A qué institución financiera representas?'),
                group: false,
                options: [
                  {
                    id: 'SOFOM',
                    name: 'SOFOM',
                  },
                  {
                    id: 'SOFIPO',
                    name: 'SOFIPO',
                  },
                  {
                    id: 'SOFOL',
                    name: 'SOFOL',
                  },
                  {
                    id: 'IFPE',
                    name: 'IFPE',
                  },
                  {
                    id: 'otra',
                    name: t('Otra'),
                  },
                ]
              },
            },
            {
              label: t('¿Qué servicio necesitas para tu empresa?'),
              name: 'interest',
              type: 'select',
              settings: {
                emptyElement: t('¿Qué servicio necesitas para tu empresa?'),
                group: false,
                options: [
                  {
                    id: 'Quiero una demo',
                    name: t('Quiero una demo'),
                  },
                  {
                    id: 'Onboarding digital',
                    name: t('Onboarding digital'),
                  },
                  {
                    id: 'Candado para celulares',
                    name: t('Candado para celulares'),
                  },
                  {
                    id: 'Administración de Clientes/KYC',
                    name: t('Administración de Clientes/KYC'),
                  },
                  {
                    id: 'Configuración de productos',
                    name: t('Configuración de productos'),
                  },
                  {
                    id: 'PLD/Reportes regulatorios',
                    name: t('PLD/Reportes regulatorios'),
                  },
                  {
                    id: 'Notificaciones automatizadas',
                    name: t('Notificaciones automatizadas'),
                  },
                  {
                    id: t('Automatización de procesos'),
                    name: 'Automatización de procesos',
                  },
                  {
                    id: 'Otros',
                    name: t('Otros'),
                  },
                ],
              },
            },
            {
              label: t('Platícanos tu proyecto'),
              name: 'message',
              type: 'textarea',
              settings: {
                multiline: true,
                rows: 4,
                rowsMax: 4,
              },
            },
            {
              label: t('Entiendo que Dynamicore no ofrece ni otorga créditos de ningún tipo'),
              name: 'check_nocredit',
              size: 'medium',
              type: 'checkbox',
              settings: {
                size: 'medium',
              },
              display: {
                breakpoints: {
                  md: 12,
                },
              },
            },
          ]}
          handleSubmitData={handleSubmitAccion}
          id="formContact"
          size={sizeInput}
          validationSchema={dataClient}
          initialValues={form}
        />
        )}
        {/*
        <div id="formContact" noValidate>
          <ContFormContactItem>
            <TextField
              variant="outlined"
              id="name"
              label="Nombre completo"
              className={'contact-input'}
              size={sizeInput}
              error={!!erName}
              helperText={erName}
              onChange={(event) => {
                setValue({name: event.target.value});
                setForm({
                  ...form,
                  name: event.target.value,
                });
              }}
              value={value.name}
            />
          </ContFormContactItem>
          <ContFormContactItem>
            <TextField
              variant="outlined"
              id="company"
              label="Nombre de la empresa"
              className={'contact-input'}
              size={sizeInput}
              error={!!erCompany}
              helperText={erCompany}
              onChange={(event) => {
                setValue({company: event.target.value});
                setForm({
                  ...form,
                  company: event.target.value,
                });
              }}
              value={value.company}
            />
          </ContFormContactItem>
          <ContFormContactItem>
            <TextField
              variant="outlined"
              id="email"
              label="Email"
              className={'contact-input'}
              size={sizeInput}
              error={!!erEmail}
              helperText={erEmail}
              onChange={(event) => {
                setValue({email: event.target.value});
                setForm({
                  ...form,
                  email: event.target.value,
                });
              }}
              value={value.email}
            />
          </ContFormContactItem>
          <ContFormContactItem>
            <TextField
              variant="outlined"
              id="phone"
              label="Teléfono"
              className={'contact-input'}
              size={sizeInput}
              error={!!erPhone}
              helperText={erPhone}
              onChange={(event) => {
                setValue({
                  phone: handleValidatePhone(event),
                });
                setForm({
                  ...form,
                  phone: event.target.value,
                });
              }}
              value={value.phone}
              InputProps={{
                inputProps: {
                  maxLength: 10,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                },
              }}
            />
          </ContFormContactItem>
          
          <ContFormContactItem>
            <FormControl fullWidth error={!!erInterest} size={sizeInput}>
              <InputLabel id="demo-simple-select-label">¿Qué servicio necesitas para tu empresa?</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="interest"
                value={value.interest}
                label="¿Qué servicio necesitas para tu empresa?"
                onChange={(event) => {
                  setValue({interest: event.target.value});
                  setForm({
                    ...form,
                    interest: event.target.value,
                  });
                }}
              >
                <MenuItem value={'Quiero una demo'}>Quiero una demo</MenuItem>
                <MenuItem value={'Onboarding digital'}>Onboarding digital</MenuItem>
                <MenuItem value={'Candado para celulares'}>Candado para celulares</MenuItem>
                <MenuItem value={'Administración de Clientes/KYC'}>Administración de Clientes/KYC</MenuItem>
                <MenuItem value={'Configuración de productos'}>Configuración de productos</MenuItem>
                <MenuItem value={'PLD/Reportes regulatorios'}>PLD/Reportes regulatorios</MenuItem>
                <MenuItem value={'Notificaciones automatizadas'}>Notificaciones automatizadas</MenuItem>
                <MenuItem value={'Automatización de procesos'}>Automatización de procesos</MenuItem>
                <MenuItem value={'Otros'}>Otros</MenuItem>
              </Select>
              <FormHelperText>{erInterest}</FormHelperText>
            </FormControl>
          </ContFormContactItem>
          <ContFormContactItem>
            <TextField
              variant="outlined"
              id="message"
              label="Platícanos tu proyecto"
              multiline
              rows={6}
              className={'contact-input'}
              size={sizeInput}
              error={!!erMessage}
              helperText={erMessage}
              onChange={(event) => {
                setValue({message: event.target.value});
                setForm({
                  ...form,
                  message: event.target.value,
                });
              }}
              value={value.message}
            />
          </ContFormContactItem>
          <ContFormContactItem>
            <ContButtonSend>
              <ButtonSpecial
                text={'Enviar'}
                type={'#'}
                onClick={() => {
                  onSubmit(form);
                  resetForm();
                }}
              />
            </ContButtonSend>
          </ContFormContactItem>
        </div>
        */}
      </ContFormContact>
    </ContFirstFormContact>
  );
};
