const contactTranslations = {
  '¡Somos mejor que un core bancario!': 'We are better than  Banking Core!',
  'Te ayudamos a integrar procesos automatizados y productos financieros digitales.': 'We assist you in integrating automated processes and digital financial products.',
  'Con Dynamicore hemos podido acelerar significativamente los tiempos para operar. Gracias al equipo y a las integraciones ya listas, DynamiCore nos permitió enfocarnos en nuestro producto y propuesta de valor.':
  'With Dynamicore, we were able to significantly speed up our operation. Thanks to the team and the ready-made integrations, DynamiCore allowed us to focus on our product and value proposition.',
  'Es muy grato contar con grandes aliados, sin duda, DynamiCore tiene un equipo de expertos que nos han acompañado en cada paso del desarrollo y lanzamiento de nuestra app de arrendamiento enKorto.':
  'It is very pleasant to have great allies, without a doubt, DynamiCore has a team of experts who have accompanied us in every step of the development and launch of our enKorto leasing app.',
  'DynamiCore es un empresa con experiencia en el campo, nos ha ayudado a gestionar adecuadamente el control interno y operaciones diarias de nuestros productos financieros ¡Es el mejor aliado!': 
  'DynamiCore is a company with experience in the field, it has helped us properly manage the internal control and daily operations of our financial products. It is the best ally!',
  'DynamiCore nos ayudó a ofrecer soluciones financieras digitales a nuestros clientes con integraciones innovadoras que nos permitieron automatizar y acelerar nuestra operación. Cuentan con una plataforma robusta y un equipo experto, lo cual se traduce en un gran desempeño.': 'DynamiCore helped us offer digital financial solutions to our clients with innovative integrations that allowed us to automate and accelerate our operation. They have a robust platform and an expert team, which translates into great performance.',
  'Nombre completo': 'Full name',
  'Nombre de la empresa': 'Company Name',
  'Email de tu empresa': 'Company email',
  'Télefono': 'Phone',
  'Ciudad': 'City',
  '¿A qué institución financiera representas?': 'Which financial institution do you represent?',
  '¿Qué servicio necesitas para tu empresa?': 'What service do you need for your company?',
  'Platícanos tu proyecto': 'Tell us about your project',
  'Entiendo que Dynamicore no ofrece ni otorga créditos de ningún tipo': 'I understand that Dynamicore does not offer or grant any type of credit',
  'Otra': 'Other',
  'Quiero una demo': 'Request a Demo',
  'Onboarding digital': 'Digital Onboarding',
  'Candado para celulares': 'Device Financing',
  'Administración de Clientes/KYC': 'Customer management',
  'Configuración de productos': 'Product configuration',
  'PLD/Reportes regulatorios': 'AML/Reports',
  'Notificaciones automatizadas': 'Automated notifications',
  'Automatización de procesos': 'Workflows',
  'Otros': 'Other',
  'Enviar': 'Send',
};

export default contactTranslations;