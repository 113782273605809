import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContContactPage,
  ContContactPageChild,
  ContRightSide, ContTitleContact,
  DescContact,
  GSRightSide,
  TitleContact,
  ContSliderClients,
  NotCreditMsg,
} from './ContactPage.styles';
import FormContact from '../../components/FormContact';
import {useWindowDimensions} from '../../utils';
import SliderClients from '../../components/SliderClients';
import ImgFinics from '../../assets/images/icon-client-finics.webp';
import ImgEnkorto from '../../assets/images/icon-client-enkorto.webp';
import ImgA55 from '../../assets/images/icon-client-a55.webp';
import ImgEmpagos from '../../assets/images/icon-client-enpagos.webp';

const Slider1 = [
  {
    name: 'Finics',
    link: 'http://finics.mx',
    img: ImgFinics,
    desc: 'Con Dynamicore hemos podido acelerar significativamente los tiempos para operar. Gracias al equipo y a las integraciones ya listas, DynamiCore nos permitió enfocarnos en nuestro producto y propuesta de valor.'
  },
  {
    name: 'enKorto',
    link: 'http://enkorto.mx',
    img: ImgEnkorto,
    desc: 'Es muy grato contar con grandes aliados, sin duda, DynamiCore tiene un equipo de expertos que nos han acompañado en cada paso del desarrollo y lanzamiento de nuestra app de arrendamiento enKorto.'
  },
  {
    name: 'a55',
    link: 'http://a55.mx',
    img: ImgA55,
    desc: 'DynamiCore es un empresa con experiencia en el campo, nos ha ayudado a gestionar adecuadamente el control interno y operaciones diarias de nuestros productos financieros ¡Es el mejor aliado!'
  },
  {
    name: 'Enpagos',
    link: 'http://enpagos.mx',
    img: ImgEmpagos,
    desc: `DynamiCore nos ayudó a ofrecer soluciones financieras digitales a nuestros clientes con integraciones innovadoras que nos permitieron automatizar y acelerar nuestra operación. Cuentan con una plataforma robusta y un equipo experto, lo cual se traduce en un gran desempeño.`
  },
];

export const ContactPage = () => {
  const { t } = useTranslation();
  const {width, height} = useWindowDimensions();
  const [lessHeight, setLessHeight] = useState(81 + 93);
  const AUTOPLAY = true;
  const slidesToShow = 1;

  useEffect(() => {
    if (width >= 1920) {
      setLessHeight(height);
    } else if (width >= 1640) {
      setLessHeight(height);
    } else if (width >= 1440) {
      setLessHeight(height);
    } else  if (width >= 1408) {
      setLessHeight(height + 30);
    } else if (width >= 1280) {
      setLessHeight(height);
    } else if (width >= 1080) {
      setLessHeight(height - (81 + 93 - 16));
    } else if (width >= 1024) {
      setLessHeight(height - (81 + 93 - 30));
    } else if (width >= 768) {
      setLessHeight(height - (81 + 93 - 16));
    }
  }, [width]);

  /*useEffect(() => {
    console.log('useEffect => height =>', height);
    console.log('useEffect => height - (81 + 93) =>', height - (81 + 93));
  }, [height]);*/

  return (
    <ContContactPage>
      <GSRightSide/>
      <ContContactPageChild>
        <ContRightSide height={lessHeight}>
          <ContTitleContact>
            <div>
              <TitleContact>
                {t('¡Somos mejor que un core bancario!')}
              </TitleContact>
              <DescContact>
                {t('Te ayudamos a integrar procesos automatizados y productos financieros digitales.')}
              </DescContact>
              {/*<NotCreditMsg>*DynamiCore no ofrece créditos de ningún tipo.</NotCreditMsg>*/}
            </div>
            <ContSliderClients>
              <SliderClients
                data={Slider1}
                autoplay={AUTOPLAY}
                rtl={false}
                dots={true}
                infinite={true}
                slidesToShow={slidesToShow}
                slidesToScroll={1}
                speed={2000}
                autoplaySpeed={7000}
                arrows={false}
              />
            </ContSliderClients>
          </ContTitleContact>
        </ContRightSide>
      </ContContactPageChild>
      <ContContactPageChild>
        <FormContact/>
      </ContContactPageChild>
    </ContContactPage>
  );
};
