import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {ContBeneficiosPage, ContInfoBeneficiosPage, DescBeneficios, ContButton} from './BeneficiosPage.styles';
import TitleSpecial from '../../components/TitleSpecial';
import ReduceCostos from '../../components/ReduceCostos';
import ProcesosSeguros from '../../components/ProcesosSeguros';
import AutomatizacionOperaciones from '../../components/AutomatizacionOperaciones';
import FacilitarAgilizarProcesos from '../../components/FacilitarAgilizarProcesos';
import FacilitarAgilizarProcesosMobile from '../../components/FacilitarAgilizarProcesosMobile';
import ButtonSpecial from '../../components/ButtonSpecial';

export const BeneficiosPage = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const checkIsMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 600px)').matches);
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return (
    <ContBeneficiosPage id={'ContBeneficiosPage'}>
      <ContInfoBeneficiosPage>
        <TitleSpecial isCenter={true}>
          {t('Beneficios de nuestra plataforma')}
        </TitleSpecial>
        <DescBeneficios>
        {t('Gracias a que somos un equipo multidisciplinario y con gran experiencia en soluciones tecnológicas financieras, creamos una propuesta de valor para todas las empresas que buscan transformar la forma de hacer negocios y crear nuevos productos y servicios para poder competir con los líderes del mercado sin riesgos de descapitalización.')}
        </DescBeneficios>
        <ContButton id={'ContButtonLeft'}>
          <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'}/>
        </ContButton>
      </ContInfoBeneficiosPage>
      <ReduceCostos/>
      <ProcesosSeguros/>
      <AutomatizacionOperaciones/>
      {isMobile ? (
        <FacilitarAgilizarProcesosMobile/>
      ) : (
        <FacilitarAgilizarProcesos/>
      )}
    </ContBeneficiosPage>
  )
}
