import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material';
import '../../globals/services-styles.css';
import {ContAnalisisPage, BtnOnbard, ContButton, IndentityPhoneImg, } from './AnalisisPage.styles';
import { IdentityCont, ContTextIdentity, TextIdentitySummary, ContCardIdentity, ContOnb, ContHowWork, TitleHowWork, ContTextDescHowWork, TextDescHowWork } from '../globalpages.styles';
import ButtonSpecial from '../../components/ButtonSpecial';
import ActionDemo from '../../components/ActionDemo/index';
import analisisIdentity from '../../assets/images/Servicios/analisis-Identity.webp';
import AnalisisService from '../../assets/images/Servicios/img-analisis-services.webp';
import StepsServices from '../../components/StepsServices/index';
import ServicesIntegrations from '../../components/ServicesIntegrations/index';
import ServicesBeneficios from '../../components/ServicesBeneficios/index';
import CCIntegration from '../../assets/images/Servicios/circulo-hover.webp';
import BuroCredito from  '../../assets/images/Servicios/buro-services.webp';
import './stepper.css'; 

const AnalisisItems = [
  {
    title: 'Facilidad para consulta de historial',
  },
  {
    title: 'Obtén score crediticio en segundos',
  },
  {
    title: 'Cumple normativas de PLD',
  },
  {
    title: 'Integración mediante APIS',
  },
  {
    title: 'Generación de reportes automatizados',
  }
];

const stepsAnalisis = [
  { number: 1, title: '¿Qué tipo de reporte deseas obtener?', subtitle: 'Personaliza el tipo de información que quieres obtener de tu cliente.' },
  { number: 2, title: 'Registro de cliente', subtitle: 'El cliente envía toda su información.' },
  { number: 3, title: 'Analiza la información', subtitle: 'La información pasa por nuestros proveedores para analizar el historial crediticio.'},
  { number: 4, title: `Resultados`, subtitle: 'Determina si tu cliente es apto para obtener un producto financiero.'}
];

export const AnalisisPage = () => {
  const { t } = useTranslation();
  const imagesToShow = [
    { src: CCIntegration, visible: true, alt: 'Logo circulo de crédito', },
    { src: BuroCredito, visible: true, alt: 'Logo buro de crédito', },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

 return(
  <ContAnalisisPage id={'ContAnalisisPage'}>
    <ContCardIdentity id={'ContCardIdentity'}>
      <IdentityCont>
        <Grid container spacing={2} className={'ContCardIdentity GridBlueCard'}>
          <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 1 }} style={{paddingLeft: '5%'}}>
            <ContOnb>
              <BtnOnbard>
              {t('Análisis de Crédito')}
              </BtnOnbard>
            </ContOnb>
            <ContTextIdentity>
              {t('Conoce el historial crediticio de tus clientes')}
            </ContTextIdentity>
            <TextIdentitySummary>
              {t('Evalúa la capacidad de pago de tus clientes consultando su comportamiento, historial y score crediticio de manera fácil y rápida.')}
            </TextIdentitySummary>
            <ContButton>
              <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'secondary'}/>
            </ContButton>
          </Grid>
          <Grid item xs={12} sm={12} md={6} 
            order={{ xs: 1, sm: 1, md: 1 }}
            className={'GridStylesCardImage'}
          >
            <IndentityPhoneImg src={analisisIdentity} alt={'Análisis crediticio'} visible={true} width={410}/>
          </Grid>
        </Grid>
      </IdentityCont>
    </ContCardIdentity>

    <ContHowWork id={'ContHowWork'}>
      <TitleHowWork>
      {t('¿Cómo funciona?')}
      </TitleHowWork>
      <ContTextDescHowWork>
        <TextDescHowWork>
          {t('Establece parámetros y consulta la información que requieras de tus clientes con los principales proveedores de score crediticio y score alternativo para obtener análisis, reportes y evaluación de su comportamiento.')}
        </TextDescHowWork>
      </ContTextDescHowWork>

      <StepsServices steps={stepsAnalisis} version={2} />

      <ContButton>
        <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'primary'}/>
      </ContButton>
    </ContHowWork>

    <ServicesBeneficios items={AnalisisItems} image={AnalisisService} alt={'Score crediticio del client'}/>

    <ServicesIntegrations imagesToShow={imagesToShow} text={'Los mejores proveedores de análisis crediticios te ayudarán a determinar si tu cliente cumple con tus requerimientos.'}/>

    <ActionDemo />

  </ContAnalisisPage>
 )
};