import React from 'react';
import {ContTitleLegals, ContLegals, GlobalStylesLegals, TitleBTag} from './Legals.styles';

export const TerminosYCondiciones = () => {

  return (
    <div className={'section-first'}>
      <GlobalStylesLegals/>
      <ContTitleLegals>
        Términos y condiciones
      </ContTitleLegals>
      <div className={'container-fit'}>
        <ContLegals>
          <div className="col">
            <div className="row mt-3">
              <div className="text-justify mt-2">
              Medici & Satoshi SAPI DE CV (en lo sucesivo “DYNAMICORE”), con domicilio en Eugenia 516, Piso 4, Col. 
              Del Valle Centro, C.P. 03100, Benito Juárez, CDMX, México, te da a conocer por este medio los 
              Términos y Condiciones generales de uso de sus Páginas Web (en adelante, la “Página”), 
              sus aplicaciones móviles (en lo sucesivo “Apps”) y/o cualquier otra plataforma digital que 
              DYNAMICORE ponga a tu disposición, en las cuales se pueden encontrar en forma enunciativa más no
               limitativa, información, servicios, consultas, contenidos y/o productos. 
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-justify mt-2">
                Los presentes Términos y Condiciones Generales de Uso (en lo sucesivo “Términos” y/o “Condiciones”) 
                tienen un carácter obligatorio y vinculante; todo usuario deberá abstenerse de utilizar la Página, 
                las Apps y/o los servicios ofrecidos por DYNAMICORE en caso de no aceptarlos. 
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-justify mt-2">
                Toda aplicación o gestión dentro de la Página y/o las Apps se entenderá como la aceptación 
                expresa de los presentes Términos y Condiciones. Al aceptar estos Términos, aceptas que existe 
                una relación jurídica válida y vinculante entre DYNAMICORE y tú, vigente hasta en tanto no manifiestes 
                tu deseo de terminar dicha relación, solicitando el cierre de tu cuenta de usuario creada conforme 
                a lo dispuesto en la sección IV siguiente y absteniéndote de utilizar cualquier servicio que 
                DYNAMICORE ofrece o llegue a ofrecer. 
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-justify mt-2">
                Como usuario tienes la obligación de leer, entender y aceptar todas las condiciones establecidas 
                en los Términos, en el Aviso de Privacidad y en los demás documentos análogos que impliquen una 
                referencia directa o indirecta a los primeros, antes de registrarse como usuario o realizar cualquier 
                gestión dentro de la Página. 
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-center">
                <TitleBTag>LEE ESTOS TÉRMINOS Y CONDICIONES DETENIDAMENTE ANTES DE ACCEDER O USAR LOS SERVICIOS.</TitleBTag>
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>I. USO Y RESTRICCIONES </b></div>
              <div className="text-justify mt-2">
                Mediante tu acceso y uso de la Página, las Apps o los productos de DYNAMICORE, estás acordando 
                vincularte jurídicamente por estos Términos y Condiciones, que pueden establecer una relación 
                contractual entre tú y DYNAMICORE. Si no aceptas dichos Términos y Condiciones, no podrás acceder 
                o usar los Servicios, mismos que estarán únicamente disponibles para personas que gocen con la 
                capacidad legal para contratar y para quienes no hayan sido vetados definitiva o parcialmente por 
                DYNAMICORE, a su sola discreción, en relación a su comportamiento crediticio o personal. 
              </div>
              <div className="text-justify mt-2">
                DYNAMICORE podrá poner fin de inmediato a estos Términos y Condiciones o cualquiera de los Servicios 
                respecto de ti o, en general, dejar de ofrecer o denegar el acceso a los mismos o cualquier parte de 
                ellos, en cualquier momento y por cualquier motivo. Se podrán aplicar condiciones suplementarias a 
                determinados Servicios, cómo políticas para una actividad o una promoción particular, y dichas 
                condiciones suplementarias se comunicarán en relación con los Servicios aplicables.
                <br/>
                Las condiciones suplementarias se establecen además de los Términos y Condiciones, y se considerarán 
                una parte más de éstas, para los fines de los Servicios aplicables. Las condiciones suplementarias 
                prevalecerán sobre las Condiciones en el caso de conflicto con respecto a los Servicios aplicables. 
                Para solicitar cualquiera de los Servicios de DYNAMICORE, deberás completar el formulario 
                correspondiente con tu información personal de manera precisa, verdadera y libre de vicios de mala fe, 
                asumiendo el compromiso de monitorear y modificar tus datos personales cuando sea necesario. 
              </div>
              <div className="text-justify mt-2">
                DYNAMICORE no se responsabilizará bajo ninguna circunstancia de la imprecisión de los datos personales 
                que brindes y/o validez, reservándose la facultad de requerir documentación comprobatoria. 
                La recopilación y el uso que hacemos de la información personal en relación con los Servicios 
                es conforme a lo dispuesto en nuestro Aviso de Privacidad Integral para Clientes/Usuarios, 
                disponible en <a href={'https://dynamicore.io/aviso-de-privacidad/'} target={'_blank'} id={'terms'} rel="noreferrer">https://dynamicore.io/aviso-de-privacidad/</a> 
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>II.	MODIFICACIONES</b></div>
              <div className="text-justify mt-2">
                DYNAMICORE podrá modificar libremente y en cualquier momento los Términos y Condiciones cuando lo 
                considere oportuno. Las modificaciones serán efectivas después de la publicación por parte de DYNAMICORE
                de dichos Términos y Condiciones actualizados en su Página o las políticas modificadas o condiciones 
                suplementarias sobre el Servicio aplicable. Tu acceso o uso continuado de la Página, las Apps o los 
                Servicios después de dicha publicación, constituye tu consentimiento a vincularte por los Términos y 
                Condiciones y sus modificaciones, siendo la última versión publicada la que regulará inmediatamente 
                las relaciones comerciales que se generen al momento de realizarse la transacción. 
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>III.	OBJETO</b></div>
              <div className="text-justify mt-2">
                Los presentes Términos y Condiciones regulan la autorización de uso que otorga DYNAMICORE a 
                los Clientes y Usuarios, para que éstos ingresen a su Página, sus Apps, se informen sobre los 
                Servicios que DYNAMICORE ofrece, y en su caso, soliciten alguno de ellos. La celebración de la 
                relación contractual se da únicamente entre DYNAMICORE y Clientes personas físicas de nacionalidad 
                mexicana, mayores de edad con capacidad legal para contratar. El proceso de adquisición de los 
                Servicios de DYNAMICORE es siempre a través de las Plataformas y/o Apps de DYNAMICORE y nunca de 
                forma presencial o a través de algún intermediario. El Cliente y/o Usuario, al utilizar las 
                Plataformas y/o Apps de DYNAMICORE, declara que actúa por cuenta propia y que el crédito será 
                para uso personal y nunca para un tercero.
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>IV.	CUENTA DE USUARIO</b></div>
              <div className="text-justify mt-2">
                Sujeto al cumplimiento de los presentes Términos y Condiciones, los Clientes y/o Usuarios crean, con su correo electrónico o teléfono, una cuenta de usuario personal, exclusiva e intransferible para realizar los procesos de adquisición de créditos y análisis crédito de DYNAMICORE en la Página o las Apps respectivamente
              </div>
              <div className="text-justify mt-2">
                DYNAMICORE se reserva cualquier derecho que no haya sido expresamente otorgado por los presentes Términos y Condiciones. El uso de la Cuenta de Usuario es personal e intransferible, por lo cual los Clientes y/o Usuarios no se encuentran facultados para ceder los datos de validación para el acceso a su perfil. En caso de olvido de los datos de validación o de usurpación de éstos, es obligación del Cliente y/o Usuario informar a DYNAMICORE durante el Inicio de Sesión a través de la opción “¿Olvidaste o no tienes contraseña?” o mediante el envío de un correo electrónico a la cuenta de support@dynamicore.io. Es de la entera responsabilidad del Cliente y/o Usuario mantener de forma confidencial y en un lugar seguro su usuario y contraseña para acceder a su perfil en dynamicore.io, por lo que mediante el presente instrumento, el Cliente y/o Usuario deslinda a DYNAMICORE de toda responsabilidad por la pérdida o daño alguno, sin importar su alcance o naturaleza, que resulte del acceso no autorizado al perfil del Cliente o Usuario.
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>V. PÁGINA WEB Y APPS</b></div>
              <div className="text-justify mt-2">
                La información mostrada en la Página y/o Apps de DYNAMICORE es meramente con fines informativos. 
                El material que se encuentra en la Página, y en estos Términos, está sujeto a cambios sin previo aviso, 
                que estarán visibles tanto en la Página como en los Términos con la última fecha de actualización 
                señalada. El acceso al Servicio puede ser limitado o lento, o verse afectado debido a una serie de 
                factores. como lo son: Servidores, redes, fallas en el hardware (incluida tu propia computadora), 
                líneas y conexiones de telecomunicaciones y otros equipos electrónicos y mecánicos; falla del software, 
                que incluye, entre otras cosas, errores, virus, problemas de configuración, 
                incompatibilidad de sistemas, utilidades o aplicaciones, el funcionamiento de cortafuegos o 
                programas de detección, códigos ilegibles o irregularidades dentro de documentos particulares 
                u otro contenido; sobrecarga de capacidades del sistema; daños causados por clima severo, terremotos, 
                guerras, insurrecciones, disturbios, conmociones civiles, hechos fortuitos, accidentes, incendios, daños
                 por agua, explosiones, averías mecánicas o desastres naturales; interrupción (ya sea parcial o total) 
                 de fuentes de alimentación u otra utilidad del servicio; huelga u otra detención (ya sea parcial o 
                 total) del trabajo; restricciones gubernamentales o regulatorias, resoluciones cambiarias, órdenes 
                 judiciales u otras formas de intervención humana; o cualquier otra causa (ya sea similar o diferente 
                 a cualquiera de las anteriores) que sea ajena al control de DYNAMICORE. 
                 <br/>
                 </div>
              <div className="text-justify mt-2">
                Podrán mostrarse enlaces a una página diferente a DYNAMICORE, que se proporcionan únicamente como indicadores de información en temas que podrían ser útiles a usuarios del Servicio. En este sentido, DYNAMICORE no tiene el control del contenido en las páginas. DYNAMICORE no garantiza el contenido en dichas páginas, incluida la precisión, integridad y confiabilidad de los sitios, así como que dicho contenido se encuentre libre de reclamos de derechos de autor, marcas registradas u otras infracciones relacionadas a los derechos de un tercero o a que dicho contenido esté libre de virus o alguna otra contaminación. Si decides seguir el enlace a un sitio web no controlado por DYNAMICORE, debes hacerlo bajo tu propio riesgo. DYNAMICORE no garantiza la autenticidad de los documentos de Internet. Los enlaces de páginas web que no sean controladas por DYNAMICORE no implican ninguna responsabilidad por las opiniones, ideas, productos, información o servicios ofrecidos en dichos sitios, ni ninguna representación referente al contenido de los mismos.
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>VI.	PROPIEDAD INTELECTUAL</b></div>
              <div className="text-justify mt-2">
                DYNAMICORE y todas aquellas marcas de su propiedad son Marcas Registradas, protegidas por la Ley de la Propiedad Industrial tanto en México como en el extranjero según las leyes aplicables. La utilización, difusión, exhibición, explotación, comercialización o cualquier otro uso, sea parcial o total, de forma idéntica o que confunda en menor o mayor grado; sea por cualquier medio, incluyendo mas no limitándose al impreso, magnético, óptico, electrónico o informático, está expresamente prohibido sin previa autorización por escrito del titular de los derechos de autor y/o marca correspondiente; que en el caso concreto es DYNAMICORE. Cualquier contravención a lo anteriormente expuesto o la legislación aplicable en materia de propiedad intelectual, industrial y derechos de autor será considerada y perseguida como un delito penal de comisión directa. El logotipo, diseños, formas, marcas nominativas, innominadas y mixtas de DYNAMICORE así como cualquier material estático o interactivo incluido en la Página y las Apps, está debidamente registrado ante las autoridades competentes y son propiedad de DYNAMICORE. 
              </div>
              <div className="text-justify mt-2">
                Los derechos de propiedad intelectual correspondientes a los Productos y servicios así como los materiales distintivos y dominios de la Página, los derechos de uso y explotación correspondientes mismos que incluyen mas no se limitan a su publicación, reproducción, divulgación, transformación y distribución son propiedad exclusiva de DYNAMICORE. 
              </div>
              <div className="text-justify mt-2">
                El usuario no adquirirá bajo ninguna circunstancia concesión o derecho alguno sobre la propiedad intelectual e industrial de DYNAMICORE por el mero uso de su Página y Apps de los Servicios ofrecidos en las mismas; por lo tanto, su uso no podrá ser considerado como una autorización tácita o expresa para utilizar alguno de los elementos de la propiedad intelectual o de derecho de autor de DYNAMICORE con algún fin diverso a los contemplados por el presente documento. 
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>VII.	CONSENTIMIENTO</b></div>
              <div className="text-justify mt-2">
                Manifiesta el Cliente y/o Usuario que la relación contractual que se puede llegar a generar por el uso 
                de la Página, las Apps o cualquiera de los Productos ofrecidos será directamente con DYNAMICORE, y 
                consistirá en una prestación de servicios celebrada por medios electrónicos, en la cual el Cliente es
                 el acreditado y DYNAMICORE es el acreditante. En virtud de las condiciones de capacidad legal 
                 establecidas en la legislación mexicana vigente y de la validez de la manifestación expresa de 
                 voluntad a través de medios electrónicos establecida en los artículos 89 y 90 del Código de Comercio 
                 y el artículo 7 de las Disposiciones de carácter general en materia de transparencia aplicable a las 
                 Sociedades Financieras de Objeto Múltiple, Entidades No Reguladas, como Cliente y/o Usuario al momento de la creación de tu Cuenta, manifiestas expresamente tu consentimiento y contar con la capacidad suficiente para celebrar contratos que se pueden o pudieren llegar a realizar accediendo y usando la Página, la App o los Productos de https://dynamicore.io En este sentido, la aceptación de los presentes Términos y Condiciones, así como de los contratos de adhesión respectivos de cada Producto, es el medio por el que manifiestas el consentimiento expreso de someterse a los mismos. 
              </div>
              <div className="text-justify mt-2">
                Adicionalmente, reconoces y aceptas que podrás expresar tu consentimiento respecto de los Servicios que contrates a través de las Plataformas así como otorgar autorizaciones a DYNAMICORE necesarias para su contratación, mediante medios electrónicos tales como, incluyendo sin limitar, el Número de Identificación Personal (“NIP”), huella digital y/o firma electrónica. En cuyo caso, DYNAMICORE informará previamente al Cliente los métodos en que este podrá generar dichos métodos de autenticación. 
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>VIII.	INDEMNIZACIÓN</b></div>
              <div className="text-justify mt-2">
                El Cliente y/o Usuario indemnizará y mantendrá indemne a DYNAMICORE, sus filiales, empresas controladas 
                y/o controlantes, directivos, administradores, representantes y empleados, por cualquier reclamo o 
                demanda de otros Usuarios o terceros por sus actividades en la Página o por su incumplimiento de los 
                Términos y Condiciones Generales y demás Políticas que se entienden incorporadas al presente o por 
                la violación de cualesquiera leyes o derechos de terceros, incluyendo los honorarios de abogados en una cantidad razonable.
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>IX.	DOMICILIO Y LEGISLACIÓN APLICABLE </b></div>
              <div className="text-justify mt-2">
                Se fija como domicilio de DYNAMICORE el ubicado en Eugenia 516, Piso 4, Col. Del Valle Centro, C.P. 03100, Benito Juárez, CDMX, México. Para lo no previsto en estos Términos y Condiciones, los Clientes y Usuarios acuerdan someterse a las leyes aplicables de los Estados Unidos Mexicanos.
              </div>
              <div className="text-justify mt-2">
                Para la interpretación y ejecución de los presentes Términos y Condiciones, el Cliente o Usuario acepta someterse a la jurisdicción y competencia de los tribunales competentes de Ciudad de Mexico, CDMX, renunciando expresamente al fuero que pudiese corresponderle por razón de su domicilio presente o futuro. 
              </div>
            </div>
            <div className="row mt-3">
              <div className="text-left"><b>X.	ADVERTENCIAS</b></div>
              <div className="text-justify mt-2">
                Incumplir tus obligaciones te puede generar comisiones e intereses moratorios. Contratar créditos que excedan tu capacidad de pago afecta tu historial crediticio.
              </div>
            </div>

            <div className="custom-hr" />
            <div className="row mt-3">
              <div className="text-right mt-4">
                Última actualización 15 de Enero del 2023
              </div>
            </div>
          </div>
        </ContLegals>
      </div>
    </div>
  );
};
