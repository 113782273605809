import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material';
import {ContScoringPage, BtnOnbard, ContButton, IndentityPhoneImg } from './ScoringPage.styles';
import { IdentityCont, ContTextIdentity, TextIdentitySummary, ContCardIdentity, ContOnb, ContHowWork, ContTextDescHowWork, TitleHowWork, TextDescHowWork } from '../globalpages.styles';
import ButtonSpecial from '../../components/ButtonSpecial';
import ActionDemo from '../../components/ActionDemo/index';
import ScoringImg from '../../assets/images/Servicios/img-scoring.webp';
import ScoringService from '../../assets/images/Servicios/img-scoring-ben.webp';
import ServicesIntegrations from '../../components/ServicesIntegrations/index';
import ServicesBeneficios from '../../components/ServicesBeneficios/index';
import Tiaxa from  '../../assets/images/Servicios/tiaxca-services.webp';
import Palenca from  '../../assets/images/Servicios/palenca-service.webp';
import Nuba from  '../../assets/images/Servicios/nubarium-services.webp';

const ScoringItems = [
  {
    title: 'Confiabilidad y transparencia ',
  },
  {
    title: 'Adquisición de clientela digital',
  },
  {
    title: 'Implementación por medio de API',
  },
  {
    title: 'Cumplimiento regulatorio',
  },
  {
    title: 'Reportes automatizados',
  },
];

{/*const stepsAnalisis = [
  { number: 1, title: '¿Qué tipo de reporte deseas obtener?', subtitle: 'Personaliza el tipo de información que quieres obtener de tu cliente.' },
  { number: 2, title: 'Registro de cliente', subtitle: 'El cliente envía toda su información.' },
  { number: 3, title: 'Analiza la información', subtitle: '...'},
  { number: 4, title: `Resultados`, subtitle: 'Determina si tu cliente es apto para obtener un producto financiero.'}
];*/}

export const ScoringPage = () => {
  const { t } = useTranslation();
  const imagesToShow = [
    { src: Tiaxa, visible: true, alt: 'Logo Tiaxa', },
    { src: Palenca, visible: true, alt: 'Logo Palenca', },
    { src: Nuba, visible: true, alt: 'Logo Nubarium', },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ContScoringPage id={'ContScoringPage'}>

      <ContCardIdentity id={'ContCardIdentity'}>
        <IdentityCont>
          <Grid container spacing={2} className={'ContCardIdentity GridBlueCard'}>
            <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 2 }} style={{paddingLeft: '5%'}}>
              <ContOnb>
                <BtnOnbard>
                  {t('Score Alternativo')}
                </BtnOnbard>
              </ContOnb>
              <ContTextIdentity>
                {t('Llega a personas sin historial de crédito con metadatos')}
              </ContTextIdentity>
              <TextIdentitySummary>
                {t('Obtén la puntuación de crediticia de tus clientes por medio de su comportamiento digital.')}
              </TextIdentitySummary>
              <ContButton>
                <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'secondary'}/>
              </ContButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} 
              order={{ xs: 2, sm: 2, md: 1 }}
              className={'GridStylesCardImage'}
            >
              <IndentityPhoneImg src={ScoringImg} alt={'Análisis de score alternativo'} width={430} visible={true} />
            </Grid>
          </Grid>
        </IdentityCont>
      </ContCardIdentity>

      <ContHowWork id={'ContHowWork'}>
        <TitleHowWork>
          {t('¿Cómo funciona?')}
        </TitleHowWork>
        <ContTextDescHowWork>
          <TextDescHowWork>
            {t('Analiza millones de metadatos y estadísticas mediante el smartphone de tus clientes, generando patrones predictivos los cuales te ayudarán a definir su score.')}
          </TextDescHowWork>
        </ContTextDescHowWork>

        <ContButton>
          <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'primary'}/>
        </ContButton>
      
      </ContHowWork>

      <ServicesBeneficios items={ScoringItems} image={ScoringService} alt={'Score alternativo dynamicore'} />

      <ServicesIntegrations imagesToShow={imagesToShow} noSpace={false} text={'Amplia tu mercado con ayuda de nuestros proveedores de Score Alternativo.'}/>

      <ActionDemo />
    </ContScoringPage>
  )
}
