import styled, {css, createGlobalStyle } from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const GlobalStylesTitleSpecial = createGlobalStyle`
  @keyframes fluido {
    0% { background-position: 0 50% }
    50% { background-position: 100% 50% }
    100% { background-position: 0 50% }
  }
`

export const ContTitleSpecial = styled.h1`
  width: 60%;
  margin-bottom: 20px;
  font-family: ${GlobalFonts.fontBold};
  font-size: 64px;
  line-height: 70px;
  text-align: center;

  z-index: 1;

  animation: fluido 20s ease-in-out infinite;
  background: linear-gradient(
      -45deg,
      #9E1ED3,
      #D52323,
      #F4B11C,
      #23d5ab,
      #23a6d5,
      ${GlobalColors.colorPrimary}
  );
  background-size: 300%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @media(min-width: 300px) and (max-width: 600px){
    width: 100%;
    font-size: 40px;
    line-height: 46px;
    text-align: left !important;
    ${props =>
      props.isCenter &&
      css`
      text-align: center !important;
      `}
  }
  @media(min-width: 601px) and (max-width: 767px){
    width: 100%;
    font-size: 40px;
    line-height: 46px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
    font-size: 50px;
    line-height: 60px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 80%;
    font-size: 50px;
    line-height: 60px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 70%;
    font-size: 50px;
    line-height: 60px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 80%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 70%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 70%;
  }
  @media(min-width: 1920px){
    width: 60%;
  }
`
export const ContTitleSpecial2= styled.h1`
  width: 90%;
  margin-top: 30px;
  margin-bottom: 20px;
  font-family: ${GlobalFonts.fontBold};
  font-size: 60px;
  line-height: 70px;
  text-align: center;

  z-index: 1;

  animation: fluido 20s ease-in-out infinite;
  background: linear-gradient(
      -45deg,
      #9E1ED3,
      #D52323,
      #F4B11C,
      #23d5ab,
      #23a6d5,
      ${GlobalColors.colorPrimary}
  );
  background-size: 300%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @media(min-width: 300px) and (max-width: 600px){
    width: 100%;
    font-size: 40px;
    line-height: 46px;
    text-align: center !important;
    ${props =>
      props.isCenter &&
      css`
      text-align: center !important;
      `}
  }
  @media(min-width: 601px) and (max-width: 767px){
    width: 100%;
    font-size: 40px;
    line-height: 46px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
    font-size: 40px;
    line-height: 55px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 40px;
    line-height: 55px;
  }
  @media(min-width: 1080px) and (max-width: 1279px) {
    font-size: 40px;
    line-height: 55px;
  }
`
