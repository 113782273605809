import styled from 'styled-components';

export const ContLayout = styled.div`
  width: 100%;
  height: auto;
  //background-color: #FBFFFF;
  background-image: url(${props => props.backgroundimage ? props.backgroundimage : 'unset'});
  background-repeat: repeat;
  background-position: left top;
  background-size: auto;

  @media(min-width: 300px) and (max-width: 767px){
    background-position: center center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    background-position: center center;
  }
`
