import React from 'react';
import {
  ContBtnActionHome,
  ContError404,
  ContImgError404,
  TextError404,
  TextInfoNotFound,
  TextPageNotFound,
} from './Error404.styles';
import ButtonSpecial from '../ButtonSpecial';
import ImgError404 from '../../assets/images/404.svg';
export const Error404 = () => {

  return (
    <div className={'section-first container-fit'}>
      <ContError404>
        <TextError404>Error 404</TextError404>
        <TextPageNotFound>Página no encontrada...</TextPageNotFound>
        <TextInfoNotFound>Lo sentimos, la página que estás buscando no existe.</TextInfoNotFound>
        <ContImgError404>
          <img src={ImgError404} alt={'Página no encontrada'}/>
        </ContImgError404>
        <ContBtnActionHome>
          <ButtonSpecial
            text={'Regresar al inicio'}
            theme={'primary'}
            type={'/'}
            linkTo={'/'}
            target={'_self'}
          />
        </ContBtnActionHome>
      </ContError404>
    </div>
  )
}
