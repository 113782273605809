import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContDatatoggle, Row, Column, ContentContainer, Icon, Text } from "./DataToggle.styles";
import { useHover } from './hoverFunctions';
import UserOnboard from '../../assets/images/Icons-datatoggle/user-onboard.webp';
import UserOnboardHover from '../../assets/images/Icons-datatoggle/onboarding-hover.webp';
import IcoAnalisis from '../../assets/images/Icons-datatoggle/icon-analisis.webp';
import IcoAnalisisHover from '../../assets/images/Icons-datatoggle/icon-analisis-hover.webp';
import IcoPayServces from '../../assets/images/Icons-datatoggle/ico-payservices.webp';
import IcoPayServcesHover from '../../assets/images/Icons-datatoggle/ico-payservices-hover.webp';
import IcoAutom from '../../assets/images/Icons-datatoggle/icon-autom.webp';
import IcoAutomHover from '../../assets/images/Icons-datatoggle/icon-autom-hover.webp';
import IcoDispersion from '../../assets/images/Icons-datatoggle/icon-dispersion.webp';
import IcoDispersionHover from '../../assets/images/Icons-datatoggle/icon-dispersion-hover.webp';
import IcoMoney from '../../assets/images/Icons-datatoggle/ico-money.webp';
import IcoMoneyHover from '../../assets/images/Icons-datatoggle/ico-money-hover.webp';
import IcoCC from '../../assets/images/Icons-datatoggle/ico-creditcard.webp';
import IcoCCHover from '../../assets/images/Icons-datatoggle/ico-credit-card-hover.webp';
import IcoNoti from '../../assets/images/Icons-datatoggle/ico-noti.webp';
import IcoNotiHover from '../../assets/images/Icons-datatoggle/ico-noti-hover.webp';
import IcoSecurity from '../../assets/images/Icons-datatoggle/ico-security.webp';
import IcoSecurityHover from '../../assets/images/Icons-datatoggle/ico-security-hover.webp';
import IcoScoring from '../../assets/images/Icons-datatoggle/ico-scoring.webp';
import IcoScoringHover from '../../assets/images/Icons-datatoggle/ico-scoring-hover.webp';

const Datatoggle = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isHovered, handleHover, handleUnhover } = useHover();
  const { isHovered: isHovered2, handleHover: handleHover2, handleUnhover: handleUnhover2 } = useHover();
  const { isHovered: isHovered3, handleHover: handleHover3, handleUnhover: handleUnhover3 } = useHover();
  const { isHovered: isHovered4, handleHover: handleHover4, handleUnhover: handleUnhover4 } = useHover();
  const { isHovered: isHovered5, handleHover: handleHover5, handleUnhover: handleUnhover5 } = useHover();
  const { isHovered: isHovered6, handleHover: handleHover6, handleUnhover: handleUnhover6 } = useHover();
  const { isHovered: isHovered7, handleHover: handleHover7, handleUnhover: handleUnhover7 } = useHover();
  const { isHovered: isHovered8, handleHover: handleHover8, handleUnhover: handleUnhover8 } = useHover();
  const { isHovered: isHovered9, handleHover: handleHover9, handleUnhover: handleUnhover9 } = useHover();
  const { isHovered: isHovered10, handleHover: handleHover10, handleUnhover: handleUnhover10 } = useHover();

  const handleNavigate = (rute) => {
    navigate(rute);
  };

  return (
    <ContDatatoggle id={'ContDatatoggle'}>
      <Row>
        <Column onClick={() => handleNavigate('/onboarding')}>
          <ContentContainer onMouseEnter={handleHover} onMouseLeave={handleUnhover}>
            <Icon>
              <img src={isHovered ? UserOnboardHover : UserOnboard } alt={'UserOnboard'} />
            </Icon>
            <Text>{t('Onboarding Digital')}</Text>
          </ContentContainer>
        </Column>
        <Column onClick={() => handleNavigate('/pld')}>
          <ContentContainer onMouseEnter={handleHover2} onMouseLeave={handleUnhover2}>
            <Icon>
              <img src={isHovered2 ? IcoSecurityHover : IcoSecurity} alt={'IcoSecurity'}/>
            </Icon>
            <Text>PLD</Text>
          </ContentContainer>
        </Column>
      </Row>
      <Row>
        <Column onClick={() => handleNavigate('/analisis-crediticio')}>
          <ContentContainer onMouseEnter={handleHover3} onMouseLeave={handleUnhover3}>
            <Icon>
              <img src={isHovered3 ? IcoAnalisisHover : IcoAnalisis} alt={'IcoAnalisis'}/>
            </Icon>
            <Text>{t('Análisis Crediticio')}</Text>
          </ContentContainer>
        </Column>
        <Column onClick={() => handleNavigate('/emision-tarjetas')}>
          <ContentContainer onMouseEnter={handleHover4} onMouseLeave={handleUnhover4}>
            <Icon>
              <img src={isHovered4 ? IcoCCHover : IcoCC} alt={'IcoCC'}/>
            </Icon>
            <Text>{t('Emisión de tarjetas')}</Text>
          </ContentContainer>
        </Column>
      </Row>
      <Row>
        <Column onClick={() => handleNavigate('/dispersion-pagos')}>
          <ContentContainer onMouseEnter={handleHover5} onMouseLeave={handleUnhover5}>
            <Icon>
              <img src={isHovered5 ? IcoDispersionHover : IcoDispersion} alt={'UserOnboard'}/>
            </Icon>
            <Text>{t('Dispersión')}</Text>
          </ContentContainer>
        </Column>
        <Column onClick={() => handleNavigate('/notificaciones')}>
          <ContentContainer onMouseEnter={handleHover6} onMouseLeave={handleUnhover6}>
            <Icon>
              <img src={isHovered6 ?  IcoNotiHover : IcoNoti} alt={'IcoNoti'}/>
            </Icon>
            <Text>{t('Campaña de notificaciones')}</Text>
          </ContentContainer>
        </Column>
      </Row>
      <Row>
        <Column onClick={() => handleNavigate('/cobranza')}>
          <ContentContainer onMouseEnter={handleHover7} onMouseLeave={handleUnhover7}>
            <Icon>
              <img src={isHovered7 ? IcoMoneyHover : IcoMoney} alt={'IcoMoney'}/>
            </Icon>
            <Text>{t('Cobranza')}</Text>
          </ContentContainer>
        </Column>
        <Column onClick={() => handleNavigate('/pago-servicios')}>
          <ContentContainer onMouseEnter={handleHover8} onMouseLeave={handleUnhover8}>
            <Icon>
              <img src={isHovered8 ? IcoPayServcesHover : IcoPayServces} alt={'IcoPayServces'}/>
            </Icon>
            <Text>{t('Pago de servicios')}</Text>
          </ContentContainer>
        </Column>
      </Row>
      <Row>
        <Column onClick={() => handleNavigate('/score-alternativo')}>
          <ContentContainer onMouseEnter={handleHover9} onMouseLeave={handleUnhover9}>
            <Icon>
              <img src={isHovered9 ? IcoScoringHover : IcoScoring} alt={'IcoScoring'}/>
            </Icon>
            <Text>{t('Score alternativo')}</Text>
          </ContentContainer>
        </Column>
        <Column onClick={() => handleNavigate('/bloqueo-dispositivos')}>
          <ContentContainer onMouseEnter={handleHover10} onMouseLeave={handleUnhover10}>
            <Icon>
              <img src={isHovered10 ? IcoAutomHover : IcoAutom} alt={'IcoAutom'}/>
            </Icon>
            <Text>{t('Candado de Smartphones')}</Text>
          </ContentContainer>
        </Column>
      </Row>
    </ContDatatoggle>
  )
};

export default Datatoggle;