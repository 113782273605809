import React from 'react';
import Layout from '../components/Layout';
import Back1 from '../assets/images/back-home-1.webp';
import SEO from '../components/SEO';
import FooterSitemap from '../components/FooterSitemap';
import UseCasesPage from '../pages/useCasesPage';

export const UseCases = () => {

  return (
    <Layout backgroundimage={Back1} actionDemo={false} footer={false}>
      <SEO
        title="Creación de workflows para crédito"
        description="
          Digitaliza tus productos financieros con el mejor core financiero, te ayudamos a automatizar tu proceso desde la primera interacción con el cliente hasta la dispersión y cobranza.
        "
        works='
          Digitalización de créditos 
          Core Bancario 
          Transformación financiera
          Innovación financiera
          Aprobación de Créditos 
          Administración de cartera
          Administración de clientes
          Work flows
          Onboarding digital
          Emisión de tarjeta
          Dispersión de crédito
          Cobranza
          Campaña de notificaciones 
          Mensajes automatizados 
          Automatización de créditos 
          Plataforma para gestión de créditos
          Software para Créditos 
        '
        bolImage={false}
        ogImage={`dynamicore-preview.png`}
        twitterImage={`dynamicore-preview.png`}
        canonicalUrl="https://dynamicore.io/caso-de-uso-credito"
        isUseCasesCredit={true}
      />
      <UseCasesPage/>
      <FooterSitemap />
    </Layout>
  )
}