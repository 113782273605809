import React from 'react';
import {Btn, BtnAnchor, BtnText, ContButton} from './Button.styles';
import { Link } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Button = ({
  text,
  linkTo,
  type = '',
  location = 'normal',
  theme = 'primary',
  withIcon = false,
  target = '_blank',
  size = 'medium'
}) => {

  const fnGoto = (link) => {
    console.log('fnGoto => link =>', link);
  };

  return (
    <ContButton location={location} size={size}>
      {type === '#'
        ? (
          <Btn theme={theme} onClick={() => fnGoto(linkTo)}>
            <BtnText>{text}</BtnText>
            {withIcon && <ArrowForwardIcon fontSize={size === 'medium' ? 'small' : 'medium'} />}
          </Btn>
        ) : (
          <Link to={linkTo} style={{ textDecoration: 'none' }}>
            <BtnAnchor theme={theme} id={'BtnA'}>
              <BtnText>{text}</BtnText>
              {withIcon && <ArrowForwardIcon fontSize={size === 'medium' ? 'small' : 'medium'} />}
            </BtnAnchor>
          </Link>
        )
      }
    </ContButton>
  )
}

export default Button;
