import React from 'react';
import Layout from '../components/Layout';
import Back1 from '../assets/images/back-home-1.webp';
import SEO from '../components/SEO';
import {AvisoDePrivacidad} from '../components/Legals';

export const AvisoPrivacidad = () => {

  return (
    <Layout backgroundimage={Back1} actionDemo={false}>
      <SEO
        title="Aviso de privacidad"
        description="Aviso de privacidad"
        works='Core Financiero, Fintech, Onboardig digital, 
          Aprobación de crédito, Gestión de cobranza, Análisis de crédito, 
          Core Bancario, Automatización de procesos, Procesos digitales, Producto financiero, 
          Servicios financieros, Digitalización de procesos, Software financiero, 
          Dispersión, Reportes automatizados, Corebanking, Sector financiero, SOFIPO, SOFOM, CNBV, Condusef, 
          Servicios financieros digitales, Plataforma financiera'
        bolImage={false}
        ogImage={`dynamicore-preview.png`}
        twitterImage={`dynamicore-preview.png`}
        canonicalUrl="https://dynamicore.io/aviso-de-privacidad"
      />
      <AvisoDePrivacidad/>
    </Layout>
  )
}
