import styled from 'styled-components';

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto; /* Centrar el contenedor horizontalmente */
  position: relative;
`;
export const ImageFull = styled.img`
  width: 800px;
  height: auto;
  @media(min-width: 0px) and (max-width: 299px){
    width: 190px;
    margin-bottom: 10px;
  }
  @media(min-width: 300px) and (max-width: 349px){
    width: 280px;
    margin-bottom: 10px;
  }
  @media(min-width: 350px) and (max-width: 399px){
    width: 320px;
    margin-bottom: 10px;
  }
  @media(min-width: 400px) and (max-width: 499px){
    width: 375px;
    margin-bottom: 20px;
  }
  @media(min-width: 500px) and (max-width: 599px){
    width: 400px;
    margin-bottom: 20px;
  }
  @media(min-width: 600px) and (max-width: 699px){
    width: 450px;
    margin-bottom: 20px;
  }
  @media(min-width: 700px) and (max-width: 899px){
    width: 500px;
  }
  @media(min-width: 900px) and (max-width: 1024px){
    width: 600px;
  }
`;
export const Image1 = styled.img`
  width: 220px;
  height: auto;
  margin: 80px 10px 0 20px;

  @media(min-width: 300px) and (max-width: 349px){
    display: none;
  }

  @media(min-width: 350px) and (max-width: 399px){
    display: none;
  }

  @media(min-width: 400px) and (max-width: 419px){
    display: none;
  }

  @media(min-width: 420px) and (max-width: 499px){
    width: 150.11px;
    height: 390.13px;
    margin: 0px 10px 0 0px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media(min-width: 500px) and (max-width: 599px){
    width: 184.11px;
    height: 381.13px;
    margin: 80px 10px 0 0px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media(min-width: 600px) and (max-width: 767px){
    width: 224.11px;
    height: 391.13px;
    margin: 80px 10px 0 0px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media(min-width: 768px) and (max-width: 1024px){
    width: 244.11px;
    height: 481.13px;
    margin: 20px 10px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }
  @media(min-width: 1025px) and (max-width: 1200px){
    width: 195.11px;
    height: 429.13px;
    margin: 80px 10px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }
  @media(min-width: 1201px) and (max-width: 10000px){
    width: 215.11px;
    height: 449.13px;
    margin: 80px 10px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const Image2 = styled.img`
  width: 220px;
  height: 420px;
  margin: 20px 20px 0 20px;

  @media(min-width: 300px) and (max-width: 349px){
    display: none;
  }

  @media(min-width: 350px) and (max-width: 399px){
    display: none;
  }

  @media(min-width: 400px) and (max-width: 419px){
    display: none;
  }

  @media(min-width: 420px) and (max-width: 499px){
    width: 150.11px;
    height: 390.13px;
    margin: -30px 0px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }

  @media(min-width: 500px) and (max-width: 599px){
    width: 184.11px;
    height: 381.13px;
    margin: 20px 10px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }
  @media(min-width: 600px) and (max-width: 767px){
    width: 224.11px;
    height: 391.13px;
    margin: 20px 10px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }
  @media(min-width: 768px) and (max-width: 1024px){
    width: 244.11px;
    height: 421.13px;
    margin: 20px 10px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }
  @media(min-width: 1025px) and (max-width: 1200px){
    width: 200.11px;
    height: 390.13px;
    margin: 20px 10px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }
  @media(min-width: 1201px) and (max-width: 10000px){
    width: 215.11px;
    height: 429.13px;
    margin: 20px 10px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const Image3 = styled.img`
  @media(min-width: 50px) and (max-width: 1024px){
    display: none;
  }
  @media(min-width: 1025px) and (max-width: 1200px){
    width: 188.11px;
    height: 410.13px;
    margin: 80px 10px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }
  @media(min-width: 1201px) and (max-width: 10000px){
    width: 200.11px;
    height: 425.13px;
    margin: 80px 10px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const Image4 = styled.img`
  @media(min-width: 50px) and (max-width: 1024px){
    display: none;
  }
  @media(min-width: 1025px) and (max-width: 1200px){
    width: 214.11px;
    height: 401.13px;
    margin: 20px 10px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }
  @media(min-width: 1201px) and (max-width: 10000px){
   width: 188.11px;
    height: 383.13px;
    margin: 20px 10px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }
  @media(min-width: 1201px) and (max-width: 10000px){
    width: 200.11px;
    height: 443.13px;
    margin: 20px 10px 0 0px;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;
