import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContNosotrosPage = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 70px !important;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 120px !important;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding-top: 27px !important;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    padding-top: 110px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding-top: 120px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding-top: 130px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding-top: 130px !important;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    padding-top: 130px !important;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    padding-top: 130px !important;
  }
  @media(min-width: 1920px){
    padding-top: 150px !important;
  }
`
export const ContInfoNosotrosPage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const DescNosotros = styled.div`
  width: 60%;
  margin-bottom: 40px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${GlobalColors.colorText};

  z-index: 1;
  
  & > span {
    font-family: ${GlobalFonts.fontBold};
  }

  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 80%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 80%;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 70%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 70%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 65%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 60%;
  }
  @media(min-width: 1920px){
    width: 50%;
  }
`
export const ContImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  margin: 50px 0 70px;
  padding: 0 50px;
  
  z-index: 2;
  
  @media(min-width: 300px) and (max-width: 767px){
    z-index: 2;
    padding: 0;
    margin: 0px 0 20px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding: 0;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin: 50px 0 40px;
    padding: 0 10px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin: 10px 0 40px;
    padding: 0 5px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin: 10px 0 40px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin: 10px 0 40px;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    margin: 10px 0 40px;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    margin: 10px 0 40px;
  }
  @media(min-width: 1920px){
    margin: 10px 0 40px;
  }
`
export const ContBack2 = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;

  @media(min-width: 300px) and (max-width: 329px){
    top: 680px;
  }

  @media(min-width: 330px) and (max-width: 382px){
    top: 650px;
  }
  @media(min-width: 383px) and (max-width: 392px){
    top: 590px;
  }
  @media(min-width: 393px) and (max-width: 415px){
    top: 570px;
  }
  @media(min-width: 416px) and (max-width: 529px){
    top: 530px;
  }
  @media(min-width: 530px) and (max-width: 609px){
    top: 480px;
  }
  @media(min-width: 610px) and (max-width: 767px){
    top: 500px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    top: 700px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    top: 650px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    top: 720px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    top: 780px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    top: 780px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    top: 700px;
  }
  @media(min-width: 1920px)  and (max-width: 4098px){
    top: 700px;
  }
`
export const Back2 = styled.img`
  width: 100%;
  height: auto;

  @media(min-width: 300px) and (max-width: 319px){
    height: 1300px;
  }

  @media(min-width: 320px) and (max-width: 329px){
    height: 1250px;
  }
  @media(min-width: 330px) and (max-width: 349px){
    height: 1200px;
  }
  //@media(min-width: 350px) and (max-width: 382px){
  //  height: 1150px;
  //}
  @media(min-width: 350px) and (max-width: 399px){
    height: 950px;
  }
  @media(min-width: 400px) and (max-width: 479px){
    height: 950px;
  }

  @media(min-width: 480px) and (max-width: 529px){
    height: 950px;
  }
  @media(min-width: 530px) and (max-width: 609px){
    height: 950px;
  }
  @media(min-width: 610px) and (max-width: 656px){
    height: 850px;
  }
  @media(min-width: 657px) and (max-width: 767px){
    height: 850px;
  }
  @media(min-width: 768px) and (max-width: 899px){
    height: 900px;
  }
  @media(min-width: 900px) and (max-width: 1023px){
    height: 750px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    height: 900px;
  }
  @media(min-width: 1080px) and (max-width: 1120px){
    height: 950px;
  }
  @media(min-width: 1121px) and (max-width: 1150px){
    height: 950px;
  }
  @media(min-width: 1151px) and (max-width: 1161px){
    height: 900px;
  }
  @media(min-width: 1162px) and (max-width: 1279px){
    height: 870px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    height: auto;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    height: auto;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    height: auto;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    height: auto;
  }
  @media(min-width: 1920px){
    height: auto;
  }
`
export const ContCreatePlat = styled.div`
  position: relative;
  z-index: 1;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-top: 10px !important;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-top: 10px !important;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin-top: 10px !important;
    padding: 0 50px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin-top: 10px !important;
    padding: 0 90px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-top: 40px !important;
    padding: 0 130px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-top: 40px !important;
    padding: 0 130px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin-top: 40px !important;
    padding: 0 140px;
  }
  @media(min-width: 1920px) and (max-width: 4098px){
    margin-top: 50px !important;
    padding: 0 140px;
  }
`
export const ContTitleCreate = styled.div`
  width: 95%;
  
  @media(min-width: 300px) and (max-width: 1023px){
    width: 100%;
  }
`
export const ContTextCreate = styled.h2`
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: ${GlobalFonts.fontBold};
  text-align: left;
  color: ${GlobalColors.colorSecondary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
    line-height: 38px;
    text-align: left;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    position: relative;
    width: 70%;
    margin: 0 auto 35px;
    font-size: 34px;
    line-height: 46px;
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 36px;
    line-height: 36px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 34px;
    line-height: 46px;
    
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 46px;
    line-height: 46px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 46px;
    line-height: 46px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 46px;
    line-height: 46px;
  }
  @media(min-width: 1920px){
    font-size: 46px;
    line-height: 46px;
  }
`
export const TextCreateSummary = styled.div`
  margin-bottom: 40px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 16px;
  line-height: 22px;
  color: ${GlobalColors.colorSecondary};
  
  & > span {
    font-family: ${GlobalFonts.fontBold};
  }

  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
    padding: 0px 30px;
  }
  @media(min-width: 768px) and (max-width: 899px){
    position: relative;
    width: 80%;
    margin: 0 auto 50px;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }
  @media(min-width: 900px) and (max-width: 939px){
    position: relative;
    width: 80%;
    margin: 0 auto 15px;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
  }
  @media(min-width: 940px) and (max-width: 989px){
    position: relative;
    width: 80%;
    margin: 0 auto 15px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  @media(min-width: 990px) and (max-width: 1023px){
    position: relative;
    width: 80%;
    margin: 0 auto 35px;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1080px) and (max-width: 1144px){
    font-size: 20px;
    line-height: 28px;
    //margin-bottom: 20px;
  }
  @media(min-width: 1145px) and (max-width: 1249px){
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 30px;
  }
  @media(min-width: 1250px) and (max-width: 1279px){
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 30px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  
`
export const ContButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 10px;
    justify-content: start;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 35px;
    justify-content: center;
  }
`
export const PhoneNosotrosImage = styled.img`
  width: 50%; /* Redimensionar al 50% del tamaño original */
  height: auto;
  @media(min-width: 768px) and (max-width: 1023px){
    width: 40%;
    height: auto;
  }
`
export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
export const ContOurMision = styled.div`
  position: relative;
  z-index: 1;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-top: 30px !important;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-top: 30px !important;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin-top: 100px !important;
    padding: 0 0px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin-top: 100px !important;
    padding: 0 0px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-top: 100px !important;
    padding: 0 0px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-top: 100px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin-top: 100px !important;
  }
  @media(min-width: 1920px){
    margin-top: 100px !important;
  }
`
export const ContImgMision = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media(min-width: 1px) and (max-width: 899px){
    & > img {
      display: none;
    }
  }
  @media(min-width: 900px) and (max-width: 1023px){
    & > img {
      width: 70%;
    }
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    & > img {
      width: 70%;
    }
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    & > img {
      width: 60%;
    }
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    & > img {
      width: 60%;
    }
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    & > img {
      width: 60%;
    }
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    & > img {
      width: 60%;
    }
  }
  @media(min-width: 1920px){
    & > img {
      width: 60%;
    }
  }
`
export const ContInfoMisionVision = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    flex-direction: column;
    margin: 0 auto;
  }
  @media(min-width: 768px) and (max-width: 899px){
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  @media(min-width: 900px) and (max-width: 1023px){
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 90%;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 95%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 95%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 93%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 95%;
  }
  @media(min-width: 1920px){
    width: 90%;
  }
`
export const TitleDifine = styled.h3`
  margin-top: 0px;
  position: relative;
  margin-bottom: 20px;
  font-family: ${GlobalFonts.fontBold};
  text-align: left;
  color: ${GlobalColors.colorPrimaryDark};
  
  @media(min-width: 300px) and (max-width: 899px){
    font-size: 30px;
    line-height: 38px;
    text-align: left;
  }
  @media(min-width: 900px) and (max-width: 1023px){
    font-size: 34px;
    line-height: 34px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    //background-color: red; 
    font-size: 36px;
    line-height: 36px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 34px;
    line-height: 46px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 48px;
    line-height: 54px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 46px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 46px;
  }
  @media(min-width: 1920px){
    font-size: 46px;
  }
`
export const SubTitleDifine = styled.div`
  @media(min-width: 300px) and (max-width: 767px){
    dsiplay: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 25px;
    font-family: ${GlobalFonts.fontRegular};
    text-align: left;
    font-size: 18px;
    line-height: 20px;
    color: ${GlobalColors.colorText};
  }
  @media(min-width: 768px) and (max-width: 3899px){
    display: none;
  }
`
export const CardTitle = styled.div`
  display: block;
  font-family: ${GlobalFonts.fontBold};
  text-align: left;
  color: ${GlobalColors.colorPrimary};
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
    margin-top: 0; 
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 30px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 38px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 38px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 38px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 38px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 38px;
    marginTop: 0; 
  }
  @media(min-width: 1920px){
    font-size: 38px;
    marginTop: 0; 
  }
`
export const CardTitleGray = styled.div`
  display: block;
  font-family: ${GlobalFonts.fontBold};
  text-align: left;
  color: #8F8F8F;
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
    margin-top: 0; 
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 30px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 38px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 38px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 38px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 38px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 38px;
    marginTop: 0; 
  }
  @media(min-width: 1920px){
    font-size: 38px;
    marginTop: 0; 
  }
`
export const CardDescription = styled.div`
  cursor: default;
  display: block;
  font-family: ${GlobalFonts.fontRegular};
  text-align: left;
  color: ${GlobalColors.colorText};

  @media(min-width: 300px) and (max-width: 767px){
    font-size: 16px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 16px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 18px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 18px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 18px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 18px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 18px;
  }
  @media(min-width: 1920px){
    font-size: 18px;
  }
`
export const ContCeoText = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const CornerImage = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 120px;
  height: 120px;
  @media(min-width: 300px) and (max-width: 429px){
    width: 60px;
    height: auto;
  }
  @media(min-width: 430px) and (max-width: 767px){
    width: 80px;
    height: auto;
  }
`
export const ContText = styled.div`
  padding-top: 20px;
  font-family: ${GlobalFonts.fontRegular};
  text-align: left;
  color: ${GlobalColors.colorText};

  @media(min-width: 300px) and (max-width: 767px){
    font-size: 14px;
    padding-top: 10px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 14px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 14px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 15px;
    padding-right: 20px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 16px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 16px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 16px;
  }
  @media(min-width: 1920px){
    font-size: 18px;
  }
`
export const ProfileImage = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-top: 10px;
`
export const NameCeoCont = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`
export const TitleNameCeo = styled.span`
  color: ${GlobalColors.colorPrimaryDark};
  font-family: ${GlobalFonts.fontBold};
  font-size: 18px;
  line-height: 21px;
`
export const TextBoldCard = styled.b`
  color: ${GlobalColors.colorPrimaryDark};
  font-family: ${GlobalFonts.fontBold};
  font-size: 16px;
`
export const ContFinalImage = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  z-index: -10;
 
  
  @media(min-width: 300px) and (max-width: 529px){
    background-color: #0C3A72;
  }
  @media(min-width: 300px) and (max-width: 399px){
    top: 650px;
  }
  @media(min-width: 400px) and (max-width: 519px){
    top: 600px;
  }
  @media(min-width: 520px) and (max-width: 549px){
    top: 550px;
  }
  @media(min-width: 550px) and (max-width: 767px){
    top: 550px;
  }
  @media(min-width: 768px) and (max-width: 899px){
    top: 450px;
  }
  @media(min-width: 900px) and (max-width: 1023px){
    top: 550px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    top: 600px;
  }
  @media(min-width: 1080px) and (max-width: 1120px){
    top: 650px;
  }
  @media(min-width: 1121px) and (max-width: 1279px){
    top: 650px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    top: 650px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    top: 650px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    top: 700px;
  }
  @media(min-width: 1920px){
    top: 800px;
  }
`
export const BackFinalImage = styled.img`
  width: 100%;

  @media(min-width: 300px) and (max-width: 529px){
    height: 500px;
    opacity: 0.1;
  }
  @media(min-width: 530px) and (max-width: 656px){
    height: 700px;
  }
  @media(min-width: 657px) and (max-width: 767px){
    height: 700px;
  }
  @media(min-width: 768px) and (max-width: 899px){
    height: 700px;;
  }
  @media(min-width: 900px) and (max-width: 1023px){
    height: 700px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    height: 800px;
  }
  @media(min-width: 1080px) and (max-width: 1120px){
    height: 850px;
  }
  @media(min-width: 1121px) and (max-width: 1279px){
    height: 700px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    height: 700px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    height: 700px;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    height: 700px;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    height: 700px;
  }
  @media(min-width: 1920px){
    height: 400px;
  }
`
export const GradinatCont = styled.div`
  width: 100%;
  height: 600px; /* Ajusta la altura según tus necesidades */
  background: linear-gradient(to right, #0C3A72 100%, #0B6BDD 100%);
`
export const ContStatisticsCounters = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin: 35px 0;
    flex-wrap: wrap;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin: 35px 0;
    flex-wrap: wrap;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin: 35px 0;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin: 70px 0;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin: 70px 0;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin: 70px 0;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin: 70px 0;
  }
  @media(min-width: 1920px){
    margin: 70px 0;
  }
`
export const ContItem = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  
  @media(min-width: 300px) and (max-width: 1023px){
    flex-direction: column;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const ContVerticalLineV = styled.div`
  width: 1px;
  height: 100px;
  background-color: ${GlobalColors.colorGrayLight};
  
  @media(min-width: 300px) and (max-width: 1023px){
    display: none;
  }
  @media(min-width: 1024px) {
    display: block;
  }
`
export const ContVerticalLineH = styled.div`
  margin-top: 30px;
  width: 100px;
  height: 1px;
  background-color: ${GlobalColors.colorGrayLight};
  
  @media(min-width: 300px) and (max-width: 767px){
    display: block;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    display: none;
  }
  @media(min-width: 1024px) {
    display: none;
  }
`
export const ContAction = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
`
