import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import {
  BrandItemSlider,
  ContImgItemSlider, ContInfoItemSlider,
  ContItemSlider,
  ContSliderClients,
  DescItemSlider,
  ImgItemSlider, NameItemSlider,
} from './SliderClients.styles';
import "../../assets/css/slick.min.css";
import "../../assets/css/slick-theme.min.css";
export const SliderClients = ({autoplay = true, rtl = false, dots = false, infinite = true, slidesToShow = 1, slidesToScroll = 1, speed = 2000, autoplaySpeed = 2000, arrows = false, data = []}) => {
  const settings = {
    dots: dots,
    infinite: infinite,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    autoplay: autoplay,
    rtl: rtl,
    arrows: arrows,
    speed: speed,
    autoplaySpeed: autoplaySpeed,
    // cssEase: "linear"
  };
  const { t } = useTranslation();
  return (
    <ContSliderClients>
      <Slider {...settings}>
        {
          data.map((item, index) => {
            return (
              <ContItemSlider key={index}>
                <DescItemSlider dangerouslySetInnerHTML={{__html: t(item.desc)}} />
                <BrandItemSlider>
                  <ContImgItemSlider>
                    <ImgItemSlider src={item.img} alt={item.name} />
                  </ContImgItemSlider>
                  <ContInfoItemSlider>
                    <NameItemSlider>
                      <a href={item.link} target={'_blank'} id={item.name} rel="noreferrer">
                        {item.name}
                      </a>
                    </NameItemSlider>
                    {/*<LinkItemSlider>
                      <a href={item.link} target={'_blank'}>
                        {item.link}
                      </a>
                    </LinkItemSlider>*/}
                  </ContInfoItemSlider>
                </BrandItemSlider>
              </ContItemSlider>
            )
          })
        }
      </Slider>
    </ContSliderClients>
  )
}
