import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { ALink, BtnLink, ContItemMenu, DropdownContainer, ArrowCont, ExpandIco } from "./ItemMenu.styles";
import { GlobalColors } from "../../globals";
import { useLocation, Link } from "react-router-dom";
import Datatoggle from '../DataToggle/index';
import IcoExpand from '../../assets/images/ico-expand.svg';

const ItemMenu = ({
    text,
    linkTo,
    type = "#",
    showDropdown = false,
  }) => {
    const { t } = useTranslation();
  const searchRaw = useLocation();
  const [isContentVisible, setIsContentVisible] = useState(false);

  const fnGoToElement = (el) => {
    if (searchRaw.pathname === "/") {
      console.log(searchRaw);
    }
    setTimeout(function () {
      const yOffset = -100;
      const titleElement = document.getElementById(el);
      // titleElement.scrollIntoView({ behavior: 'smooth' });
      const y =
        titleElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }, 500);
  };

  const fnItemActive = (el) => {
    return searchRaw.pathname.substring(1).toString() === el;
  };

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  return (
    <ContItemMenu>
      {type === "#" && showDropdown === false ? (
        <BtnLink
          color={GlobalColors.colorPrimaryDark}
          onClick={() => fnGoToElement(linkTo)}
          active={fnItemActive(linkTo)}
        >
          {t(text)}
        </BtnLink>
      ) : type === "#" && showDropdown === true ? (
        <>
          <BtnLink
            color={GlobalColors.colorPrimaryDark}
            onClick={() => toggleContent()}
            active={fnItemActive(linkTo)}
            arrowRight={true}
          >
            {t(text)}
            <ArrowCont
              onClick={toggleContent}
            >
              <div>
                <ExpandIco
                  src={IcoExpand}
                  alt={isContentVisible ? 'Expandir' : 'Contraer'}
                  isContentVisible={!isContentVisible}
                />
              </div>
              {isContentVisible && (
                <DropdownContainer id={'DropdownContainer'}>
                  <Datatoggle/>
                </DropdownContainer>
              )}
            </ArrowCont>
          </BtnLink>
        </>
      ) : type === "@" ? (
        <ALink
          color={GlobalColors.colorPrimaryDark}
          href={linkTo} 
          target="_blank" 
          rel="noopener noreferrer"
          id={'AlLink'}
        >
          {t(text)}
        </ALink>
      ) : (
        <Link to={`/${linkTo}`} style={{ textDecoration: 'none' }}>
          <ALink color={GlobalColors.colorPrimaryDark} active={fnItemActive(linkTo)}>
            {t(text)}
          </ALink>
        </Link>
      )}
    </ContItemMenu>
  );
};

export default ItemMenu;
