import styled, { createGlobalStyle } from 'styled-components';
import ImgBack from '../../assets/images/back-home-complete-4.svg';
import {GlobalColors, GlobalFonts} from '../../globals';

export const GlobalStylesOurApi = createGlobalStyle`
  .contZIndexOurApi{
    position: relative;
    padding-top: 100px;
    z-index: 2;

    @media(min-width: 300px) and (max-width: 767px){
      padding-left: 0px;
      padding-top: 60px !important;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      padding-left: 0px;
      padding-top: 50px;
      width: 100%;
    }
    @media(min-width: 1024px) and (max-width: 1079px){
      padding-left: 60px;
      padding-top: 80px;
      width: 100%;
    }
    @media(min-width: 1080px) and (max-width: 1279px){
      padding-left: 60px;
      padding-top: 100px;
      width: 80%;
    }
    @media(min-width: 1280px) and (max-width: 1407px){
      padding-left: 60px;
      padding-top: 100px;
      width: 80%;
    }
    @media(min-width: 1408px) and (max-width: 1439px){
      padding-top: 100px;
      width: 80%;
    }
    @media(min-width: 1440px) and (max-width: 1919px){
      padding-top: 100px;
      padding-left: 30px;
      width: 80%;
    }
    @media(min-width: 1920px){
      width: 80%;
      padding-top: 100px;
    }
  }
`

export const ContOurApi = styled.div`
  
`
export const ContOurApiBack = styled.div`
  position: absolute;
  width: 100%;
  height: 1000px;
  background-image: url(${ImgBack});
  background-repeat: no-repeat;
  background-size: cover;
  //clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 800px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 600px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    height: 600px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    height: 650px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    height: 650px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    height: 700px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    height: 750px;
  }
  @media(min-width: 1920px){
    height: 1000px;
  }
`

export const ContInfoApi = styled.div`
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    position: relative;
    width: 90%;
    margin: 0 auto;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 100%;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 100%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 80%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 80%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 80%;
  }
  @media(min-width: 1920px){
    width: 80%;
  }
`
export const TitleApi = styled.h5`
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: ${GlobalFonts.fontBold};
  font-size: 64px;
  line-height: 74px;
  color: ${GlobalColors.colorSecondary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
    line-height: 42px;
    text-align: left;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 40px;
    line-height: 42px;
    text-align: left;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 36px;
    line-height: 38px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 34px;
    line-height: 48px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 46px;
    line-height: 50px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 46px;
    line-height: 58px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 46px;
    line-height: 60px;
  }
  @media(min-width: 1920px){
    font-size: 46px;
  }
`
export const TextApi = styled.div`
  margin-bottom: 40px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 20px;
  line-height: 28px;
  color: ${GlobalColors.colorSecondary};
`
export const ContBtn = styled.div`
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  @media(min-width: 300px) and (max-width: 768px){
    justify-content: left;  
  }
`
export const ContImgApi = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    & > img {
      width: 100%;
    }  
  }
  @media(min-width: 768px) and (max-width: 1023px){
    & > img {
      width: 100%;
    }
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    & > img {
      width: 90%;
    }
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    & > img {
      width: 90%;
    }
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    & > img {
      width: 90%;
    }
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    & > img {
      width: 90%;
    }
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    & > img {
      width: 90%;
    }
  }
  @media(min-width: 1920px){
    & > img {
      width: 100%;
    }
  }
`
