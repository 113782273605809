import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContServicesBeneficios = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #DBDBDB;
    opacity: 0.3;
    z-index: -1;
  }

  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 10px !important;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 20px !important;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding-top: 20px !important;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    padding-top: 20px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding-top: 20px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding-top: 30px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding-top: 30px !important;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    padding-top: 30px !important;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    padding-top: 30px !important;
  }
  @media(min-width: 1920px){
    padding-top: 30px !important;
  }
`
export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media(min-width: 100px) and (max-width: 767px){
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media(min-width: 768px) and (max-width: 899px){
    display: none;
    //align-items: center;
    //justify-content: center;
  }
`;
export const LayoutImageBeneficios = styled.img`
  width: 70%;
  @media(min-width: 100px) and (max-width: 549px){
    width: 90%;
  }
  @media(min-width: 550px) and (max-width: 767px){
    width: 80%;
  }
  @media(min-width: 768px) and (max-width: 899px){
    width: 85%;
  }
  @media(min-width: 900px) and (max-width: 949px){
    width: 100%;
  }
  @media(min-width: 950px) and (max-width: 1023px){
    width: 95%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 85%;
  }
  @media(min-width: 1080px) and (max-width: 1200px){
    width: 80%;
  }
  @media(min-width: 1201px) and (max-width: 1300px){
    width: 75%;
  }
  @media(min-width: 1301px) and (max-width: 1400px){
    width: 75%;
  }
  @media(min-width: 1401px) and (max-width: 1500px){
    width: 65%;
  }
`

export const ContBeneficios = styled.div`
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  @media(min-width: 100px) and (max-width: 767px) {
    align-items: flex-start;
    justify-content: flex-start;
  }
`

export const TitleBeneficios = styled.h2`
  font-family: ${GlobalFonts.fontBold};
  font-size: 46px;
  line-height: 46px;
  color: ${GlobalColors.colorPrimaryDark};
  margin-bottom: 10px;
`

export const ContListBeneficios = styled.div`
  display: block;
  align-items: center;
  justify-content: flex-start;

  @media(min-width: 100px) and (max-width: 767px) {
    text-align: start;
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media(min-width: 768px) and (max-width: 899px) {
    display: block;
    text-align: left;
    align-items: center;
    justify-content: center;
    padding-left: 20%;
  }
`
export const ListItemBeneficios = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  @media(min-width: 768px) and (max-width: 899px) {
    justify-content: flex-start;
  }
`
export const ImgListBeneficios = styled.img`
  align-items: center;
  width: 30px;
`
export const TitleListBeneficios = styled.div`
  align-items: center;
  margin-left: 25px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 20px;
  line-height: 22px;
  color: #000000;
`