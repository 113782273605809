export default {
  'Integra y verifica la identidad de tus clientes en segundos.': 'Integrate and verify the identity of your customers in seconds.',
  'Agiliza la integración de nuevos clientes, solicitando información de manera digital y validándola con el método más seguro.': 'Speed up new customer onboarding by collecting their digital information and validating it securely.',
  '¿Cómo funciona?': 'How does it work?',
  'Recaba toda la información que necesites de tus clientes, información personal, documentos oficiales y realiza un escáner facial para la autenticación de identidad.': 'Gather all essential customer details, including personal information and official documents, also you can perform a facial scan to authenticate the identity of your customer.',
  'Registro del cliente': 'Customer registration',
  'El cliente crea una cuenta': 'Customer creates an account',
  'Validación': 'Validation',
  'Llena el formulario con su información personal.': 'Fills out the form with their personal information.',
  'Verificación de datos': 'Liveness detection',
  'Escaneo de Identificación y rostro.': 'ID and face scan.',
  'Registro exitoso': 'Successful registration',
  'Proceso completado': 'Process completed',
  'Mejora la experiencia del cliente': 'Enhanced customer experience.',
  'Optimiza y agiliza proceso de integración': 'Optimized and streamlined onboarding process.',
  'Recaba documentos digitales': 'Digital document collection.',
  'Validación OCR INE/IFE, CURP y RFC ': 'Validation of official documents, ID cards and passports.',
  'Consulta historial y score crediticio': 'Credit analysis and financial score.',
  'Alertas en Listas Negras': 'Blacklist Alerts',
  'Integraciones': 'Integrations',
  'Agilizamos y potencializamos el proceso del': 'Enhance ',
  'con estas integraciones.': 'with these Integrations',
  'Onboarding Digital ': 'digital Onboarding',
  'Análisis de Crédito': 'Credit Analysis',
  'Conoce el historial crediticio de tus clientes': 'Know your customers credit history',
  'Evalúa la capacidad de pago de tus clientes consultando su comportamiento, historial y score crediticio de manera fácil y rápida.': 'Evaluate costumers payment capacity quickly and easily by assessing their history and score.',
  'Establece parámetros y consulta la información que requieras de tus clientes con los principales proveedores de score crediticio y score alternativo para obtener análisis, reportes y evaluación de su comportamiento.': 'Establish parameters and consult necessary customer information with primary credit score providers. This generates detailed reports and analyses.',
  '¿Qué tipo de reporte deseas obtener?': 'Report Type Selection',
  'Personaliza el tipo de información que quieres obtener de tu cliente.': 'Specify desired customer information.',
  'Registro de cliente': 'Client registration',
  'El cliente envía toda su información.': 'Customers provide their information.',
  'Analiza la información': 'Information Analysis',
  'La información pasa por nuestros proveedores para analizar el historial crediticio.': 'Providers analyze credit history data',
  'Resultados': 'Results',
  'Determina si tu cliente es apto para obtener un producto financiero.': 'Determine customer eligibility for financial products.',
  'Facilidad para consulta de historial': 'Effortless history consultation.',
  'Obtén score crediticio en segundos': 'Instant credit scorng.',
  'Cumple normativas de PLD': 'Retrieval adherence to AML regulations.',
  'Integración mediante APIS': 'Integration through API.',
  'Generación de reportes automatizados': 'Automated report generation.',
  'Los mejores proveedores de análisis crediticios te ayudarán a determinar si tu cliente cumple con tus requerimientos.': 'The best credit analysis providers to help you evaluate your customers suitability for your services.',
  'Dispersión': 'Disbursement',
  'Realiza dispersiones instantáneas y seguras': 'Performs instant and safe dispersions',
  'Olvídate de la conciliación manual y ten el control de todas tus dispersiones, procesos de pagos y transferencias desde un solo lugar.': 'Forget about manual reconciliation and control all your disbursements, payment processes and transfers from a single platform.',
  'Al realizar tus dispersiones a través de SPEI agilizas todas tus operaciones bajo rigurosas medidas de seguridad de manera precisa.': 'By conducting your disbursements through SPEI, you expedite all your operations under stringent security measures with precision.',
  'Centraliza operaciones bancarias.': 'Centralize banking operations.',
  'Seguridad en todas tus transacciones con STP': 'Security in all transactions with STP.',
  'Programa dispersiones constantes.': 'Schedule consistent disbursements.',
  'Envía y recibe transferencias de pago.': 'Send and receive bank transfers.',
  'Genera dispersiones a clientes, empleados y proveedores.': 'Generate disbursements to clients, employees, and suppliers.',
  'Dispersiones más ágiles y seguras con nuestros proveedores': 'Swift and secure disbursements with our partners.',
  'Cobranza': 'Payments',
  'Los mejores medios de pago para tus clientes': 'The best payment methods for your customers',
  'Extendemos medios de cobranza con referencia única para pagar en tiendas de conveniencia, abarrotes y bancos con confirmación en tiempo real.': 'Expand payment collection options with unique references for payments at convenience stores, supermarkets, and banks. Real-time confirmation included.',
  'Tus clientes eligen el medio de pago que más se les facilite y se genera un número de referencia para el pago de tu producto financiero.': 'Collect all necessary customer information. Generate a unique reference for customers to make payments conveniently.',
  'Notificación': 'Notification',
  'El cliente recibe una notificación o recordatorio de pago.': 'Customers receive payment notifications or reminders.',
  'Medio de pago ': 'Payment method',
  'El cliente entra a la plataforma y selecciona el medio de pago.': 'Customers access the platform and select payment methods.',
  'Referencia ': 'Reference code ',
  'Se genera una referencia única para que el cliente acuda a su sucursal más cercana y realize el pago.': 'A unique reference code is generated for customers to make payments.',
  'Pago exitoso ': 'Successful payment',
  'Recibe la notificación de que tu cliente realizó el pago con éxito.': 'Payment Notification of successful payment received.',
  'Agiliza la recepción de pagos.': 'Streamlined payment receipt.',
  'Tus clientes tienen diferentes opciones de pago.': 'Diverse payment options for customers.',
  'Confirmación en tiempo real.': 'Real-Time confirmation.',
  'Llega a distintos puntos de la república con nuestros proveedores': 'Reaches everywhere with our suppliers.',
  'Score Alternativo': 'Alternative Score',
  'Llega a personas sin historial de crédito con metadatos': 'Reach individuals with no credit history through metadata',
  'Obtén la puntuación de crediticia de tus clientes por medio de su comportamiento digital.': 'Obtain credit scores of your customers based on their digital behavior.',
  'Analiza millones de metadatos y estadísticas mediante el smartphone de tus clientes, generando patrones predictivos los cuales te ayudarán a definir su score.': 'Analyze millions of metadata and statistics through your customers smartphones, generating predictive patterns that will help you define their score.',
  'Confiabilidad y transparencia ': 'Reliability and transparency.',
  'Adquisición de clientela digital': 'Digital customer acquisition.',
  'Implementación por medio de API': 'Implementation through APIs.',
  'Cumplimiento regulatorio': 'Regulatory compliance.',
  'Reportes automatizados': 'Automated reports.',
  'Amplia tu mercado con ayuda de nuestros proveedores de Score Alternativo.': 'Expand your market with the assistance of our Alternative Score providers.',
  'Prevención de Lavado de Dinero (PLD)': 'Anti-Money Laundering (AML)',
  'Cumple con normativas y prevén riesgo de fraude.': 'Complies with regulations and anticipates risk of fraud.',
  'Conoce el perfil de tus clientes en las principales listas financieras a nivel mundial y cumple con todas las normativas con nuestro módulo de Prevención de Lavado de Dinero (PLD).': 'Familiarize yourself with your customers profiles on the major global financial lists and adhere to all regulations using our Anti-Money Laundering (AML) module.',
  'Identifica y evalúa a tus clientes revisando las principales listas negras, PEPs nacionales e internacionales (Persona políticamente expuesta) y genera alertas para prevenir riesgo de fraude.': 'Identify and assess your customers by cross-referencing major blacklists, national and international PEPs (Politically Exposed Persons), and generate alerts to prevent fraud risk.',
  'Validación de identidad.': 'Identity validation.',
  'Búsqueda en listas negras OFAC, FBI, SAT, PEPs nacionales e internacionales.': 'Search in blacklists like OFAC, FBI and DEA.',
  'Recibe alertas de fraude.': 'Receive fraud alerts.',
  'Revisión de transacciones inusuales, relevantes o preocupantes.': 'Review unusual, significant, or concerning transactions.',
  'Reportes automatizados.': 'Automated reports.',
  'Emisión de Tarjetas': 'Card Issuance',
  'Emite tarjetas para tu empresa': ' Issue cards for your company',
  'Genera cuentas y tarjetas VISA o MasterCard como medio de dispersión de crédito, nóminas y pagos a proveedores.': 'Generate accounts and VISA or MasterCard cards as a means of credit disbursement, payroll, and supplier payments.',
  'Centraliza y administra todas tus operaciones en una sola plataforma y enlaza tarjetas para tus clientes y empleados.': 'Centralize and manage all your operations in only one platform, connecting cards for your clients and employees.',
  'Emisión de tarjetas para clientes y empleados': 'Card issuance for clients and employees.',
  'Tarjetas Visa y MasterdCard aceptadas en todos los comercios.': 'Visa and MasterCard cards accepted at all stores, business and online payments..',
  'Dispersión de créditos, nóminas y pagos a proveedores. ': 'Credit disbursement, payroll, and supplier payments.',
  'Genera tarjetas bajo la licencia de nuestros proveedores': 'Generate cards under the license of our partners.',
  'Crea una estrategia de comunicación con tus clientes': 'Create a communication strategy with customers',
  'Facilitamos la herramienta que te ayudará a mejorar el acercamiento con tus clientes a través de medios de mensajería directa.': 'We provide the tool that will help you enhance your communication with customers through direct message channels.',
  'Al generar y configurar tu campaña de notificaciones te comunicas de manera directa con tus clientes estableciendo bajo que condiciones se realiza el envío de mensajes SMS, Email y WhatsApp.': 'By generating and configuring your notification campaign, you directly communicate with your clients, setting conditions for sending SMS, Email, and WhatsApp messages.',
  'Genera tu mensaje': 'Compose your message',
  'Crea una plantilla del mensaje que deas enviar.': 'Create a message template to send.',
  'Medio de pago': 'Configuration',
  'Determina el horario.': 'Determine the date and timing.',
  'Condiciones del mensaje': 'Message condition',
  'Selecciona bajo que condiciones y perfil de cliente deseas enviar el mensaje.': 'Select the conditions and customer profile to send the message.',
  'Envío exitoso': 'Successful delivery',
  'Realiza el envío y tus clientes recibiran el mensaje en su dispositivo.': 'Send the message and your customers will receive it on their devices.',
  'Envío de mensajes masivos en cualquier momento': 'Massive message sending anytime.',
  'Facilidad de configuración y envío': 'Easy setup and delivery.',
  'Contacto directo con tus clientes': 'Direct contact with your customers.',
  'Campaña de cobranza': 'Collection campaign.',
  'Notificaciones de pago automáticas': 'Automatic payment notifications.',
  'Utiliza distintos canales de comunicación con tus clientes.': 'Use different communication channels with your clients.',
  'Candado de Smartphone': 'Device Financing',
  'Asegura el pago de tus financiamientos o arrendamientos': 'Secures the payment of your financing or leases',
  'Nuestra herramienta Blockfone te ayudará a reducir tu cartera moratoria al realizar el bloqueo parcial o total de smartphones a distancia.': 'Our Lock Device tool will help you reduce your delinquent portfolio by partially or totally locking Android or IOS smartphones remotely.',
  'El Candado de smartphone permite el bloqueo de equipos a distancia dejando inhabilitado el dispositivo mismo que se reactiva al momento de presentar el pago, así mismo puedes envía mensajes automatizados de alertas y recordatorio de pago.': 'The Look Device tool allows remote locking of devices, rendering the device inactive until payment is made. Additionally, you can send automated alerts and payment reminder messages.',
  'Validación de Información': 'Information Validation',
  'Análisis Score Crediticio': 'Credit Score Analysis',
  'Consulta historial de crédito.': 'Check credit history.',
  'Fácil integración y activación del candado.': 'Easy integration and activation of the lock.',
  'Distintos canales de pago para tus clientes.': 'Several payment channels for your customers.',
  'Configuración y personalización de mensajes automatizados SMS, Email y WhatsApp.': 'Configuration and customization of automated messages via SMS, Email, and WhatsApp.',
  'Reducción de cartera moratoria.': 'Fraud detection and prevention ',
  'Bloqueo automático y manual.': 'Automatic and manual locking.',
  'Análisis Crediticio': 'Credit Analysis',
  'Score alternativo': 'Alternative Score',
  'Emisión de tarjetas': 'Cards Issuance',
  'Campaña de notificaciones': 'WokFlows',
  'Pago de servicios': 'Pay services',
  'Pago de Servicios': 'Pay services',
  'Ofrece a tus clientes más que productos financieros': 'Offer your customers more than just financial products',
  'Integra más de 200 productos y robustece tu plataforma o aplicación ofreciendo pago de servicios, recargas y pines electrónicos.':
  'Integrate more than 200 products and strengthen your platform or application by offering payment for services, recharges and electronic pins.',
  'Al conectarte con nuestra API puedes integrar diferentes funcionalidades y ofrecer a tus clientes la posibilidad de pagar todo tipo de servicios, mejorando la experiencia y usabilidad de tu plataforma o aplicación.':
  'By connecting with our API you can integrate different functionalities and offer your clients the possibility of paying for all types of services, improving the experience and usability of your platform or application.',
  'Mejor experiencia del cliente.' : 'Better customer experience.',
  'Más de 200 productos como servicios, recargas y pines electrónicos.' : 'More than 200 products such as services, recharges and electronic pins.',
  'Mayor captación de clientes para tu empresa.' : 'Greater customer acquisition for your company.',
  'Conecta tu plataforma con nuestros proveedores de servicios.' : 'Connect your platform with our service providers.',
  'Candado de Smartphones': 'Device Financing',
};