import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Button from '../Button';
import {
  List,
  ListItem,
} from '@mui/material';
import {
  BodyMenuMobile,
  ContBodyMenuMobile,
  ContFooterMenuMobile,
  ContItemsMenuMobile, ContItemsMenuMobileBtn,
  ContMenuMobile,
  ListaServiciosMobile,
  ImgMenu,
  ItemMenuMobile,
  ExpandIco,
  ContItemMenuMobileServices,
  ContItemMenuMobileLenguagess,
  ItemMenuMobileServices,
  ContGLobe,
} from './MenuMobile.styles';
import IcoExpand from '../../assets/images/ico-expand.svg';
import icoMenu from "../../assets/images/ico-hamburger.svg";
import icoClose from "../../assets/images/ico-close.svg";
import icoOnboarding from "../../assets/images/iconns-menu/ico-onboarding.svg";
import IcoAnalisis from '../../assets/images/Icons-datatoggle/icon-analisis.webp';
import IcoDispersion from '../../assets/images/Icons-datatoggle/icon-dispersion.webp';
import IcoMoney from '../../assets/images/Icons-datatoggle/ico-money.webp';
import IcoScoring from '../../assets/images/Icons-datatoggle/ico-scoring.webp';
import IcoSecurity from '../../assets/images/Icons-datatoggle/ico-security.webp';
import IcoCC from '../../assets/images/Icons-datatoggle/ico-creditcard.webp';
import IcoNoti from '../../assets/images/Icons-datatoggle/ico-noti.webp';
import IcoPayServces from '../../assets/images/Icons-datatoggle/ico-payservices.webp';
import IcoAutom from '../../assets/images/Icons-datatoggle/icon-autom.webp';
import GlobeIcon from '../../assets/images/globe-icon.png';

const MenuMobile = () => {
  const { i18n } = useTranslation();
  const [onShowMenu, setOnShowMenu] = useState(false);
  const [serviciosOpen, toggleServiciosOpen] = useState(false);
  const [lenguages, setLenguages] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [borderBottomPad, setBorderBottomPad] = useState(false);

  const onShowMenuMobile = () => {
    console.log("onShowMenuMobile =>", !onShowMenu);
    setOnShowMenu(!onShowMenu);
  };

  //const fnGoToElement = (el) => {
  //  setOnShowMenu(false);
//
  //  setTimeout(function () {
  //    const yOffset = -100;
  //    const titleElement = document.getElementById(el);
  //    // titleElement.scrollIntoView({ behavior: 'smooth' });
  //    const y =
  //      titleElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
  //    window.scrollTo({ top: y, behavior: "smooth" });
  //  }, 500);
  //};

  const toggleContent = () => {
    toggleServiciosOpen(!serviciosOpen)
    setIsContentVisible(!isContentVisible);
    setBorderBottomPad(!borderBottomPad);
  };

  const handleChangeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    setOnShowMenu(false);
  };

  return (
    <ContMenuMobile id={'ContMenuMobile'}>
      <ImgMenu
        src={onShowMenu ? icoClose : icoMenu}
        alt="img-menu"
        onClick={onShowMenuMobile}
      />
      {onShowMenu ? (
        <ContBodyMenuMobile id={'ContBodyMenuMobile'}>
          <BodyMenuMobile>
            <ContItemsMenuMobile>
              <Link to="/" style={{ textDecoration: "none" }}>
                <ItemMenuMobile>Home</ItemMenuMobile>
              </Link>
              <Link to="/nosotros" style={{ textDecoration: "none" }}>
                <ItemMenuMobile>Nosotros</ItemMenuMobile>
              </Link>
              <Link to="/beneficios" style={{ textDecoration: "none" }}>
                <ItemMenuMobile>Beneficios</ItemMenuMobile>
              </Link>
              <ItemMenuMobile onClick={() => toggleContent()} borderBottom={borderBottomPad}>
                Servicios 
                <ExpandIco
                  src={IcoExpand}
                  alt={isContentVisible ? 'Expandir' : 'Contraer'}
                  isContentVisible={!isContentVisible}
                />
              </ItemMenuMobile>
              {serviciosOpen && (
                <ListaServiciosMobile>
                  <Link to="/onboarding" style={{ textDecoration: "none" }}>
                    <ContItemMenuMobileServices>
                      <ItemMenuMobileServices>
                        <img src={icoOnboarding} alt="Onboarding" /> Onboarding Digital
                      </ItemMenuMobileServices>
                    </ContItemMenuMobileServices>
                  </Link>
                  <Link to="/analisis-crediticio" style={{ textDecoration: "none" }}>
                    <ContItemMenuMobileServices>
                      <ItemMenuMobileServices>
                        <img src={IcoAnalisis} alt="Análisis" />Análisis Crediticio
                      </ItemMenuMobileServices>
                    </ContItemMenuMobileServices>
                  </Link>
                  <Link to="/dispersion-pagos" style={{ textDecoration: "none" }}>
                    <ContItemMenuMobileServices>
                      <ItemMenuMobileServices>
                        <img src={IcoDispersion} alt="Dispersión" />Dispersión
                      </ItemMenuMobileServices>
                    </ContItemMenuMobileServices>
                  </Link>
                  <Link to="/cobranza" style={{ textDecoration: "none" }}>
                    <ContItemMenuMobileServices>
                      <ItemMenuMobileServices>
                        <img src={IcoMoney} alt="Cobranza" />Cobranza
                      </ItemMenuMobileServices>
                    </ContItemMenuMobileServices>
                  </Link>
                  <Link to="/score-alternativo" style={{ textDecoration: "none" }}>
                    <ContItemMenuMobileServices>
                      <ItemMenuMobileServices>
                        <img src={IcoScoring} alt="Score alternativo" /> Score alternativo
                      </ItemMenuMobileServices>
                    </ContItemMenuMobileServices>
                  </Link>
                  <Link to="/pld" style={{ textDecoration: "none" }}>
                    <ContItemMenuMobileServices>
                      <ItemMenuMobileServices>
                        <img src={IcoSecurity} alt="PLD" /> PLD
                      </ItemMenuMobileServices>
                    </ContItemMenuMobileServices>
                  </Link>
                  <Link to="/emision-tarjetas" style={{ textDecoration: "none" }}>
                    <ContItemMenuMobileServices>
                      <ItemMenuMobileServices>
                        <img src={IcoCC} alt="Emisión" />Emisión de tarjetas
                      </ItemMenuMobileServices>
                    </ContItemMenuMobileServices>
                  </Link>
                  <Link to="/notificaciones" style={{ textDecoration: "none" }}>
                    <ContItemMenuMobileServices>
                      <ItemMenuMobileServices>
                        <img src={IcoNoti} alt="Campaña" />Campaña de notificaciones
                      </ItemMenuMobileServices>
                    </ContItemMenuMobileServices>
                  </Link>
                  <Link to="/pago-servicios" style={{ textDecoration: "none" }}>
                    <ContItemMenuMobileServices>
                      <ItemMenuMobileServices>
                        <img src={IcoPayServces} alt="Pago" />Pago de servicios
                      </ItemMenuMobileServices>
                    </ContItemMenuMobileServices>
                  </Link>
                  <Link to="/bloqueo-dispositivos" style={{ textDecoration: "none" }}>
                    <ContItemMenuMobileServices>
                      <ItemMenuMobileServices borderBottom={true}>
                        <img src={IcoAutom} alt="Candado" />Candado de Smartphones
                      </ItemMenuMobileServices>
                    </ContItemMenuMobileServices>
                  </Link>
                </ListaServiciosMobile>
              )}

              <a href="https://blog.dynamicore.io/" target="_blank" style={{ textDecoration: "none" }}>
                <ItemMenuMobile>Blog</ItemMenuMobile>
              </a>

              <ItemMenuMobile onClick={() => setLenguages(!lenguages)} borderBottom={borderBottomPad}>
                <ContGLobe>
                  <img src={GlobeIcon} alt={'Globe icon'} width={60} />
                </ContGLobe>
                <ExpandIco
                  src={IcoExpand}
                  alt={lenguages ? 'Expandir' : 'Contraer'}
                  isContentVisible={!lenguages}
                />
              </ItemMenuMobile>
              {lenguages && (
                <ListaServiciosMobile>
                  <List disablePadding>
                  <ContItemMenuMobileLenguagess>
                    <ListItem onClick={() => handleChangeLanguage('en')} style={{cursor: 'pointer'}}>
                      <ItemMenuMobileServices>
                        English
                      </ItemMenuMobileServices>
                    </ListItem>

                    <ListItem onClick={() => handleChangeLanguage('es')} style={{cursor: 'pointer'}}>
                        <ItemMenuMobileServices>
                          Español
                        </ItemMenuMobileServices>
                    </ListItem>
                    </ContItemMenuMobileLenguagess>
                  </List>
                </ListaServiciosMobile>
              )}
              {/*<Link to="/marketplace" style={{ textDecoration: "none" }}>
                <ItemMenuMobile>Marketplace</ItemMenuMobile>
              </Link>*/}
              <ContItemsMenuMobileBtn>
                <Button
                  text={'Contáctanos'}
                  type={'/'}
                  linkTo={'/contacto'}
                  withIcon={true}
                  target={'_self'}
                  theme={'secondary'}
                  size={'b'}
                />
              </ContItemsMenuMobileBtn>
            </ContItemsMenuMobile>
            <ContFooterMenuMobile>
              <div>2024 Dynamicore©</div>
              <div>Todos los derechos reservados</div>
            </ContFooterMenuMobile>
          </BodyMenuMobile>
        </ContBodyMenuMobile>
      ) : null}
    </ContMenuMobile>
  );
};

export default MenuMobile;
