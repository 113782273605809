import styled, { createGlobalStyle, css } from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';
import Bullet from '../../assets/images/bullet.svg';

export const GlobalStylesTransform = createGlobalStyle`
  .transform-tabs {
    width: 100%;
    height: auto;
    background-color: transparent;
    
    @media(min-width: 300px) and (max-width: 767px){
      display: flex;
      flex-direction: column;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      display: flex;
      flex-direction: column;
    }
    @media(min-width: 1024px) and (max-width: 1079px){
      display: flex;
      flex-direction: row;
    }
    @media(min-width: 1080px) and (max-width: 1279px){
      display: flex;
      flex-direction: row;
    }
    @media(min-width: 1280px) and (max-width: 1407px){
      display: flex;
      flex-direction: row;
    }
    @media(min-width: 1408px) and (max-width: 1439px){
      display: flex;
      flex-direction: row;
    }
    @media(min-width: 1440px) and (max-width: 1919px){
      display: flex;
      flex-direction: row;
    }
    @media(min-width: 1920px){
      display: flex;
      flex-direction: row;
    }
  }
  
  #vertical-tabpanel-0,
  #vertical-tabpanel-1,
  #vertical-tabpanel-2,
  #vertical-tabpanel-3 {
    width: 100%;
    
    @media(min-width: 300px) and (max-width: 767px){
      position: relative;
      width: 100%;
      margin: 0 auto;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      position: relative;
      width: 80%;
      margin: 0 auto;
    }
  }

  #vertical-tabpanel-0 & .MuiBox-root,
  #vertical-tabpanel-1 & .MuiBox-root,
  #vertical-tabpanel-2 & .MuiBox-root,
  #vertical-tabpanel-3 & .MuiBox-root {    
    @media(min-width: 300px) and (max-width: 767px){
      padding: 0 !important;  
    }
    @media(min-width: 768px) and (max-width: 1023px){
      padding: 0 !important;
    }
    @media(min-width: 1024px) and (max-width: 1079px){
      padding-top: 0 !important;
    }
    @media(min-width: 1080px) and (max-width: 1279px){
      padding-top: 0 !important;
    }
    @media(min-width: 1280px) and (max-width: 1407px){
      padding-top: 0 !important;
    }
    @media(min-width: 1408px) and (max-width: 1439px){
      padding-top: 0 !important;
    }
    @media(min-width: 1440px) and (max-width: 1919px){
      padding-top: 0 !important;
    }
    @media(min-width: 1920px){
      padding-top: 0 !important;
    }
  }
  #vertical-tabpanel-0 & .MuiGrid-container,
  #vertical-tabpanel-1 & .MuiGrid-container,
  #vertical-tabpanel-2 & .MuiGrid-container,
  #vertical-tabpanel-3 & .MuiGrid-container {
    @media(min-width: 300px) and (max-width: 767px){
      width: 100%;
      margin-left: 0;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      width: 100%;
      margin-left: 0;
    }
    @media(min-width: 1024px) and (max-width: 1079px){
      width: 100%;
      margin-left: 0;
    }
    @media(min-width: 1080px) and (max-width: 1279px){
      width: 100%;
      margin-left: 0;
    }
    @media(min-width: 1280px) and (max-width: 1407px){
      width: 100%;
      margin-left: 0;
    }
    @media(min-width: 1408px) and (max-width: 1439px){
      width: 100%;
      margin-left: 0;
    }
    @media(min-width: 1440px) and (max-width: 1919px){
      width: 100%;
      margin-left: 0;
    }
    @media(min-width: 1920px){
      width: 100%;
      margin-left: 0;
    }
  }
  
  #vertical-tabpanel-0 & .MuiGrid-item,
  #vertical-tabpanel-1 & .MuiGrid-item,
  #vertical-tabpanel-2 & .MuiGrid-item,
  #vertical-tabpanel-3 & .MuiGrid-item {
    @media(min-width: 300px) and (max-width: 767px){
      padding: 0 !important;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      padding: 0 !important;
    }
  }
  
  /*.MuiTab-textColorPrimary .Mui-selected {
    //color: red;
  }*/
  
  .MuiTabs-indicator{
    background-color: ${GlobalColors.colorGrayLight} !important;
    right: 78px !important;
  }
  
  .MuiTabs-flexContainer {
    width: 100%;
    
    @media(min-width: 300px) and (max-width: 767px){
      align-items: center;
      justify-content: center;
      
      & > button {
        width: 20%;
      }
    }
    @media(min-width: 768px) and (max-width: 1023px){
      align-items: center;
      justify-content: center;

      & > button {
        width: 20%;
      }
    }
    @media(min-width: 1024px) and (max-width: 1079px){
      align-items: center;
      justify-content: center;
    }
    @media(min-width: 1080px) and (max-width: 1279px){
      flex-direction: column !important;
    }
    @media(min-width: 1280px) and (max-width: 1407px){
      flex-direction: column !important;
    }
    @media(min-width: 1408px) and (max-width: 1439px){
      flex-direction: column !important;
    }
    @media(min-width: 1440px) and (max-width: 1919px){
      flex-direction: column !important;
    }
    @media(min-width: 1920px){
      flex-direction: column !important;
    }

    & > button.Mui-selected > span {
      color: ${GlobalColors.colorPrimary} !important;
    }
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    .vertical-tabpanel {
      padding-top: 20px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .vertical-tabpanel {
      padding-top: 20px;
    }
  }
`

export const ContTabsTransformationStartsNow = styled.div`
  position: relative;
`
export const ContPhone = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    & > img.ImgTabPanel1,
    & > img.ImgTabPanel2,
    & > img.ImgTabPanel3,
    & > img.ImgTabPanel4 {
      width: 100%;
    } 
  }
  @media(min-width: 768px) and (max-width: 1023px){
    & > img.ImgTabPanel1,
    & > img.ImgTabPanel2,
    & > img.ImgTabPanel3,
    & > img.ImgTabPanel4 {
      width: 70%;
    }
    padding-top: 20px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    & > img.ImgTabPanel1,
    & > img.ImgTabPanel2,
    & > img.ImgTabPanel3,
    & > img.ImgTabPanel4 {
      width: 100%;
    }
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    & > img.ImgTabPanel1,
    & > img.ImgTabPanel2,
    & > img.ImgTabPanel3,
    & > img.ImgTabPanel4 {
      width: 100%;
    }
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    & > img.ImgTabPanel1,
    & > img.ImgTabPanel2,
    & > img.ImgTabPanel3,
    & > img.ImgTabPanel4 {
      width: 100%;
    }
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    & > img.ImgTabPanel1,
    & > img.ImgTabPanel2,
    & > img.ImgTabPanel3,
    & > img.ImgTabPanel4 {
      width: 100%;
    }
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    & > img.ImgTabPanel1,
    & > img.ImgTabPanel2,
    & > img.ImgTabPanel3,
    & > img.ImgTabPanel4 {
      width: 100%;
    }
  }
  @media(min-width: 1920px){
    & > img.ImgTabPanel1,
    & > img.ImgTabPanel2,
    & > img.ImgTabPanel3,
    & > img.ImgTabPanel4 {
      width: 100%;
    }
  }
`
export const LabelTab = styled.span`
  color: ${GlobalColors.colorGraySuperLight};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 18px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 20px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 14px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 14px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 16px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 18px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 20px;
  }
  @media(min-width: 1920px){
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 20px;
  }
`
export const ContInfoTransform = styled.div`
  width: 80%;
  height: auto;
  padding-left: 25px;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
    padding: 35px 0px !important;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 98%;
    padding: 35px 0px !important;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 100%;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 100%; 
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 90%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const TitleTransform = styled.div`
  margin-bottom: 20px;
  font-family: ${GlobalFonts.fontBold};
  text-align: left;
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 42px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 42px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin-bottom: 20px;
    font-size: 48px;
    line-height: 56px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin-bottom: 20px;
    font-size: 48px;
    line-height: 56px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 56px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-bottom: 20px;
    font-size: 44px;
    line-height: 56px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin-bottom: 20px;
    font-size: 46px;
    line-height: 56px;
  }
  @media(min-width: 1920px){
    margin-bottom: 20px;
    font-size: 48px;
    line-height: 56px;
  }
`
export const CssTextTransform = css`
  font-family: ${GlobalFonts.fontRegular};
  text-align: left;
  color: ${GlobalColors.colorText};

  @media(min-width: 300px) and (max-width: 767px){
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1920px){
    font-size: 20px;
    line-height: 28px;
  }
`
export const TextTransform = styled.div`
  margin-bottom: 40px;
  ${CssTextTransform}
`
export const UlTextTransform = styled.div`
  list-style-image: url(${Bullet});
`
export const LiTextTransform = styled.li`
  margin-bottom: 10px;
  ${CssTextTransform}
`
export const ContButtonTransform = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    justify-content: left;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    justify-content: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    justify-content: flex-start;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    justify-content: flex-start;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    justify-content: flex-start;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    justify-content: flex-start;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    justify-content: flex-start;
  }
  @media(min-width: 1920px){
    justify-content: flex-start;
  }
`
