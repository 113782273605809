export default {
  'Beneficios de nuestra plataforma': 'Benefits of our platform',
  'Gracias a que somos un equipo multidisciplinario y con gran experiencia en soluciones tecnológicas financieras, creamos una propuesta de valor para todas las empresas que buscan transformar la forma de hacer negocios y crear nuevos productos y servicios para poder competir con los líderes del mercado sin riesgos de descapitalización.':
  'As a multidisciplinary team with extensive experience in the financial sector, we created a value proposition for all companies who are looking to transform the way they do business and create new products and services to compete against market leaders.',
  'Reduce costos de operación': 'Reduce operating costs',
  'Con nuestra plataforma no necesitas contratar a terceros, encontrarás a los mejores proveedores para hacer más sencillos y rápidos tus procesos.': 'Our platform avoids the need to outsource, aligning you with key suppliers to streamline and simplify processes.',
  'Tus procesos seguros y confiables': 'Your processes secure and reliable',
  'Nos comprometemos a proporcionar una plataforma que opera bajo todas las normas de seguridad y requerimientos solicitados ': 'We are committed to offer a platform that operates under all the requested security standards and requirements ',
  'por la Comisión Nacional Bancaria y de Valores (CNBV).': 'by the Comisión Nacional Bancaria y de Valores (CNBV).',
  'Automatización de operaciones': 'Operations automation',
  'Haz tus procesos más rápidos y facilita tu operación en nuestra plataforma, configurando tu flujo de crédito y generando reportes automatizados llevarás tus productos financieros a otro nivel.': 'Accelerate processes and streamline operations within our platform. Configure workflows and automate reporting to improve your financial products.',
  'Facilitamos y agilizamos tus procesos': 'We facilitate and streamline your processes',
  'Creamos una plataforma 100% intuitiva donde tú eliges a los proveedores que mejor se adapten a tu estrategia, y te ayuden a agilizar operaciones internas y externas.': 'We have created a 100% intuitive platform where you select the best suppliers to align your efforts with strategy and help you streamline internal and external operations.',
};
