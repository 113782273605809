import { useState } from 'react';

export function useHover() {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleUnhover = () => {
    setIsHovered(false);
  };

  return { isHovered, handleHover, handleUnhover };
}