import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material';
import '../../globals/services-styles.css';
import { ContPayServicesPage, BtnOnbard, ContButton, IndentityPhoneImg } from './PayServicesPage.styles';
import { IdentityCont, ContTextIdentity, TextIdentitySummary, ContCardIdentity, ContOnb, ContTextDescHowWork, ContHowWork, TitleHowWork, TextDescHowWork } from '../globalpages.styles';
import ButtonSpecial from '../../components/ButtonSpecial';
import ActionDemo from '../../components/ActionDemo/index';
import payIdentity from '../../assets/images/Servicios/img-payservices.webp';
import ServicesIntegrations from '../../components/ServicesIntegrations/index';
import ServicesBeneficios from '../../components/ServicesBeneficios/index';
import STP from '../../assets/images/Servicios/stp-services.webp';
import Pagaqui from '../../assets/images/Servicios/pagaqui-servcices.webp';
import Spei from '../../assets/images/Servicios/spei-services.webp';
import PayServicesView from '../../assets/images/Servicios/img-payservices-beneficios.webp';
import Oxxo from '../../assets/images/Servicios/oxxopay-services.webp';
const PayServicesItems = [
  {
    title: 'Mejor experiencia del cliente.',
  },
  {
    title: 'Más de 200 productos como servicios, recargas y pines electrónicos.',
  },
  {
    title: 'Mayor captación de clientes para tu empresa.',
  }
];
//const stepsPayServices = [
//  { number: 1, title: 'Registro del cliente', subtitle: 'El cliente crea una cuenta' },
//  { number: 2, title: 'Validación de Información', subtitle: 'Llena el formulario con su información personal.' },
//  { number: 3, title: 'Verificación de datos', subtitle: 'Escaneo de Identificación y rostro.'},
//  { number: 4, title: 'Análisis Score Crediticio', subtitle: 'Consulta historial de crédito.' },
//  { number: 5, title: 'Registro exitoso', subtitle: 'Proceso completado' },
//];

export const PayServicesPage = () => {
  const { t } = useTranslation();
  const imagesToShow = [
    { src: Pagaqui, visible: true, alt: 'Logo Pagaqui', },
    { src: Spei, visible: true, alt: 'Logo Spei', },
    { src: STP, visible: true, alt: 'Logo STP', },
    { src: Oxxo, visible: true, alt: 'Logo Oxxo pay', },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ContPayServicesPage id={'ContPayServicesPage'}>

      <ContCardIdentity id={'ContCardIdentity'}>
        <IdentityCont>
          <Grid container spacing={2} className={'ContCardIdentity GridBlueCard'}>
            <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 1 }} style={{paddingLeft: '5%'}}>
              <ContOnb>
                <BtnOnbard>
                  {t('Pago de Servicios')}
                </BtnOnbard>
              </ContOnb>
              <ContTextIdentity>
                {t('Ofrece a tus clientes más que productos financieros')}
              </ContTextIdentity>
              <TextIdentitySummary>
               {t('Integra más de 200 productos y robustece tu plataforma o aplicación ofreciendo pago de servicios, recargas y pines electrónicos.')}
              </TextIdentitySummary>
              <ContButton>
                <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'secondary'}/>
              </ContButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} 
              order={{ xs: 2, sm: 2, md: 2 }}
              className={'GridStylesCardImage'}
            >
              <IndentityPhoneImg src={payIdentity} alt={'Integracion de pago de servicios'} visible={true} width={450}/>
            </Grid>
          </Grid>
        </IdentityCont>
      </ContCardIdentity>

      <ContHowWork id={'ContHowWork'}>
        <TitleHowWork>
          {t('¿Cómo funciona?')}
        </TitleHowWork>
        <ContTextDescHowWork>
          <TextDescHowWork>
            {t('Al conectarte con nuestra API puedes integrar diferentes funcionalidades y ofrecer a tus clientes la posibilidad de pagar todo tipo de servicios, mejorando la experiencia y usabilidad de tu plataforma o aplicación.')}
          </TextDescHowWork>
        </ContTextDescHowWork>

        {/*<StepsServices steps={stepsPayServices} version={2} />*/}

        <ContButton>
          <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'primary'}/>
        </ContButton>
      
      </ContHowWork>

      <ServicesBeneficios items={PayServicesItems} image={PayServicesView} alt={'Pago de servicios en plataforma'} />

      <ServicesIntegrations imagesToShow={imagesToShow} noSpace={true} text={'Conecta tu plataforma con nuestros proveedores de servicios.'}/>

      <ActionDemo />
    </ContPayServicesPage>
  )
}
