import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContFacilitarAgilizarProcesos = styled.div`
  @media(min-width: 300px) and (max-width: 399px){
    margin-top: 70px;
    padding: 50px 10px 0 10px;
  }
  @media(min-width: 400px) and (max-width: 500px){
    margin-top: 100px;
    padding: 50px 10px 0 10px;
  }
  @media(min-width: 501px) and (max-width: 549px){
    margin-top: 100px;
    padding: 50px 10px 0 10px;
  }
  @media(min-width: 550px) and (max-width: 599px){
    margin-top: 100px;
    padding: 100px 10px 0 10px;
  }
  @media(min-width: 600px) and (max-width: 767px){
    padding: 100px 10px 0 10px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding: 0 10px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding: 0 70px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    padding: 0 50px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding: 0 10px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding: 0 10px;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    padding: 0 10px;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    padding: 0 10px;
  }
  @media(min-width: 1920px){
    padding: 0 10px;
  }
`
export const ContInfoFacilitarAgilizarProcesos = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
    text-align: left;
    align-items: start;
    justify-content: start;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 80%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 60%;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 60%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 55%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 55%;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    width: 55%;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    width: 55%;
  }
  @media(min-width: 1920px){
    width: 55%;
  }
`
export const TitleFacilitarAgilizarProcesos = styled.h5`
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: ${GlobalFonts.fontBold};
  font-size: 64px;
  line-height: 74px;
  color: ${GlobalColors.colorPrimaryDark};

  @media(min-width: 300px) and (max-width: 399px){
    margin-top: 90px;
  }
  @media(min-width: 400px) and (max-width: 497px){
    margin-top: 50px;
  }
  @media(min-width: 498px) and (max-width: 500px){
    margin-top: 20px;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
    line-height: 38px;
    text-align: left;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 40px;
    line-height: 50px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 36px;
    line-height: 36px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 34px;
    line-height: 46px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 46px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 46px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 46px;
  }
  @media(min-width: 1920px){
    font-size: 46px;
  }
`
export const TextFacilitarAgilizarProcesos = styled.div`
  margin-bottom: 40px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 20px;
  line-height: 28px;
  color: ${GlobalColors.colorText};
`
export const ContBtn = styled.div`
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media(min-width: 200px) and (max-width: 450px){
    margin-left: 0%;
  }
  @media(min-width: 451px) and (max-width: 480px){
    margin-left: 0%;
  }
  @media(min-width: 481px) and (max-width: 540px){
    margin-left: 0%;
  }
  @media(min-width: 541px) and (max-width: 599px){
    margin-left: 0%;
  }
  @media(min-width: 600px) and (max-width: 699px){
    margin-left: 0%;
  }
  @media(min-width: 700px) and (max-width: 767px){
    margin-left: 0%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    justify-content: center;  
  }
`
export const ContVideo = styled.div`
  width: 100%;
  height: auto;
  
  & > video {
    width: 100%;
    border-radius: 20px;
  }
  & > video::-webkit-media-controls {
    display: none;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 50px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 80%;
    margin: 0 auto 50px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin-bottom: 50px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin-bottom: 80px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-bottom: 100px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-bottom: 100px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin-bottom: 100px;
  }
  @media(min-width: 1920px){
    margin-bottom: 150px;
  }
`
export const ContVideoMobile = styled.div`
  text-align: center;
  width: 100%;
  height: auto;

  & > div {
    width: 100%!important;
    height: auto!important;
  }
  
  & > video {
    width: 100%;
    border-radius: 20px;
  }
  & > video::-webkit-media-controls {
    display: none;
  }
  
  @media(min-width: 300px) and (max-width: 600px){
    margin-bottom: 50px;
  }
  
`
