import styled from 'styled-components';

export const ContBtnLanguage = styled.div`
  margin-left: 10px;
  width: auto;
  display: flex !important;
  align-items: center !important;
  flex-direction: row !important;
`
export const ContLanguage = styled.div`
  display: flex !important;
  justify-content: center;
  align-content: center;
  align-items: center;
`
export const ContGLobe = styled.div`
  margin-right: 1px
`
export const ContArrowLenguage = styled.div`

`

export const ContPopover = styled.div`
  width: 200px,
  margin-top: 20px;
`;