import React from 'react';
import Layout from '../components/Layout';
import Back1 from '../assets/images/back-home-1.webp';
import Thankspage from '../pages/ThanksPage';
import SEO from '../components/SEO';

export const ThanksView = () => {

  return (
    <Layout backgroundimage={Back1} actionDemo={false}>
      <SEO
        title="Gracias"
        description="Gracias por proporcionarnos sus datos"
        works='Core Financiero, Fintech, Onboardig digital, Aprobación de crédito, Gestión de cobranza, Análisis de crédito, Core Bancario, Automatización de procesos, Procesos digitales, Producto financiero, Servicios financieros, Digitalización de procesos, Software financiero, Dispersión, Reportes automatizados, Corebanking, Sector financiero, SOFIPO, SOFOM, CNBV, Condusef, Servicios financieros digitales, Plataforma financiera'
        bolImage={false}
        ogImage={`dynamicore-preview.png`}
        twitterImage={`dynamicore-preview.png`}
        canonicalUrl="https://dynamicore.io/gracias"
      />
      <Thankspage/>
    </Layout>
  );
};