import React from 'react';
import Slider from "react-slick";
import {ContSlider, ImgItemSlider, ImgItemSliderClients} from './SliderSlick.styles';
import "../../assets/css/slick.min.css";
import "../../assets/css/slick-theme.min.css";

export const SliderSlick = ({autoplay = true, rtl = false, dots = false, infinite = true, slidesToShow = 3, 
slidesToScroll = 1, speed = 2000, autoplaySpeed = 2000, arrows = false, data = [], clients = false}) => {
  const settings = {
    dots: dots,
    infinite: infinite,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    autoplay: autoplay,
    rtl: rtl,
    arrows: arrows,
    speed: speed,
    autoplaySpeed: autoplaySpeed,
    cssEase: "linear"
  };
  return (
    <ContSlider>
      <Slider {...settings}>
      {
        data.map((item, index) => {
          return (
            clients ? (
              <ImgItemSliderClients src={item.img} alt={item.name} key={index}/>
            ) : (
              <ImgItemSlider src={item.img} alt={item.name} key={index}/>
            )
          );
        })
      }
      </Slider>
    </ContSlider>
  )
}
