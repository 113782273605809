import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';
import ImageBack from '../../assets/images/bg-beneficios-complete-02.webp';

export const ContAutomatizacionOperaciones = styled.div`
  background-image: url(${ImageBack});
  background-repeat: no-repeat;
  background-size: cover;

  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 90px !important;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 100px !important;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin-bottom: 150px !important;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin-bottom: 150px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-bottom: 150px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-bottom: 150px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin-bottom: 200px !important;
  }
  @media(min-width: 1920px){
    margin-bottom: 250px !important;
  }
`
export const ContAutomatizacionOperacionesInner = styled.div`
  padding: 100px 0;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding: 50px 0;
    height: 450px;
  }
  @media(min-width: 768px) and (max-width: 899px){
    padding: 100px 0;
    height: 650px;
  }
  @media(min-width: 900px) and (max-width: 1023px){
    padding: 100px 0;
    height: 350px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    height: 330px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    height: 345px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    height: 360px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    height: 365px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    height: 380px;
  }
  @media(min-width: 1920px){
    height: 550px;
  }
`
export const ContInfoAutomatizacionOperaciones = styled.div`
  position: relative;
  height: 100%;
  
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
    text-align: left;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
    align-items: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 100%;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 95%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 95%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 93%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 95%;
  }
  @media(min-width: 1920px){
    width: 90%;
  }
`
export const TitleAutoProcesos = styled.h4`
  position: relative;
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: ${GlobalFonts.fontBold};
  text-align: left;
  color: ${GlobalColors.colorSecondary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
    line-height: 38px;
    text-align: left;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    position: relative;
    width: 70%;
    margin: 0 auto 35px;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 40px;
    line-height: 50px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 42px;
    line-height: 52px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 48px;
    line-height: 54px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 52px;
    line-height: 58px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 54px;
    line-height: 64px;
  }
  @media(min-width: 1920px){
    font-size: 64px;
    line-height: 74px;
  }
`
export const TextAutoProcesos = styled.div`
  position: relative;
  margin-bottom: 40px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 20px;
  line-height: 28px;
  color: ${GlobalColors.colorSecondary};
  
  & > span {
    font-family: ${GlobalFonts.fontBold};
  }

  @media(min-width: 300px) and (max-width: 767px){
    font-size: 20px;
    line-height: 28px;
    text-align: left;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    position: relative;
    width: 80%;
    margin: 0 auto 35px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 20px;
    line-height: 28px;
  }
`
export const ContButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 30px;
    justify-content: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 35px;
    justify-content: center;
  }
`
export const ContImgAutProc = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media(min-width: 300px) and (max-width: 399px){
    position: absolute;

    & > img {
      width: 100%;
      margin: 0 auto;
    }
  }

  @media(min-width: 400px) and (max-width: 500px){
    position: absolute;

    & > img {
      width: 95%;
      margin: 0 auto;
    }  
  }

  @media(min-width: 501px) and (max-width: 549px){
    position: absolute;
    & > img {
      width: 85%;
      margin: 0 auto;
    }  
  }
  
  @media(min-width: 550px) and (max-width: 767px){
    position: absolute;
    & > img {
      width: 80%;
      margin: 0 auto;
    }  
  }
  @media(min-width: 768px) and (max-width: 899px){
    position: relative;
    width: 58%;
    margin: 0 auto;
    
    & > img {
      width: 100%;
    }
  }
  @media(min-width: 900px) and (max-width: 1023px){
    position: relative;
    width: 80%;
    margin: 0 auto;
    
    & > img {
      width: 100%;
    }
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    position: relative;

    & > img {
      width: 100%;
    }
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    position: relative;

    & > img {
      width: 100%;
    }
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    position: relative;
    
    & > img {
      width: 100%;
    }
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    position: relative;
    
    & > img {
      width: 90%;
    }
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    position: relative;
    
    & > img {
      width: 90%;
    }
  }
  @media(min-width: 1920px){
    position: relative;
    
    & > img {
      width: 100%;
    }
  }
`
