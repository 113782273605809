import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';
import ImageBack from '../../assets/images/bg-beneficios-complete-01.svg';

export const ContReduceCostos = styled.div`
  width: 100%;
  background-image: url(${ImageBack});
  background-repeat: no-repeat;
  background-size: cover;
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 590px;
    margin-bottom: 50px;
  }
  @media(min-width: 768px) and (max-width: 899px){
    height: 880px;
    margin-bottom: 50px;
  }
  @media(min-width: 900px) and (max-width: 1023px){
    height: 600px;
    margin-bottom: 60px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    height: 600px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    height: 600px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    height: 600px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    height: 600px;
  }
  @media(min-width: 1920px){
    height: 680px;
  }
`
export const ContReduceCostosInner = styled.div`
  padding: 100px 50px;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding: 80px 0px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    padding: 50px 50px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const ContInfoReduceCostos = styled.div`
  position: relative;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  
  @media(min-width: 100px) and (max-width: 767px){
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const TitleReduceCostos = styled.h2`
  margin-top: 0px;
  position: relative;
  margin-bottom: 20px;
  font-family: ${GlobalFonts.fontBold};
  text-align: left;
  color: ${GlobalColors.colorSecondary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
    line-height: 38px;
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    position: relative;
    width: 70%;
    margin: 0 auto 35px;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 36px;
    line-height: 36px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 34px;
    line-height: 46px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 46px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 46px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 46px;
  }
  @media(min-width: 1920px){
    font-size: 46px;
  }
`
export const TextReduceCostos = styled.div`
  position: relative;
  margin-bottom: 40px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 20px;
  line-height: 28px;
  color: ${GlobalColors.colorSecondary};
  
  & > span {
    font-family: ${GlobalFonts.fontBold};
  }

  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    position: relative;
    width: 80%;
    margin: 0 auto 35px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 20px;
    line-height: 28px;
  }
`
export const ContButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  
  @media(min-width: 100px) and (max-width: 767px){
    margin-bottom: 30px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 35px;
    justify-content: center;
  }
`
export const ContImgReduceCostos = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 100%;
  }

  @media(min-width: 100px) and (max-width: 367px){
    position: relative;
    width: 100%;
    margin: 0 auto;
  }
  @media(min-width: 368px) and (max-width: 467px){
    position: relative;
    width: 95%;
    margin: 0 auto;
  }
  @media(min-width: 468px) and (max-width: 567px){
    position: relative;
    width: 90%;
    margin: 0 auto;
  }
  @media(min-width: 568px) and (max-width: 667px){
    position: relative;
    width: 80%;
    margin: 0 auto;
  }
  @media(min-width: 668px) and (max-width: 767px){
    position: relative;
    width: 80%;
    margin: 0 auto;
  }
  @media(min-width: 768px) and (max-width: 899px){
    position: relative;
    width: 80%;
    margin: 0 auto;
  }
  @media(min-width: 900px) and (max-width: 1023px){
    position: relative;
    width: 100%;
    margin: 0 auto;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    position: absolute;
    width: 45%;
    margin-top: 0px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    position: absolute;
    width: 49%;
    margin-top: -50px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    position: absolute;
    width: 49%;
    margin-top: -50px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    position: absolute;
    width: 49%;
    margin-top: -50px;
  }
  @media(min-width: 1920px){
    position: absolute;
    width: 49%;
    margin-top: -50px;
  }
`
export const ContImgProcesosSeguros = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    & > img {
      width: 100%;
    }  
  }
  @media(min-width: 768px) and (max-width: 1023px){
    & > img {
      width: 100%;
    }
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    & > img {
      width: 90%;
    }
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    & > img {
      width: 95%;
    }
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    & > img {
      width: 90%;
    }
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    & > img {
      width: 90%;
    }
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    & > img {
      width: 90%;
    }
  }
  @media(min-width: 1920px){
    & > img {
      width: 100%;
    }
  }
`
