import styled, {css} from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContButton = styled.div`
  position: relative;
  width: auto;
  //height: 60px;
  height: ${props => props.size === 'medium' ? '45px' : '70px'};
  /*background: linear-gradient(73.69deg, #5433FF -18.91%, #20BDFF 65.98%, #A5FECB 124.27%);*/
  background: transparent;
  border-radius: 10px;
  float: right;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  
  z-index: 1;
  
  &:hover{
    /*background: linear-gradient(298.69deg, #5433FF -18.91%, #20BDFF 65.98%, #A5FECB 124.27%);*/
  }

  &:active{
    background: ${GlobalColors.colorSecondary};
  }
`
export const CfgBtnLink = css`
  width: 160px;
  height: 45px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  //justify-content: space-evenly;
  justify-content: center;
  flex-direction: row;
  background-color: ${props => props.theme === 'primary' ? GlobalColors.colorPrimary : GlobalColors.colorSecondary};
  border: none;
  border-radius: 10px;
  font: 18px ${GlobalFonts.fontRegular};
  font-weight: 500;
  color: ${props => props.theme === 'primary' ? GlobalColors.colorBtnTextPrimaryHover : GlobalColors.colorMenuPrimary};
  text-decoration: none;
  transition: 0.3s ease all;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme === 'primary' ? GlobalColors.colorPrimaryDark : GlobalColors.colorMenuMobileSecondary};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    //width: 170px;
    width: ${props => props.size === 'medium' ? '170px' : '280px'};
    height: 100%;
    font-family: ${props => props.size === 'medium' ? GlobalFonts.fontRegular : GlobalFonts.fontBold};
    font-size: ${props => props.size === 'medium' ? '14px' : '18px'};
  }
  @media(min-width: 768px) and (max-width: 1023px){
    //width: 170px;
    width: ${props => props.size === 'medium' ? '170px' : '280px'};
    height: 100%;
    font-family: ${props => props.size === 'medium' ? GlobalFonts.fontRegular : GlobalFonts.fontBold};
    font-size: ${props => props.size === 'medium' ? '14px' : '18px'};
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 130px;
    height: 38px;
    font-size: 14px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 135px;
    height: 40px;
    font-size: 15px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 135px;
    height: 40px;
    font-size: 15px; 
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 135px;
    height: 40px;
    font-size: 15px;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    width: 135px;
    height: 40px;
    font-size: 15px;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    width: 135px;
    height: 42px;
    font-size: 15px;
  }
  @media(min-width: 1920px){
    height: 45px;
    font-size: 18px;
  }
`
export const Btn = styled.button`
  ${CfgBtnLink}
`
export const BtnAnchor = styled.a`
  //padding: 0 !important;
  ${CfgBtnLink}
`
export const BtnText = styled.div`
  margin-right: 10px;
  margin-left: 15px;
`
