import React from 'react';
import {
  ImagesContainer, ImageFull, Image1, Image2, Image3, Image4,
} from './UseCasesImagesContainer.styles';
import Image11 from '../../assets/images/UseCases/image-1.png';
import Image22 from '../../assets/images/UseCases/image-2.png';
import Image33 from '../../assets/images/UseCases/image-3.png';
import Image44 from '../../assets/images/UseCases/image-4.png';
import unitImage from '../../assets/images/UseCases/unitImages2.png';

export const UseCasesImagesContainer = ({useSeparates}) => {
  
  return (
    <>
    {useSeparates ? (
      <ImagesContainer>
        <Image1 src={Image11} alt="Registro de nuevos clientes con Onboarding digital" title='Registro de nuevos clientes con Onboarding digital'/>
        <Image2 src={Image22} alt="Escaneo facial para verificar la identidad de tus clientes" title='Verificación de identidad con escaneo facial'/>
        <Image3 src={Image33} alt="Aprobación de crédito para tus clientes" title='Preaprobación de crédito '/>
        <Image4 src={Image44} alt="Transacciones y pagos del cliente" title='Portal de clientes Dynamicore'/>
      </ImagesContainer>
    ) : (
      <ImagesContainer>
        <ImageFull src={unitImage} alt="Registro de nuevos clientes con Onboarding digital" />
      </ImagesContainer>
    )}
    </>
  );
};