import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContBeneficiosPage = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 70px !important;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 120px !important;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding-top: 27px !important;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    padding-top: 110px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding-top: 120px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding-top: 130px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding-top: 130px !important;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    padding-top: 130px !important;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    padding-top: 130px !important;
  }
  @media(min-width: 1920px){
    padding-top: 150px !important;
  }
`
export const ContInfoBeneficiosPage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 70px;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    align-items: center;
    justify-content: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const DescBeneficios = styled.div`
  width: 60%;
  margin-bottom: 40px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${GlobalColors.colorText};

  z-index: 1;
  
  & > span {
    font-family: ${GlobalFonts.fontBold};
  }

  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 80%;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 70%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 70%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 65%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 60%;
  }
  @media(min-width: 1920px){
    width: 50%;
  }
`

export const ContButton = styled.div`
@media(min-width: 300px) and (max-width: 320px){
  text-align: left !important;
  margin-left: -22%;
  display: none;
}
@media(min-width: 321px) and (max-width: 350px){
  margin-left: -25%;
  display: none;
}
@media(min-width: 351px) and (max-width: 370px){
  margin-left: -31%;
  display: none;
}
@media(min-width: 371px) and (max-width: 390px){
  margin-left: -33%;
  display: none;
}
@media(min-width: 391px) and (max-width: 410px){
  margin-left: -33%;
  display: none;
}
@media(min-width: 411px) and (max-width: 430px){
  margin-left: -36%;
  display: none;
}
@media(min-width: 431px) and (max-width: 450px){
  margin-left: -39%;
  display: none;
}
@media(min-width: 451px) and (max-width: 470px){
  margin-left: -41%;
  display: none;
}
@media(min-width: 471px) and (max-width: 490px){
  margin-left: -43%;
  display: none;
}
@media(min-width: 491px) and (max-width: 510px){
  margin-left: -45%;
  display: none;
}
@media(min-width: 511px) and (max-width: 530px){
  margin-left: -48%;
  display: none;
}
@media(min-width: 531px) and (max-width: 550px){
  margin-left: -50%;
  display: none;
}
@media(min-width: 551px) and (max-width: 570px){
  margin-left: -53%;
  display: none;
}
@media(min-width: 571px) and (max-width: 679px){
  margin-left: -55%;
  display: none;
}
@media(min-width: 580px) and (max-width: 600px){
  margin-left: -58%;
}
`
