import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContainerFit = styled.div`
  @media(min-width: 300px) and (max-width: 499px){
    margin: 0 30px !important;
  }
  @media(min-width: 500px) and (max-width: 599px){
    margin: 0 55px !important;
  }
  @media(min-width: 600px) and (max-width: 900px){
    margin: 0 70px !important;
  }
  @media(min-width: 901px) and (max-width: 1023px){
    margin: 0 45px !important;
  }
  @media(min-width: 1024px) and (max-width: 1279px){
    margin: 0 52px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin: 0 92px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin: 0 92px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin: 0 92px !important;
  }
  @media(min-width: 1920px) and (max-width: 3200px){
    margin: 0 192px !important;
  }
`;
export const ContFooter = styled.footer`
  position: relative;
  width: 100%;
  height: auto;
  background-color: #FBFFFF;
  z-index: 1;
`
export const ContFooterInner = styled.div`
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 90%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 70%;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 70%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 70%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 70%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 70%;
  }
  @media(min-width: 1920px){
    width: 70%;
  }
`
export const ContTextFooterLeft = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  align-items: center;
  justify-content: flex-start;
  padding-top 40px;
  
  @media(min-width: 300px) and (max-width: 1023px){
    justify-content: center;
  }
`
export const ContTextFooterCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-left: 5%;
  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 80px;
    padding-left: 15%;
  }
`
export const ContTextFooterRight = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-left: 5%;
`
export const TextFooterCont = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`
export const TextFooterIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`
export const TextFooter = styled.div`
  padding: 5px 0;
  font: 14px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #0C3A72;
  
  & > a {
    color: #0C3A72;
    text-decoration: none;
  }
  
  @media(min-width: 300px) and (max-width: 1023px){
    padding: 20px 0;
  }
`
export const IcoFooter = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  & > a {
    color: ${GlobalColors.colorMenuFooterPrimary};
  }

  @media(min-width: 300px) and (max-width: 1023px){
    padding: 20px 0;
  }
`
export const TextFooterLeft = styled.div`
  width: auto;
  height: auto;
  display: block;
`
export const TextFooterCenter = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  
  & > div {
    padding: 0 5px;
  }
`
export const TextFooterRight = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & > div {
    padding: 0 5px;
  }
`

export const TextCenterPages = styled.a`
  padding: 5px 0;
  font: 15px ${GlobalFonts.fontBold};
  font-style: bold;
  font-weight: 400;
  line-height: 18px;
  color: #0C3A72;
  text-decoration: none;
  curso: pointer; 
`


export const TextCenterPagesServices = styled.div`
  padding: 5px 0;
  font: 14px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #0C3A72;
  text-decoration: none;
  curso: pointer; 
  margin-bottom: 5px;
`;
export const ContRightService = styled.div`
  text-align: start;
  justify-content: center;
  padding-left: 10%;
  @media(min-width: 300px) and (max-width: 600px){
    padding-left: 20%;
  }
`;

export const NotitleContent = styled.div`
  alignContent: center;
  padding: 20px 0px 15px 0;
  @media(min-width: 300px) and (max-width: 900px){
    padding: 35px 0px 15px 0;
  }
`;

export const FintechLogo = styled.img`
  width: 60px;
  height: auto;
`;
