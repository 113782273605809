import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContSliderClients = styled.div`
  position: relative;

  & .slick-slide > div {
    margin: 0 35px;
    padding: 0 15px;
  }
  
  & .slick-dots {
    position: relative;
    margin-top: 10px;
  }
  & .slick-dots li button:before, .slick-next:before, .slick-prev:before {
    width: 10px;
    height: 10px;
    background-color: #FFFFFF;
    border-radius: 50%;
  }

  /*@media(min-width: 300px) and (max-width: 767px){
    & .slick-slide > div {
      padding: 0 15px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    & .slick-slide > div {
      padding: 0 20px;
    }
  }*/
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 470px;
    margin-top: 30px;

    & .slick-dots {
      bottom: 10px
    }
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 470px;
    margin-top: 30px;

    & .slick-dots {
      bottom: 10px
    }
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 520px;
    margin-top: 100px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 520px;
    margin-top: 100px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 520px;
    margin-top: 100px;
  }
  @media(min-width: 1920px){
    width: 520px;
    margin-top: 100px;
  }
`
export const ContItemSlider = styled.div`
  //width: 520px !important;
  height: auto;
  margin: 0 35px 0 -45px;
  padding: 25px;
  background: ${GlobalColors.colorPrimaryDark};
  border-radius: 20px;
`
export const DescItemSlider = styled.div`
  font-family: ${GlobalFonts.fontRegular};
  font-size: 14px;
  line-height: 16px;
  color: ${GlobalColors.colorSecondary};
  
  @media(min-width: 300px) and (max-width: 767px){
    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 12px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 12px;
  }
`
export const BrandItemSlider = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`
export const ContImgItemSlider = styled.div`
  width: 48px;
  height: 48px;
  margin: 0 20px 0 0;
`
export const ImgItemSlider = styled.img`
  width: 100%;
  height: 100%;
`
export const ContInfoItemSlider = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`
export const NameItemSlider = styled.div`
  margin-bottom: 8px;
  font-family: ${GlobalFonts.fontBold};
  font-size: 15px;
  line-height: 17px;
  color: ${GlobalColors.colorSecondary};

  & > a {
    font-family: ${GlobalFonts.fontBold};
    font-size: 14px;
    line-height: 16px;
    color: ${GlobalColors.colorSecondary};
    text-decoration: none;
  }
`
export const LinkItemSlider = styled.div`
  & > a {
    font-family: ${GlobalFonts.fontRegular};
    font-size: 14px;
    line-height: 16px;
    color: ${GlobalColors.colorSecondary};
    text-decoration: none;
  }
`
