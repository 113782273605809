import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContStatisticsBack = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 150px;
  z-index: -1;

  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 100px;
  }
`
export const ContStatistics = styled.div`
  position: relative;
  margin: 0 auto;

  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 50px;
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 50px;
    width: 100%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding-top: 80px;
    width: 100%;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    padding-top: 100px;
    width: 90%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding-top: 0px;
    width: 90%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding-top: 0px;
    width: 90%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding-top: 0px;
    width: 90%;
  }
  @media(min-width: 1920px){
    width: 90%;
    padding-top: 0px;
  }
`
export const TitleStatistics = styled.h4`
  margin-bottom: 20px;
  font-family: ${GlobalFonts.fontBold};
  font-size: 48px;
  line-height: 56px;
  color: ${GlobalColors.colorPrimaryDark};
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
    line-height: 38px;
    text-align: left;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 40px;
    line-height: 50px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 36px;
    line-height: 36px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 34px;
    line-height: 46px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 46px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 46px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 46px;
    line-height: 56px;
  }
  @media(min-width: 1920px){
    font-size: 46px;
    line-height: 56px;
  }
`
export const TextStatistics = styled.div`
  margin-bottom: 40px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 20px;
  line-height: 28px;
  color: ${GlobalColors.colorText};
  text-align: center;

  @media(min-width: 300px) and (max-width: 767px){
    padding: 0;
    text-align: left;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding: 0;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding: 0;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    padding: 0 20%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding: 0 20%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding: 0 20%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding: 0 20%;
  }
  @media(min-width: 1920px){
    padding: 0 20%;
  }
`
export const ContStatisticsCounters = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin: 35px 0;
    flex-wrap: wrap;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin: 35px 0;
    flex-wrap: wrap;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin: 35px 0;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin: 70px 0;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin: 70px 0;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin: 70px 0;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin: 70px 0;
  }
  @media(min-width: 1920px){
    margin: 70px 0;
  }
`
export const ContItem = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  
  @media(min-width: 300px) and (max-width: 1023px){
    flex-direction: column;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const ContVerticalLineV = styled.div`
  width: 1px;
  height: 100px;
  background-color: ${GlobalColors.colorGrayLight};
  
  @media(min-width: 300px) and (max-width: 1023px){
    display: none;
  }
  @media(min-width: 1024px) {
    display: block;
  }
`
export const ContVerticalLineH = styled.div`
  margin-top: 30px;
  width: 100px;
  height: 1px;
  background-color: ${GlobalColors.colorGrayLight};
  
  @media(min-width: 300px) and (max-width: 767px){
    display: block;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    display: none;
  }
  @media(min-width: 1024px) {
    display: none;
  }
`
