import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Tooltip,
} from '@mui/material';
//import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';

const CssTextField = styled(TextField)({
  '& .MuiFormControl-root': {
    marginTop: '15px',
    color: 'green',
  },
});

//const useStyles = makeStyles(() => ({
//  dynamicInput: {
//    margin: '25px 0',
//  },
//}));

function TextControl(props) {
  //const classes = useStyles();
  const [revealPassword, setRevealPassword] = useState(false);
  const {
    disabled,
    display,
    error,
    events,
    settings,
    size,
    onBlur,
    onChange,
  } = props;
  let { type } = props;

  if (type === 'password' && settings?.canReveal) {
    display.endIcon = revealPassword
      ? <VisibilityOffIcon />
      : <VisibilityIcon />;
    display.endIconAction = () => setRevealPassword(!revealPassword);
    display.endIconTitle = revealPassword
      ? 'Ocultar'
      : 'Visualizar';
    type = revealPassword ? 'text' : type;
  }

  const Title = display.endIconTitle ? Tooltip : Fragment;

  return (
    <CssTextField
      //className={classes.dynamicInput}
      //style={{margin: '25px 0'}}
      disabled={disabled}
      error={error}
      fullWidth={true}
      helperText={props.helperText}
      InputProps={{
        endAdornment: display.endIcon
          ? (<InputAdornment position="end">
            {display.endIconAction
              ? (<Title {...display.endIconTitle
                ? {
                  title: display.endIconTitle,
                }
                : {}}
              >
                <IconButton
                  children={display.endIcon}
                  onClick={() => display.endIconAction(props.value)}
                  size="small"
                />
              </Title>)
              : (<SvgIcon
                children={display.endIcon}
                color="action"
                fontSize="small"
              />)}
          </InputAdornment>)
          : null,
        readOnly: settings?.readOnly,
        startAdornment: display.startIcon
          ? (<InputAdornment position="start">
            <SvgIcon
              children={display.startIcon}
              color="action"
              fontSize="small"
            />
          </InputAdornment>)
          : null,
      }}
      label={props.label}
      multiline={settings?.multiline}
      name={props.name}
      placeholder={props.placeholder}
      rows={settings?.rows}
      rowsMax={settings?.rowsMax}
      size={size}
      type={type}
      variant="outlined"
      value={props.value}
      onBlur={onBlur}
      onChange={(e) => {
        onChange(e);

        if (events.onChange) {
          events.onChange(e?.target);
        }
      }}
    />
  );
}

TextControl.defaultProps = {
  disabled: false,
  display: {},
  error: false,
  events: {},
  onBlur: () => { },
  onChange: () => { },
  type: 'text',
};

TextControl.propTypes = {
  disabled: PropTypes.bool,
  display: PropTypes.shape({
    startIcon: PropTypes.node,
    endIcon: PropTypes.node,
    endIconAction: PropTypes.func,
    endIconTitle: PropTypes.string,
  }),
  error: PropTypes.bool,
  events: PropTypes.object,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf([
    'checkbox',
    'email',
    'hidden',
    'password',
    'radio',
    'text',
  ]),
  value: PropTypes.any,
  settings: {
    multiline: PropTypes.bool,
    readOnly: PropTypes.bool,
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
  },
  size: PropTypes.oneOf([
    'small',
    'medium',
  ]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default TextControl;
