import React from 'react';
import { useTranslation } from 'react-i18next';
import {ContActionDemo, ContBtnActionDemo, TextActionDemo, TitleActionDemo} from './ActionDemo.styles';
import ButtonSpecial from '../ButtonSpecial';

export const ActionDemo = () => {
  const { t } = useTranslation();
  return (
    <ContActionDemo>
      <div className={'container-fit'}>
        <TitleActionDemo>
        {t('¡Somos mejor que un core bancario!')}
        </TitleActionDemo>
        <TextActionDemo>
        {t('Te ayudamos a integrar procesos automatizados y productos financieros digitales.')}
        </TextActionDemo>
        <ContBtnActionDemo>
          <ButtonSpecial
            text={'Prueba la versión Demo'}
            type={'/'}
            linkTo={'/contacto'}
            location={'/demo'}
            theme={'tertiary'}
            target={'_self'}
          />
        </ContBtnActionDemo>
      </div>
    </ContActionDemo>
  )
}
