import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContStatisticsCounter = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const ContCounter = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`
export const Counter = styled.div`
  margin-right: 5px;
  font-family: ${GlobalFonts.fontBold};
  font-size: 57px;
  line-height: 65px;
  color: ${GlobalColors.colorText};
`
export const CounterSmall = styled.div`
  margin-right: 5px;
  font-family: ${GlobalFonts.fontBold};
  font-size: 44px;
  line-height: 53px;
  color: #FFFFFF;
`
export const Subtitle = styled.div`
  margin-right: 5px;
  font-family: ${GlobalFonts.fontBold};
  font-size: 57px;
  line-height: 65px;
  color: ${GlobalColors.colorText};
`
export const SubtitleSmall = styled.div`
  margin-right: 5px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 44px;
  line-height: 53px;
  color: #FFFFFF;
`

export const Plus = styled.div`
  font-family: ${GlobalFonts.fontRegular};
  font-size: 30px;
  line-height: 65px;
  color: ${GlobalColors.colorPrimaryLight};
`
export const Label = styled.div`
  font-family: ${GlobalFonts.fontRegular};
  font-size: 18px;
  line-height: 21px;
  color: ${GlobalColors.colorText};
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 1023px){
    font-size: 14px;
    line-height: 16px;
  }
`

export const LabelSmall = styled.div`
  font-family: ${GlobalFonts.fontRegular};
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 1023px){
    font-size: 14px;
    line-height: 16px;
  }
`
