import React, {useEffect} from 'react';
import {Grid} from '@mui/material';
import {ContUseCasesPage, ContInfoUseCasesPage, ContSecondaryBtnTitle, ContBtnTitle, ContBtnTitle2, BtnTitle, DescUseCases, ContButton, ContainerResolve,
  ContainerResolveTitle, ContainerCarrousel, ContInfoModulesPage, ListItem, ContModulesTabs,
  TiltleModules2, TiltleModules3, TiltleModules4, TiltleModules5, TiltleModules6,
  SubTiltleModules, ContIntegrations, ContItemsClients, ContCreditPersonal, ContainerColumnsCredit, Column,
  ContTexts, ContTextsSection, ContHyperTitle, HyperImage,LinkTextCredit, DescriptionCredit, ContImgCredit, ImageCredit, ContButtonsCompanies, ButtonCompanie, Icon,
  ContInfoContactPage, ContTitleContact, TitleContact, SubtitleContact, AdvisorContact,
} from './UseCasesPage.styles';
import UseCasesImagesContainer from '../../components/useCasesImagesContainer';
import ModulesTabs from '../../components/ModulesTabs';
import TitleSpecial from '../../components/TitleSpecial';
import ButtonSpecial from '../../components/ButtonSpecial';
import ClientCarousel from '../../components/ClientsCarousel';
import CardSlider from '../../components/CardSlider';
import Image1 from '../../assets/images/UseCases/personal-credit.png';
import Clock from '../../assets/images/UseCases/clock-icon.png';
import Client from '../../assets/images/UseCases/client-icon.png';
import Normative from '../../assets/images/UseCases/normative-icon.png';
import Card from '../../assets/images/UseCases/card-icon.png';
import Money from '../../assets/images/UseCases/money-icon.png';
import Wallet from '../../assets/images/UseCases/wallet-icon.png';
import Finance from '../../assets/images/UseCases/finance-icon.png';
import FormContact from '../../components/FormContact';
import HyperLink from '../../assets/images/hyperlink-icon.png';
import backImage from '../../assets/images/use-caseform-back.png';

export const UseCasesPage = () => {

  const cards = [
    { 
      title: 'Mala experiencia del cliente',
      description: 'La lentitud de los procesos y la complejidad en la solicitud de documentación lleva al cliente a perder interés en completar la solicitud de servicio.', 
      icon: Clock,
    },
    { 
      title: 'Clientes morosos',
      description: 'La ausencia de una evaluación de riesgos adecuada y de un seguimientoeficiente puede resultar en préstamos incobrables o en la conclución de personas no bancarizados.', 
      icon: Client,
    },
    { 
      title: 'Cumplimiento normativo',
      description: 'Falta de métodos para verificación de identidad del solicitante y la prevención del lavado de dinero.', 
      icon: Normative,
     },
  ];

  const cardsTexts = [
    { 
      title: 'Configurable y escalable',
      description: 'Diseñamos una plataforma que se adapta 100% a tu estrategia.',
    },
    { 
      title: 'Experiencia en el sector',
      description: 'Llevamos más de tres años especializándonos en la automotivación y digitalización de financieras del país',
    },
    { 
      title: 'Seguridad y cumplimiento',
      description: 'Cumplimos con los más altos estándares de seguridad, así como la normativa requerida por las instituciones financieras y fiscales.',
     },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={'container-fit'} id={'container-fit'}>
    <ContUseCasesPage id={'ContUseCasesgPage'}>

      <ContInfoUseCasesPage>
        <ContBtnTitle>
          <BtnTitle>Caso de Uso: Crédito</BtnTitle>
        </ContBtnTitle>
        <TitleSpecial useCasesView={true}>Revoluciona tu flujo de  aprobación de créditos</TitleSpecial>
        <DescUseCases>
          Fortalece el proceso de aprobación de crédito ayudando a tomar decisiones de manera ágil y efectiva, permitiendo registrar a un mayor número de clientes y una gestión de riesgos más eficaz.
        </DescUseCases>
        <ContButton id={'ContButton'}>
          <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'primary'}/>
        </ContButton>

        <UseCasesImagesContainer useSeparates={false}/>

      </ContInfoUseCasesPage>

      <ContainerResolve id={'ContainerResolve'}>
        <ContSecondaryBtnTitle>
          <ContBtnTitle2>
            <BtnTitle secondary={true}>Problemática</BtnTitle>
          </ContBtnTitle2>
        </ContSecondaryBtnTitle>
        <ContainerResolveTitle>
          <TiltleModules2 white={true}>Resuelve los desafíos del sector</TiltleModules2>
          <SubTiltleModules white={true}>
            Nuestra plataforma ofrece soluciones efectivas a los siguientes problemas:
          </SubTiltleModules>
        </ContainerResolveTitle>
        <ContainerCarrousel>
          <CardSlider cards={cards} />
        </ContainerCarrousel>
      </ContainerResolve>

      <ContInfoModulesPage>
        <ContBtnTitle2>
          <BtnTitle secondary={true}>Nuestra solución</BtnTitle>
        </ContBtnTitle2>
        <TiltleModules3>
          Eficienta procesos con los mejores módulos
        </TiltleModules3>
        <SubTiltleModules>
          Serán tus mejores aliados para la captación de clientes, aprobación y gestión de crédito
        </SubTiltleModules>
        <ContModulesTabs>
          <ModulesTabs />
        </ContModulesTabs>
      </ContInfoModulesPage>

      <ContIntegrations>
        <TiltleModules4>
          Nuestras integraciones
        </TiltleModules4>
        
        <ContItemsClients>
          <ClientCarousel />
        </ContItemsClients>
      </ContIntegrations>

      {/* <ContCreditPersonal>
        <ContBtnTitle2>
          <BtnTitle secondary={true}>Caso de éxito</BtnTitle>
        </ContBtnTitle2>
        <TiltleModules5 width={90}>
          Otorgamiento de créditos personales de la empresa Findere 
        </TiltleModules5>
        <SubTiltleModules width={90}>
          Mediante diversos procesos de investigación descubrimos los problemas que enfrentan los usuarios con algunos productos tecnológicos actualmente.
        </SubTiltleModules>
        <ContainerColumnsCredit>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 1 }}>
              <Column>
                <ContTexts>
                  <ContTextsSection>
                    <ContHyperTitle>
                      <HyperImage src={HyperLink} alt={'hyper-link'} />
                      <LinkTextCredit>El reto</LinkTextCredit>
                    </ContHyperTitle>
                    <DescriptionCredit>
                      Kapital Flex enfrentaba dificultades para lanzar nuevos productos al mercado debido 
                      a la poca flexibilidad y configurabilidad de su Core central, el cual le proporcionaba 
                      plazos muy largos para el lanzamiento del producto, impidiendo el crecimiento deseado.
                    </DescriptionCredit>
                  </ContTextsSection>
                  <ContTextsSection>
                    <ContHyperTitle>
                      <HyperImage src={HyperLink} alt={'hyper-link-solution'} />
                      <LinkTextCredit>La solución</LinkTextCredit>
                    </ContHyperTitle>
                    <DescriptionCredit>
                      Implementamos un workflow personalizado en nuestro Core Financiero, integrando los módulos 
                      necesarios para agilizar el proceso, resultando el lanzamiento del producto financiero de 
                      Crédito Pyme en un time-to-market de tan solo 3 semanas.
                    </DescriptionCredit>
                  </ContTextsSection>
                  <ContTextsSection>
                    <ContHyperTitle>
                      <HyperImage src={HyperLink} alt={'hyper-link-services'} />
                      <LinkTextCredit>Servicios</LinkTextCredit>
                    </ContHyperTitle>
                    <DescriptionCredit>
                      <ul>
                        <ListItem>Onboarding</ListItem>
                        <ListItem>Análisis de crédito</ListItem>
                        <ListItem>Administración de cartera</ListItem>
                        <ListItem>Cobranza</ListItem>
                        <ListItem>Campaña de notificaciones</ListItem>
                      </ul>
                    </DescriptionCredit>
                  </ContTextsSection>
                </ContTexts>
              </Column>
            </Grid>
            <Grid item xs={12} sm={12} md={6} order={{ xs: 2, sm: 2, md: 2 }}>
              <Column>
                <ContImgCredit>
                  <ImageCredit src={Image1} alt={'Preaprobación de crédito '} title='Caso de éxito de aprobación de crédito'  />
                </ContImgCredit>
              </Column>
            </Grid>
          </Grid>
        </ContainerColumnsCredit>
      </ContCreditPersonal> */}

      <ContainerResolve>
        <ContSecondaryBtnTitle>
          <ContBtnTitle>
            <BtnTitle secondary={true}>Valor Agregado</BtnTitle>
          </ContBtnTitle>
        </ContSecondaryBtnTitle>
        <ContainerResolveTitle>
          <TiltleModules6 white={true}>Somos el socio ideal para tu entidad financiera</TiltleModules6>
          <SubTiltleModules white={true}>
            Nuestra propuesta se destaca por su eficiencia y reducción de riesgos. Ofrecemos una plataforma configurable que simplifica la creación de productos financieros y respaldamos a cada cliente con un soporte personalizado
          </SubTiltleModules>
        </ContainerResolveTitle>
        <ContainerCarrousel marginBottom={true}>
          <CardSlider cards={cardsTexts} isCenter={true} />
        </ContainerCarrousel>
      </ContainerResolve>

      <ContIntegrations>
        <ContBtnTitle>
          <BtnTitle secondary={true}>Modelos de negocio</BtnTitle>
        </ContBtnTitle>
        <TiltleModules6>
          ¿A qué empresas ayudamos?
        </TiltleModules6>
        <SubTiltleModules width={70}>
          Nuestra plataforma ayuda a financieras tradicionales o en crecimiento que desean automatizar su 
          operación y crear productos financieros digitales.
        </SubTiltleModules>
        <ContButtonsCompanies>
          <ButtonCompanie>
            SOFOMES<Icon><img src={Card} alt={'sofomes imagen'}/></Icon>
          </ButtonCompanie>
          <ButtonCompanie>
            SOFIPOS<Icon><img src={Money} alt={'Sofipos imagen'}/></Icon>
          </ButtonCompanie>
          <ButtonCompanie>
            FINTECHS<Icon><img src={Wallet} alt={'FINTECH imagen'}/></Icon>
          </ButtonCompanie>
          <ButtonCompanie>
            Financieras<Icon><img src={Finance} alt={'Financiersas imagen'}/></Icon>
          </ButtonCompanie>
        </ContButtonsCompanies>
      </ContIntegrations>

      <ContInfoContactPage>
        <ContainerColumnsCredit>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 1 }}
              sx={{
                backgroundImage: `url(${backImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <ContTitleContact>
                <TitleContact>
                  Queremos ayudarte a despegar tu proyecto 🚀
                </TitleContact>
                <SubtitleContact>
                  ¡Compártenos detalles y comencemos a trabajar juntos! 
                </SubtitleContact>
                {/*<AdvisorContact>
                  *DynamiCore no ofrece créditos de ningún tipo.
                </AdvisorContact>*/}
              </ContTitleContact>
            </Grid>
            <Grid item xs={12} sm={12} md={6} order={{ xs: 2, sm: 2, md: 2 }}>
              <FormContact/>
            </Grid>
          </Grid>
        </ContainerColumnsCredit>
      </ContInfoContactPage>      
    </ContUseCasesPage>
    </div>
  );
};