import styled, { createGlobalStyle } from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const GlobalStylesTransformStartNow = createGlobalStyle`
  .contZIndex{
    z-index: 2;
  }
`

export const ContTransformationStartsNowBack = styled.div`
  position: absolute;
  width: 100%;
  height: 1000px;
  background-color: #FFFFFF;
  //clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 1100px;
    margin-top: -180px
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 800px;
    margin-top: -230px
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    height: 900px;
    margin-top: -230px
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    height: 1000px;
    margin-top: -230px
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    height: 880px;
    margin-top: -230px
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    height: 900px;
    margin-top: -230px
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    height: 900px;
    margin-top: -230px
  }
  @media(min-width: 1920px){
    height: 1000px;
    margin-top: -230px
  }
`
export const ContTransformationStartsNow = styled.div`
  z-index: 1;

  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 50px !important;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 200px !important;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin-top: -40px;
    padding-top: 200px !important;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin-top: -40px;
    padding-top: 250px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-top: -50px;
    padding-top: 250px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-top: -50px;
    padding-top: 250px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin-top: -50px;
    padding-top: 300px !important;
  }
  @media(min-width: 1920px){
    margin-top: -50px;
    padding-top: 300px !important;
  }
`
export const TitleTransform = styled.h3`
  width: 100%;
  margin-bottom: 35px;
  font-family: ${GlobalFonts.fontBold};
  text-align: center;
  color: ${GlobalColors.colorPrimaryDark};
  z-index: 1;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 35px;
    font-size: 30px;
    line-height: 38px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 35px;
    font-size: 40px;
    line-height: 50px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 36px;
    line-height: 36px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 34px;
    line-height: 46px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 46px;
    line-height: 56px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-bottom: 70px;
    font-size: 46px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin-bottom: 70px;
    font-size: 46px;
  }
  @media(min-width: 1920px){
    margin-bottom: 70px;
    font-size: 46px;
  }
`
export const ContPhone = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    & > img {
      width: 100%;
    } 
  }
  @media(min-width: 768px) and (max-width: 1023px){
    & > img {
      width: 100%;
    }
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    & > img {
      width: 100%;
    }
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    & > img {
      width: 100%;
    }
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    & > img {
      width: 70%;
    }
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    & > img {
      width: 70%;
    }
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    & > img {
      width: 70%;
    }
  }
  @media(min-width: 1920px){
    & > img {
      width: 70%;
    }
  }
`
