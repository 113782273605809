export default {
  'Transformamos tu financiera':  'Transforming your finance',
  'desde el core': 'company from the Core',
  'Transformamos tu financiera desde el core': 'Transforming your finance company from the Core',
  'El primer Core Financiero que te permite': 'The first financial core that enables you',
  'configurar productos financieros, administrar transacciones y simplificar operaciones' : 'to create financial products, manage transactions and streamline',
  'de principio a fin.' : 'end-to-end operations.',
  'Prueba la versión Demo': 'Request a demo',
  'Todo en una plataforma': 'All in one platform.',
  'Creamos un marketplace de proveedores especializados en la creación de productos financieros, recepción de solicitudes, aprobación de créditos y administración de cartera.': 'We have a Marketplace of suppliers specialized in the creation of financial products, reception of applications, credit approval and portfolio management.',
  'Ir al marketplace': 'Go to marketplace',
  'Transformación financiera': 'Financial transformation',
  'Onboarding Digital': 'Digital onboarding',
  'Permite la verificación de identidad digital de tus clientes mediante biométricos, OCR, voz, en instituciones gubernamentales y las principales listas negras como SAT, OFAC, DEA, etc.': 'Allows digital identity verification of your customers through OCR procesess, in governmental institutions, and the main blacklists such as DEA and FBI.',
  'Plataforma centralizada': 'Centralized platform',
  'Ver más': 'See more',
  'Administración de clientes': 'Customer management',
  'Administra y genera perfiles de tus clientes en un solo lugar con nuestros módulos de administración de clientes.': 'Manage and generate customer profiles in one place with our customer management modules.',
  'Automatización de procesos': 'Work Flows',
  'Deja de realizar largos procesos, hazlo todo de manera digital en cuestión de minutos.': 'Stop time-consuming processes, make them automated in minutes by creating your work flows.',
  'Cobranza y dispersión': 'Payments and Collections',
  'Facilitamos la gestión de cobranza y dispersión con procesos confiables y seguros.': 'We streamline of payments and disbursement management with reliable and secure processes',
  'Administra e integra productos financieros gestionando los módulos que mejor se adapten a las necesidades de tu empresa.': 'Incorporate financial products by customizing modules that align with your company´s requirements.',
  'Años en el mercado': 'Years in the industry',
  'Productos financieros': 'Financial Products',
  'Módulos': 'Modules',
  'Productos financieros lanzados': 'Financial products launched',
  'Integraciones': 'Interactons',
  'Conoce nuestros servicios': 'Our Services',
  'Conéctate con otras plataformas con nuestra API': 'Connect to other platforms with our API',
  'Extiende funcionalidades mediante integraciones con otras plataformas en el ecosistema tecnológico financiero con nuestra plataforma API.': 'Extends functionalities through integrations with other platforms of the financial technology ecosystem using our API.',
  'Clientes': 'Clients',
};