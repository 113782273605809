import React from 'react';
import Layout from '../components/Layout';
import Back1 from '../assets/images/back-home-1.webp';
import SEO from '../components/SEO';
import LockPhonePage from '../pages/LockPhonePage';

export const LockPhone = () => {

  return (
    <Layout backgroundimage={Back1} actionDemo={false}>
      <SEO
        title="Mejora tus Servicios de Arrendamiento con Candado para Smartphone"
        description="Protege tu inversión y los dispositivos móviles que ofreces a tus clientes con nuestro candado para smartphone. Bloquea dispositivos a distancia y reduce tu cartera moratoria."
        works='Core Financiero, Fintech, Onboardig digital, Aprobación de crédito, Gestión de cobranza, Análisis de crédito, Core Bancario, Automatización de procesos, Procesos digitales, Producto financiero, Servicios financieros, Digitalización de procesos, Software financiero, Dispersión, Reportes automatizados, Corebanking, Sector financiero, SOFIPO, SOFOM, CNBV, Condusef, Servicios financieros digitales, Plataforma financiera'
        bolImage={false}
        ogImage={`dynamicore-preview.png`}
        twitterImage={`dynamicore-preview.png`}
        canonicalUrl="https://dynamicore.io/bloqueo-dispositivos"
      />
      <LockPhonePage/>
    </Layout>
  )
}