import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContProcesosSeguros = styled.div`
  @media(min-width: 300px) and (max-width: 767px){
    padding: 50px 0;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding: 50px 0;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding: 50px 50px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    padding: 60px 50px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding: 70px 0;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding: 80px 0;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    padding: 100px 0;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    margin-top: 50px !important;
    padding: 100px 0;
  }
  @media(min-width: 1920px){
    padding: 100px 0;
  }
`
export const ContInfoProcesosSeguros = styled.div`
  position: relative;
  height: 100%;
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
    margin-top: 0px;
    flex-direction: column;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
    margin-top: 0px;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 100%;
    margin-top: 30px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 95%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 95%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 93%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 95%;
  }
  @media(min-width: 1920px){
    width: 90%;
  }
`
export const TitleProcesosSeguros = styled.h3`
  margin-top: 0px;
  position: relative;
  margin-bottom: 20px;
  font-family: ${GlobalFonts.fontBold};
  text-align: left;
  color: ${GlobalColors.colorPrimaryDark};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
    line-height: 38px;
    text-align: left;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    position: relative;
    width: 70%;
    margin: 0 auto 35px;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 36px;
    line-height: 36px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 34px;
    line-height: 46px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 48px;
    line-height: 54px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 46px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 46px;
  }
  @media(min-width: 1920px){
    font-size: 46px;
  }
`
export const TextProcesosSeguros = styled.div`
  position: relative;
  margin-bottom: 40px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 20px;
  line-height: 28px;
  color: ${GlobalColors.colorText};
  
  & > span {
    font-family: ${GlobalFonts.fontBold};
  }

  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    position: relative;
    width: 80%;
    margin: 0 auto 35px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 20px;
    line-height: 28px;
  }
`
export const ContButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 30px;
    justify-content: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 35px;
    justify-content: center;
  }
`
