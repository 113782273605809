import styled from 'styled-components';

export const ContLogo = styled.div`
  width: 159px;
  height: 82px;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 180px;
    height: auto;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 220px;
    height: auto;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 180px;
    height: auto;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 180px;
    height: auto;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 200px;
    height: auto;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 240px;
    height: auto;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 285px;
    height: auto;
  }
  @media(min-width: 1920px){
    width: 285px;
    height: auto;
  }
`
export const ImgLogo = styled.img`
  width: 100%;
  height: 100%;
`
