import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContError404 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const TextError404 = styled.h1`
  font-family: ${GlobalFonts.fontBold};
  font-size: 24px;
  line-height: 32px;
  color: ${GlobalColors.colorPrimaryLight};
`

export const TextPageNotFound = styled.div`
  margin-bottom: 20px;
  font-family: ${GlobalFonts.fontBold};
  font-size: 64px;
  line-height: 70px;
  color: ${GlobalColors.colorPrimaryDark};
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 44px;
    line-height: 50px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 54px;
    line-height: 60px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 54px;
    line-height: 60px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 54px;
    line-height: 60px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 64px;
    line-height: 70px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 64px;
    line-height: 70px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 64px;
    line-height: 70px;
  }
  @media(min-width: 1920px){
    font-size: 64px;
    line-height: 70px;
  }
`
export const TextInfoNotFound = styled.div`
  margin-bottom: 30px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 20px;
  line-height: 30px;
  color: ${GlobalColors.colorText};
  text-align: center;
`
export const ContImgError404 = styled.div`
  width: 100%;
  
  & > img {
    width: 100%;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 80%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 50%;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 50%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 50%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 50%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 50%;
  }
  @media(min-width: 1920px){
    width: 50%;
  }
`
export const ContBtnActionHome = styled.div`
  padding-bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
      
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding: 50px;
  }
`
