import styled, { createGlobalStyle } from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const GlobalStylesLegals = createGlobalStyle`
  .col {
    width: 100%;
  }
  .row {
    width: 100%;
  }
  .mt-4 {
    margin-top: 1.5rem!important;
  }
  .mt-3 {
    margin-top: 1rem!important;
  }
  .mt-2 {
    margin-top: 0.5rem!important;
  }
  .text-center {
    text-align: center;
    font-family: ${GlobalFonts.fontBold};
    font-size: 16px;
    line-height: 16px;
    color: ${GlobalColors.colorText};
  }
  .text-left {
    text-align: left;
    font-family: ${GlobalFonts.fontBold};
    font-size: 16px;
    line-height: 16px;
    color: ${GlobalColors.colorText};
  }
  .text-right {
    text-align: right;
    font-family: ${GlobalFonts.fontRegular};
    font-size: 14px;
    line-height: 16px;
    color: ${GlobalColors.colorText};
  }
  .text-justify {
    text-align: justify;
    font-family: ${GlobalFonts.fontRegular};
    font-size: 14px;
    line-height: 16px;
    color: ${GlobalColors.colorText};
  }
  .custom-hr {
    width: 100%;
    height: 3px;
    margin-top: 15px;
    background-color: #69B5FF;
  }
`
export const ContTitleLegals = styled.h1`
  width: 100%;
  height: 180px;
  background-color: ${GlobalColors.colorPrimary};
  font-family: ${GlobalFonts.fontBold};
  font-size: 48px;
  line-height: 56px;
  color: ${GlobalColors.colorSecondaryLight};
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 120px;
    margin: -6px 0 40px;
    font-size: 32px;
    line-height: 56px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 150px;
    margin: -35px 0 40px;
    font-size: 44px;
    line-height: 56px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    height: 150px;
    margin: -55px 0 40px;
    font-size: 44px;
    line-height: 56px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    height: 180px;
    margin: -38px 0 40px;
    font-size: 48px;
    line-height: 56px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    height: 180px;
    margin: -38px 0 40px;
    font-size: 48px;
    line-height: 56px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    height: 180px;
    margin: -38px 0 40px;
    font-size: 48px;
    line-height: 56px;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    height: 180px;
    margin: -38px 0 40px;
    font-size: 48px;
    line-height: 56px;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    height: 180px;
    margin: -38px 0 40px;
    font-size: 48px;
    line-height: 56px;
  }
  @media(min-width: 1920px){
    height: 180px;
    margin: -38px 0 40px;
    font-size: 48px;
    line-height: 56px;
  }
`
export const ContLegals = styled.div`
  margin: 0 100px 50px;
  background-color: ${GlobalColors.colorSecondaryLight};
  border-radius: 20px;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin: 0 0 50px;
    padding: 15px;
    border-radius: 14px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin: 0 40px 50px;
    padding: 25px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin: 0 100px 50px;
    padding: 25px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin: 0 100px 50px;
    padding: 25px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin: 0 100px 50px;
    padding: 25px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin: 0 100px 50px;
    padding: 25px;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    margin: 0 100px 50px;
    padding: 25px;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    margin: 0 100px 50px;
    padding: 25px;
  }
  @media(min-width: 1920px){
    margin: 0 100px 50px;
    padding: 25px;
  }
`

export const TitleBTag = styled.div`
  font-family: ${GlobalFonts.fontBold};
  font-size: 18px;
  line-height: 18px;
  color: ${GlobalColors.colorText};
`;
