import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContServicesIntegrations,
  ContTextIntegration,
  TextIntegrationSummary,
  BoldIntegrationText,
  ContImagesIntegrations,
  //ImagesIntegrationContainer,
  ImagesIntegration,
  ContButton,
} from './ServicesIntegrations.styles';
import ButtonSpecial from '../ButtonSpecial';
//import CCIntegration from '../../assets/images/Servicios/circulo-hover.png';
//import Weetrust from '../../assets/images/Servicios/weetrust-hover.png';
//import NaaT from '../../assets/images/Servicios/Naat-white.png';
//import Sat from '../../assets/images/Servicios/sat-services.png';
//import STP from '../../assets/images/Servicios/stp-services.webp';
//import Mastercard from '../../assets/images/Servicios/mastercard-services.png';
//import Pagaqui from '../../assets/images/Servicios/pagaqui-servcices.png';
//import Visa from '../../assets/images/Servicios/visa-services.webp';
//import Tiaxca from  '../../assets/images/Servicios/tiaxca-services.png';
//import BuroCredito from  '../../assets/images/Servicios/buro-services.png';

export const ServicesIntegrations = ({ imagesToShow, text, noSpace = false }) => {
  const { t } = useTranslation();
  return (
    <ContServicesIntegrations id={'ContServicesIntegrations'}>
      <ContTextIntegration>
        {t('Integraciones')}
      </ContTextIntegration>
      {text ? (
        <TextIntegrationSummary>
          {t(text)}
        </TextIntegrationSummary>
      ) : (
        <TextIntegrationSummary>
          {t('Agilizamos y potencializamos el proceso del')} {' '}
          <BoldIntegrationText>
          {t('Onboarding Digital ')}
          </BoldIntegrationText>
          {' '}
          {t('con estas integraciones.')} 
        </TextIntegrationSummary>
      )}
      

      <ContImagesIntegrations noSpace={noSpace}>
          {imagesToShow.map((image, index) => (
            <ImagesIntegration
              key={index}
              src={image.src}
              alt={image.alt}
              visible={image.visible}
            />
          ))}
      </ContImagesIntegrations>

      <ContButton>
        <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'secondary'}/>
      </ContButton>
    </ContServicesIntegrations>
  )
}
