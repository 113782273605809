import React from 'react';
import Layout from '../components/Layout';
import Back1 from '../assets/images/back-home-1.webp';
import ContactPage from '../pages/ContactPage';
import SEO from '../components/SEO';
//import logoImage from '../assets/images/stp.svg';

export const Contact = () => {
  //const absoluteImageUrl = require.resolve(logoImage);
  return (
    <Layout backgroundimage={Back1} actionDemo={false}>
      <SEO
        title="Contacto"
        description="Escríbenos y solicita tu demo"
        works='Core Financiero, Fintech, Onboardig digital, Aprobación de crédito, Gestión de cobranza, Análisis de crédito, Core Bancario, Automatización de procesos, Procesos digitales, Producto financiero, Servicios financieros, Digitalización de procesos, Software financiero, Dispersión, Reportes automatizados, Corebanking, Sector financiero, SOFIPO, SOFOM, CNBV, Condusef, Servicios financieros digitales, Plataforma financiera'
        bolImage={false}
        ogImage={`dynamicore-preview.png`}
        twitterImage={`dynamicore-preview.png`}
        isContact={true}
        canonicalUrl="https://dynamicore.io/contacto"
      />
      <ContactPage/>
    </Layout>
  );
};
