import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {useWindowDimensions} from '../../utils';
import {Grid} from '@mui/material';
import {ContNosotrosPage, ContInfoNosotrosPage, ContImg,
  DescNosotros, ContBack2, Back2, ContCreatePlat, ContOurMision,
  ContTextCreate, TextCreateSummary,ContButton, CenteredContainer, PhoneNosotrosImage, ContAction} from './NosotrosPage.styles';
import TitleSpecial from '../../components/TitleSpecial';
import ButtonSpecial from '../../components/ButtonSpecial';
import NosotrosImagesContainer from '../../components/NosotrosImagesContainer';
import ActionDemo from '../../components/ActionDemo/index';
import ImageBack2 from '../../assets/images/NosotrosImages/bg-nosotros-new.webp';
import PhoneNosotosImage from '../../assets/images/NosotrosImages/phone-nosotros-2.webp';
import {MisionVision} from './MisionVision';

export const NosotrosPage = () => {
  const { t } = useTranslation();
  const {width} = useWindowDimensions();
  const [imgBack, setImgBack] = useState();

  useEffect(() => {
    // console.log('width =>', width);
    setImgBack(parseInt(width, 10) >= 1024 ? ImageBack2 : ImageBack2);
  }, [width]);

  useEffect(() => {
    // console.log('imgBack =>', imgBack);
  }, [imgBack]);

  return (
    <ContNosotrosPage id={'ContNosotrosPage'}>
      <ContInfoNosotrosPage>
        <TitleSpecial>
        {t('El primer Core financiero')}
        </TitleSpecial>
        <DescNosotros>
        {t('Dynamicore nace en enero del 2021 debido a la necesidad de brindar a las empresas no bancarias que ofrecen servicios financieros una forma rápida y sencilla de innovar sus productos y servicios financieros, mediante la digitalización de sus procesos y contar con infraestructura tecnológica sin grandes inversiones.')}
        </DescNosotros>
      </ContInfoNosotrosPage>

      {/* Aqui van las 4 imagenes */}
      <ContImg id={'ContImg'}>
        <NosotrosImagesContainer />
      </ContImg>

      <ContBack2 id={'ContBack2'}>
        <Back2 id={'imgBack2'} src={imgBack} alt={'background de Nosotros'}/>
      </ContBack2>

      <ContCreatePlat id={'ContCreatePlat'}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 1 }} style={{padding: '16px 50px'}}>
            <ContTextCreate>
            {t('Una plataforma que cambia el mundo')}
            </ContTextCreate>
            <ContButton>
              <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'secondary'}/>
            </ContButton>
          </Grid>
          <Grid item xs={12} sm={12} md={6} order={{ xs: 2, sm: 2, md: 2 }}>
            <TextCreateSummary>
            {t('Con base en nuestra experiencia en el sector financiero, identificamos que los procesos tradicionales administrativos y operativos dentro éste carece de una infraestructura tecnológica que facilite su desarrollo. De esta manera diseñamos una herramienta digital e innovadora que permitirá llevar estas tareas a un nivel superior.')}
              <br/>
              <br/>
            {t('Así es como a inicios del 2021 creamos DYNAMICORE, una plataforma escalable y segura que permite a nuestros clientes ofrecer productos en el mercado financiero con rapidez de acuerdo con su giro y sus necesidades específicas.')}
              <br/>
              <br/>
            {t('Es así como logramos describirnos y posicionarnos como el enlace que facilita las estrategias del mercado financiero hacia el cliente.')}
            </TextCreateSummary>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
            <CenteredContainer>
              <PhoneNosotrosImage id={'phone-image'} src={PhoneNosotosImage} alt={'Plataforma de pagos Dynamicore'} />
            </CenteredContainer>
          </Grid>
        </Grid>
      </ContCreatePlat>

      <ContOurMision>
        <MisionVision />
      </ContOurMision>
      
      <ContAction>
        <ActionDemo />
      </ContAction>
      

    </ContNosotrosPage>
  )
}
