import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import '../../globals/services-styles.css';
import {
  ContServicesBeneficios,
  ImageContainer,
  LayoutImageBeneficios,
  ContBeneficios, TitleBeneficios, ContListBeneficios, ListItemBeneficios, ImgListBeneficios, TitleListBeneficios,
} from './ServicesBeneficios.styles';
import Biometric from '../../assets/images/Servicios/layout-biometric.webp';
import ItemList from '../../assets/images/Servicios/item-list.webp';

export const ServicesBeneficios = ({items, image, alt}) => {
  const { t } = useTranslation();
  return(
    <ContServicesBeneficios>
      <Grid container spacing={2} className={'Grid-styles-ServicesBeneficios'}>
        <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 1 }}>
        <div style={{paddingRight: '30px'}}>
          <ImageContainer>
            <LayoutImageBeneficios src={image} alt={alt} />
          </ImageContainer>
        </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} 
          order={{ xs: 2, sm: 2, md: 2 }}
          className='Grid-2-styles-ServicesBeneficios'
        >
          <div style={{paddingLeft: '30px'}}>
          <ContBeneficios id={'ContBeneficios'}>
            <TitleBeneficios>
              {t('Beneficios')}
            </TitleBeneficios>
          </ContBeneficios>
          <ContListBeneficios id={'ContListBeneficios'}>
            {items.map((item, index) => (
              <ListItemBeneficios key={index}>
                <ImgListBeneficios src={ItemList} alt={`ico-${index}`} />
                <TitleListBeneficios>{t(item?.title)}</TitleListBeneficios>
              </ListItemBeneficios>
            ))}
          </ContListBeneficios>
          </div>
        </Grid>
      </Grid>
    </ContServicesBeneficios>
  )
}

ServicesBeneficios.defaultProps = {
  items: [],
  image: Biometric,
  alt: 'LayoutImageBeneficios',
};
ServicesBeneficios.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOf([
      'default',
    ]),
  })),
  image: PropTypes.string,
  alt: PropTypes.string,
};
