export default {
  'El primer Core financiero': 'The first financial core',
  'Dynamicore nace en enero del 2021 debido a la necesidad de brindar a las empresas no bancarias que ofrecen servicios financieros una forma rápida y sencilla de innovar sus productos y servicios financieros, mediante la digitalización de sus procesos y contar con infraestructura tecnológica sin grandes inversiones.': 
  'Dynamicore was founded in early 2021 due to the need to provide non-banking companies offering financial services with a quick and easy way to innovate their products and services by configuring workflows without large investments.',
  'Una plataforma que cambia el mundo': 'A platform that changes the world',
  'Con base en nuestra experiencia en el sector financiero, identificamos que los procesos tradicionales administrativos y operativos dentro éste carece de una infraestructura tecnológica que facilite su desarrollo. De esta manera diseñamos una herramienta digital e innovadora que permitirá llevar estas tareas a un nivel superior.': 'Based on our experience in the financial sector, we identified that the traditional administrative and operational processes within it lack a technological infrastructure that facilitates their development. Therefore, we designed a digital and innovative tool that allows us to take these tasks to a higher level.',
  'Así es como a inicios del 2021 creamos DYNAMICORE, una plataforma escalable y segura que permite a nuestros clientes ofrecer productos en el mercado financiero con rapidez de acuerdo con su giro y sus necesidades específicas.': 'In 2021 we created Dynamicore, a scalable and secure platform that allows our clients to quickly offer products in the financial market according to their specific needs.',
  'Es así como logramos describirnos y posicionarnos como el enlace que facilita las estrategias del mercado financiero hacia el cliente.': 'This is how we managed to represent and position ourselves as the link that streamlines the financial market strategies towards the client.',
  'Conoce nuestra misión y visión': 'Meet our mission and vision',
  'Nuestra Misión': 'Our Mission',
  'Nos comprometemos a proporcionar una plataforma que opera bajo todas las normas de seguridad y requerimientos solicitados por la Comisión Nacional Bancaria y de Valores (CNBV).': 'We are committed to providing a platform that operates under all the security standards and requirements requested by the National Banking and Securities Commission (CNBV).',
  'Impulsar la transformación financiera a través de tecnología adaptable, escalable y configurable.': 'Drive financial transformation through adaptable, scalable and configurable technology.',
  'Nuestra Visión': 'Our Vision',
  'Ser el aliado tecnológico que transforma los servicios financieros.': 'To be the technological ally that transforms financial services.',
  'Desde el principio, nuestro objetivo fue integrar las ': 'From the beginning, our goal was to integrate ',
  'mejores prácticas digitales ': 'digital best practices ',
  'donde fusionamos nuestros conocimientos en la implementación de big data con el procesamiento de información financiera, para otorgar a nuestros clientes  el respaldo tecnológico necesario para ': 'where we combine our expertise in big data implementation with financial information processing, in order to provide our clients with the technological support needed to ',
  'potencializar sus negocios y cumplir sus objetivos.': 'improve their business and achieve their goals.',
};