import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material';
import '../../globals/services-styles.css';
import {ContLockPhonePage, BtnOnbard, ContButton, IndentityPhoneImg } from './LockPhonePage.styles';
import { IdentityCont, ContTextIdentity, TextIdentitySummary, ContCardIdentity, ContOnb, ContHowWork, TitleHowWork, ContTextDescHowWork, TextDescHowWork } from '../globalpages.styles';
import ButtonSpecial from '../../components/ButtonSpecial';
import ActionDemo from '../../components/ActionDemo/index';
import phoneLock from '../../assets/images/Servicios/lock-exemple.webp';
import StepsServices from '../../components/StepsServices/index';
//import ServicesIntegrations from '../../components/ServicesIntegrations/index';
import ServicesBeneficios from '../../components/ServicesBeneficios/index';
import LockImage from '../../assets/images/Servicios/img-onboarding-lock.webp';

const LockPhoneItems = [
  {
    title: 'Fácil integración y activación del candado.',
  },
  {
    title: 'Distintos canales de pago para tus clientes.',
  },
  {
    title: 'Configuración y personalización de mensajes automatizados SMS, Email y WhatsApp.',
  },
  {
    title: 'Reducción de cartera moratoria.',
  },
  {
    title: 'Bloqueo automático y manual.',
  }
];
const stepsLockPhone = [
  { number: 1, title: 'Registro del cliente', subtitle: 'El cliente crea una cuenta' },
  { number: 2, title: 'Validación de Información', subtitle: 'Llena el formulario con su información personal.' },
  { number: 3, title: 'Verificación de datos', subtitle: 'Escaneo de Identificación y rostro.'},
  { number: 4, title: 'Análisis Score Crediticio', subtitle: 'Consulta historial de crédito.' },
  { number: 5, title: 'Registro exitoso', subtitle: 'Proceso completado' },
];

export const LockPhonePage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ContLockPhonePage id={'ContLockPhonePage'}>

      <ContCardIdentity id={'ContCardIdentity'}>
        <IdentityCont>
          <Grid container spacing={2} className={'ContCardIdentity GridBlueCard'}>
            <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 2 }} style={{paddingLeft: '5%'}}>
              <ContOnb>
                <BtnOnbard>
                  {t('Candado de Smartphone')}
                </BtnOnbard>
              </ContOnb>
              <ContTextIdentity>
                {t('Asegura el pago de tus financiamientos o arrendamientos')}
              </ContTextIdentity>
              <TextIdentitySummary>
                {t('Nuestra herramienta Blockfone te ayudará a reducir tu cartera moratoria al realizar el bloqueo parcial o total de smartphones a distancia.')}
              </TextIdentitySummary>
              <ContButton>
                <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'secondary'}/>
              </ContButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} 
              order={{ xs: 2, sm: 2, md: 1 }}
              className={'GridStylesCardImage'}
            >
              <IndentityPhoneImg src={phoneLock} alt={'Candado de smartphone Dynamicore'} visible={true} width={430}/>
            </Grid>
          </Grid>
        </IdentityCont>
      </ContCardIdentity>

      <ContHowWork id={'ContHowWork'}>
        <TitleHowWork>
          {t('¿Cómo funciona?')}
        </TitleHowWork>
        <ContTextDescHowWork>
          <TextDescHowWork>
            {t('El Candado de smartphone permite el bloqueo de equipos a distancia dejando inhabilitado el dispositivo mismo que se reactiva al momento de presentar el pago, así mismo puedes envía mensajes automatizados de alertas y recordatorio de pago.')}
          </TextDescHowWork>
        </ContTextDescHowWork>

        <StepsServices steps={stepsLockPhone} version={2}/>

        <ContButton>
          <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'primary'}/>
        </ContButton>
      
      </ContHowWork>

      <ServicesBeneficios items={LockPhoneItems} image={LockImage} alt={'Bloqueo de dispositivo Dynamicore'}/>

      <ActionDemo />
    </ContLockPhonePage>
  )
}
