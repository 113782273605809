import styled, { createGlobalStyle } from 'styled-components';

export const GSFormContact = createGlobalStyle`
  .contact-input {
    width: 100%;
  }
`
export const ContFirstFormContact = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;
  background-color: #FFFFFF;
  box-shadow: 0px 20px 43px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media(min-width: 300px) and (max-width: 767px){
    width: 96%;
    margin: 40px auto;
    padding: 20px 0;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 80%;
    margin: 60px auto;
    padding: 40px 0;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 80%;
    margin: 30px auto;
    padding: 20px 0;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 80%;
    margin: 30px auto;
    padding: 20px 0;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 90%;
    margin: 0px auto;
    padding: 20px 0;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 80%;
    margin: 23px auto;
    padding: 40px 0;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 70%;
    margin: 30px auto;
    padding: 40px 0;
  }
  @media(min-width: 1920px){
    width: 60%;
    margin: 30px auto;
    padding: 40px 0;
  }
`
export const ContAlertContact = styled.div`
  position: absolute;
  width: 100%;
  z-index: 2;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    top: -45px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 90%;
    top: -20px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 80%;
    top: -22px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 75%;
    top: 0px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 75%;
    top: 0px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 75%;
    top: -35px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 75%;
    top: -25px;
  }
  @media(min-width: 1920px){
    width: 70%;
    top: -40px;
  }
`
export const ContFormContact = styled.div`
  position: relative;
  height: auto;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 90%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 80%;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 75%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 75%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 75%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 75%;
  }
  @media(min-width: 1920px){
    width: 70%;
  }
`
export const ContFormContactItem = styled.div`
  @media(min-width: 300px) and (max-width: 767px){
    margin: 15px 0;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin: 15px 0;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin: 5px 0;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin: 10px 0;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin: 10px 0;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin: 10px 0;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin: 10px 0;
  }
  @media(min-width: 1920px){
    margin: 10px 0;
  }
`
export const ContButtonSend = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    justify-content: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    justify-content: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    justify-content: center;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    justify-content: center;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    justify-content: flex-start;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    justify-content: flex-start;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    justify-content: flex-start;
  }
  @media(min-width: 1920px){
    justify-content: flex-start;
  }
`
