import styled, { createGlobalStyle } from 'styled-components';
import BgRightSide from '../../assets/images/bg-contact.webp';
import {GlobalColors, GlobalFonts} from '../../globals';

export const GSRightSide = createGlobalStyle`
  .padding-top-sides {
    padding-top: 0 !important;
  }
  @media(min-width: 300px) and (max-width: 767px){
    .pt-right-side {
      padding-top: 65px !important;
    }
    .padding-top-sides {
      margin-top: 30px !important;
    } 
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .pt-right-side {
      padding-top: 80px !important;
    }
    .padding-top-sides {
      margin-top: 30px !important;
    }
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    .pt-right-side {
      padding-top: 27px !important;
    }
    .padding-top-sides {
      margin-top: 30px !important;
    }
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    .pt-right-side {
      padding-top: 80px !important;
    }
    .padding-top-sides {
      margin-top: 30px !important;
    }
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    .pt-right-side {
      padding-top: 80px !important;
    }
    .padding-top-sides {
      margin-top: 30px !important;
    }
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    .pt-right-side {
      padding-top: 80px !important;
    }
    .padding-top-sides {
      margin-top: 30px !important;
    }
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    .pt-right-side {
      padding-top: 70px !important;
    }
    .padding-top-sides {
      margin-top: 30px !important;
    }
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    .pt-right-side {
      padding-top: 81px !important;
    }
    .padding-top-sides {
      margin-top: 18px !important;
    }
  }
  @media(min-width: 1920px){
    .pt-right-side {
      padding-top: 90px !important;
    }
    .padding-top-sides {
      margin-top: 18px !important;
    }
  }
`
export const ContContactPage = styled.div`
  position: relative;
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 65px !important;
    background-image: url(${BgRightSide});
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 80px !important;
    background-image: url(${BgRightSide});
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding-top: 27px !important;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    padding-top: 80px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding-top: 80px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding-top: 80px !important;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    padding-top: 70px !important;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    padding-top: 79px !important;
  }
  @media(min-width: 1920px){
    padding-top: 90px !important;
  }
`
export const ContContactPageChild = styled.div`
  position: relative;
  height: 100%;
  min-height: 100%;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
  }
  @media(min-width: 1024px){
    width: 50%;
  }
`
export const ContRightSide = styled.div`
  position: relative;
  min-height: 100%;
  background-image: url(${BgRightSide});
  background-size: cover;
  background-repeat: no-repeat;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    height: auto !important;
    background-image: none;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: auto !important;
    background-image: none;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    height: 640px !important;
    margin-bottom: -30px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    height: 650px !important;
    margin-bottom: 0px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    height: ${props => props.height}px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    height: ${props => props.height}px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    height: ${props => props.height}px !important;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    height: ${props => props.height}px !important;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    height: ${props => props.height}px !important;
  }
  @media(min-width: 1920px){
    height: ${props => props.height}px !important;
  }
`
export const ContTitleContact = styled.div`
  position: relative;
  width: 100%;
  padding-left: 30%;
  padding-right: 20%;
  display: flex;
  align-items: flex-start;
  //justify-content: center;
  justify-content: space-between;
  flex-direction: column;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    margin: 45px 0 10px;
    padding-left: 0;
    padding-right: 0;
    align-items: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 90%;
    padding-left: 0;
    padding-right: 0;
    align-items: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 90%;
    height: 540px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 90%;
    height: 500px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 90%;
    height: auto;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 90%;
    height: 650px;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    position: relative;
    width: 100%;
    height: 650px;
    padding-left: 30%;
    padding-right: 10%;
    align-items: flex-start;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    position: relative;
    width: 100%;
    height: 650px;
    padding-left: 30%;
    padding-right: 10%;
    align-items: flex-start;
  }
  @media(min-width: 1920px){
    position: relative;
    width: 100%;
    height: 674px;
    padding-left: 30%;
    padding-right: 10%;
    align-items: flex-start;
  }
`
export const TitleContact = styled.h1`
  margin-top: 0;
  font-family: ${GlobalFonts.fontBold};
  font-size: 64px;
  line-height: 74px;
  color: ${GlobalColors.colorSecondary};
  
  @media(min-width: 300px) and (max-width: 767px){
    margin: 0 0 30px 0;
    font-size: 30px;
    line-height: 42px;
    text-align: center;  
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin: 0 0 30px 0;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin: 0 0 25px 0;
    font-size: 38px;
    line-height: 48px;
    text-align: left;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin: 0 0 15px 0;
    font-size: 40px;
    line-height: 50px;
    text-align: left;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin: 0 0 25px 0;
    font-size: 54px;
    line-height: 62px;
    text-align: left;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin: 0 0 25px 0;
    font-size: 56px;
    line-height: 64px;
    text-align: left;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    margin: 0 0 30px 0;
    font-size: 56px;
    line-height: 64px;
    text-align: left;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    margin: 0 0 30px 0;
    font-size: 56px;
    line-height: 64px;
    text-align: left;
  }
  @media(min-width: 1920px){
    margin: 0 0 30px 0;
    font-size: 64px;
    line-height: 74px;
    text-align: left;
  }
`
export const DescContact = styled.div`
  font-family: ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorSecondary};

  @media(min-width: 300px) and (max-width: 767px){
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 20px;
    line-height: 28px;
    text-align: match-parent;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 20px;
    line-height: 28px;
    text-align: match-parent;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-bottom: 60px;
    font-size: 20px;
    line-height: 28px;
    text-align: match-parent;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-bottom: 150px;
    font-size: 20px;
    line-height: 28px;
    text-align: match-parent;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    margin-bottom: 60px;
    font-size: 20px;
    line-height: 28px;
    text-align: match-parent;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    margin-bottom: 55px;
    font-size: 20px;
    line-height: 28px;
    text-align: match-parent;
  }
  @media(min-width: 1920px){
    width: 80%;
    margin-bottom: 55px;
    font-size: 20px;
    line-height: 28px;
    text-align: match-parent;
  }
`;
export const NotCreditMsg = styled.div`
  padding-top: 20px;
  font-family: ${GlobalFonts.fontRegular};
  color: #FFFFFF;

  @media(min-width: 300px) and (max-width: 767px){
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 16px;
    line-height: 28px;
    text-align: left;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 16px;
    line-height: 28px;
    text-align: left;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-bottom: 1px;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-bottom: 1px;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    margin-bottom: 1px;
    font-size: 16px;
    line-height: 28px;
    text-align: match-parent;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    margin-bottom: 1px;
    font-size: 16px;
    line-height: 28px;
    text-align: match-parent;
  }
  @media(min-width: 1920px){
    width: 80%;
    margin-bottom: 1px;
    font-size: 16px;
    line-height: 28px;
    text-align: match-parent;
  }
`
export const ContSliderClients = styled.div`
  width: 95%;

  @media(min-width: 300px) and (max-width: 1023px){
    //width: 100%;
    display: none;
  }
`
