import styled, { css } from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContItemMenu = styled.div`
  width: auto;
  height: auto;
  background-color: #FBFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const CfgBtnLink = css`
  height: 100%;
  
  background-color: transparent;
  border: none;

  /* Display & Box Model */
  display: flex;
  align-items: center;
  justify-content: center;
  
  /* Typografhy */
  text-decoration: none;
  font: 18px ${props => props.active ? GlobalFonts.fontBold : GlobalFonts.fontRegular};
  color: ${props => props.color};
  cursor: pointer;
  
  &:hover{
    color: ${GlobalColors.colorPrimaryDark};
  }
  
  &[aria-current]{
    color: ${props => props.color};
  }
  @media(min-width: 300px) and (max-width: 767px){
    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding: 10px;
    font-size: 13px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    padding: 10px;
    font-size: 14px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding: 10px;
    font-size: 14px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding: 15px;
    font-size: 15px;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    padding: 15px;
    font-size: 15px;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    padding: 15px;
    font-size: 15px;
  }
  @media(min-width: 1920px){
    padding: 15px;
    font-size: 17px;
  }
`
export const BtnLink = styled.button`
  ${CfgBtnLink}
  ${props => props.arrowRight && `
    padding-right: 0!important;
  `}
`
export const ALink = styled.a`
  ${CfgBtnLink}
`
export const ArrowCont = styled.div`
  cursor: pointer;
  padding: 8px 0px 0px 0px;
`
export const DropdownContainer = styled.div`
  position: absolute;
  top: calc(100% + -5px);
  left: 45%;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 500px;
  min-width: 200px;
  z-index: 1000;
  border-radius: 20px;

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 45%;
    transform: translateX(-50%);
    width: 30px;
    height: 15px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-bottom: none;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
  @media(min-width: 1024px) and (max-width: 1059px){
    left: 43%;

    &::before {
      left: 30%;
    }
  }
  @media(min-width: 1060px) and (max-width: 1079px){
    left: 45%;

    &::before {
      left: 25%;
    }
  }
  @media(min-width: 1080px) and (max-width: 1139px){
    left: 47%;

    &::before {
      left: 25%;
    }
  }
  @media(min-width: 1140px) and (max-width: 1180px){
    left: 48%;

    &::before {
      left: 30%;
    }
  }
  @media(min-width: 1181px) and (max-width: 1229px){
    left: 50%;

    &::before {
      left: 30%;
    }
  }
  @media(min-width: 1230px) and (max-width: 1279px){
    left: 50%;

    &::before {
      left: 30%;
    }
  }
  @media(min-width: 1280px) and (max-width: 1299px){
    left: 50%;

    &::before {
      left: 30%;
    }
  }
  @media(min-width: 1300px) and (max-width: 1329px){
    left: 50%;

    &::before {
      left: 33%;
    }
  }
  @media(min-width: 1330px) and (max-width: 1361px){
    left: 50%;

    &::before {
      left: 35%;
    }
  }
  @media(min-width: 1362px) and (max-width: 10000px){
    left: 50%;

    &::before {
      left: 35%;
    }
  }
`;

export const ExpandIco = styled.img`
  width: 110%;
  ${props =>
    !props.isContentVisible &&
    css`
      transform: rotate(180deg);
    `}
`
