import styled, { createGlobalStyle } from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const GlobalStylesLegals = createGlobalStyle`
  .row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .col {
    width: 100%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mt-4 {
    margin-top: 1.5rem!important;
  }
  .mt-3 {
    margin-top: 1rem!important;
  }
  .mt-2 {
    margin-top: 0.5rem!important;
  }
  .text-center {
    font-family: ${GlobalFonts.fontBold};
    font-size: 16px;
    line-height: 16px;
    color: ${GlobalColors.colorText};
  }
  .text-left {
    text-align: left;
    font-family: ${GlobalFonts.fontBold};
    font-size: 16px;
    line-height: 16px;
    color: ${GlobalColors.colorText};
  }
  .text-right {
    text-align: right;
    font-family: ${GlobalFonts.fontRegular};
    font-size: 14px;
    line-height: 16px;
    color: ${GlobalColors.colorText};
  }
  .text-justify {
    text-align: justify;
    font-family: ${GlobalFonts.fontRegular};
    font-size: 14px;
    line-height: 16px;
    color: ${GlobalColors.colorText};
  }
  .custom-hr {
    width: 100%;
    height: 3px;
    margin-top: 15px;
    background-color: #69B5FF;
  }
  .Container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .ContainerSitemap {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .containerStylist {
    margin-bottom: 30rem;
  }
  .routerLink {
    font-family: ${GlobalFonts.fontBold};
    font-size: 14px;
    line-height: 16px;
    color: ${GlobalColors.colorText};
    text-decoration: none;
  }
  .routerLink:hover {
    color: #8CB0FD;
  } 
`
export const ContTitleSitemap = styled.h1`
  width: 100%;
  font-family: ${GlobalFonts.fontBold};
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: '#040404;
  
  display: flex;
  align-items: left;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin: -6px 0 40px;
    font-size: 32px;
    line-height: 56px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin: -35px 0 40px;
    font-size: 44px;
    line-height: 56px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin: -55px 0 40px;
    font-size: 44px;
    line-height: 56px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin: -38px 0 40px;
    font-size: 48px;
    line-height: 56px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin: -38px 0 40px;
    font-size: 48px;
    line-height: 56px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin: -38px 0 40px;
    font-size: 48px;
    line-height: 56px;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    margin: -38px 0 40px;
    font-size: 48px;
    line-height: 56px;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    margin: -38px 0 40px;
    font-size: 48px;
    line-height: 56px;
  }
  @media(min-width: 1920px){
    margin: -38px 0 40px;
    font-size: 48px;
    line-height: 56px;
  }
`
export const ContTitleSitemap2 = styled.h2`
  width: 100%;
  font-family: ${GlobalFonts.fontBold};
  font-size: 35px;
  font-weight: 700;
  line-height: 44px;
  color: '#040404;
  
  display: flex;
  align-items: left;
  
  @media(min-width: 300px) and (max-width: 767px){
    font-family: ${GlobalFonts.fontBold};
    margin: -6px 0 40px;
    font-size: 32px;
    line-height: 56px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-family: ${GlobalFonts.fontBold};
    margin: -35px 0 40px;
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-family: ${GlobalFonts.fontBold};
    margin: -55px 0 40px;
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-family: ${GlobalFonts.fontBold};
    margin: -38px 0 40px;
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-family: ${GlobalFonts.fontBold};
    margin: -38px 0 40px;
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-family: ${GlobalFonts.fontBold};
    margin: -38px 0 40px;
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    font-family: ${GlobalFonts.fontBold};
    margin: -38px 0 40px;
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    font-family: ${GlobalFonts.fontBold};
    margin: -38px 0 40px;
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
  }
  @media(min-width: 1920px){
    font-family: ${GlobalFonts.fontBold};
    margin: -38px 0 40px;
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
  }
`

export const ContMapping = styled.div`
  margin: 0 5px 5px;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin: 0 0 50px;
    padding: 15px;
    border-radius: 14px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin: 0 5px 5px;;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin: 0 5px 5px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin: 0 5px 5px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin: 0 5px 5px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin: 0 5px 5px;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    margin: 0 5px 5px;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    margin: 0 5px 5px;
  }
  @media(min-width: 1920px){
    margin: 0 5px 5px;
  }
`
