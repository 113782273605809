import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const MainBanner = styled.div`
  background-color: rgba(255, 255, 255, 0.8); /* Color blanco con opacidad 0.8 */
  backdrop-filter: blur(10px); /* Efecto de desenfoque en el fondo */
  z-index: 9999;
  height: 100vh;
`

export const CookieBannerWrapper = styled.div`
  height: 190px;
  width: 600px;
  position: fixed;
  bottom: 5%;
  left: 5%;
  right: 0;
  background-color: #FFF;
  color: #fff;
  padding: 30px 5px;
  display: flex;
  z-index: 10000;
  border-radius: 15px;

  @media (max-width: 767px) {
    width: 90%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: auto;
    right: auto;
  }
`;

export const ContBanner = styled.div`
  display: block;
  @media(min-width: 300px) and (max-width: 767px){
    margin: 0 20px !important;
  }
  @media(min-width: 768px) and (max-width: 1079px){
    margin: 0 20px !important;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin: 0 30px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin: 0 30px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin: 0 30px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin: 0 30px !important;
  }
  @media(min-width: 1920px) and (max-width: 3200px){
    margin: 0 30px !important;
  }
`

export const TitleBanner = styled.div`
  font-family: ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorPrimaryDark};
  font-size: 22px;
  line-height: 21px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  @media(min-width: 200px) and (max-width: 767px){
    width: 100%;
    font-size: 32px;
    line-height: 37px;
    margin-bottom: 20px;
  }
`

export const IcoBanner = styled.img`
  display: flex;
  margin-right: 10px;
  width: 40px;
  height: 40px;
`

export const SubTitleBanner = styled.div`
  font-family: ${GlobalFonts.fontRegular};
  color: #282828;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 20px;

  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 20px;
  }
`

export const ContButtons = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
`

export const CookieButton = styled.button`
  width: 179px;
  height: 60px;
  padding: 20px 60px;
  border-radius: 5px;
  background-color: ${props => props.type === 'primary' ? '#0C3A72' : '#FFFFFF'};
  color: ${props => props.type === 'primary' ? '#FFFFFF' : '#0C3A72'};
  border: ${props => props.type === 'primary' ? 'none' : '1px solid #0C3A72'};
  padding: 5px 10px;
  cursor: pointer;
  font-family: ${GlobalFonts.fontBold};
  font-size: 17px;

  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
    height: 60px;
    padding: 3px 10px;
    font-size: 25px;
  }
`;
