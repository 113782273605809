import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import {
  ContBtn,
  ContFacilitarAgilizarProcesos, ContInfoFacilitarAgilizarProcesos, ContVideoMobile,
  TextFacilitarAgilizarProcesos,
  TitleFacilitarAgilizarProcesos,
} from './FacilitarAgilizarProcesosMobile.styles';
import ButtonSpecial from '../ButtonSpecial';
import VideoAgilProcessMobile from '../../assets/videos/asst-mobile.mp4';

export const FacilitarAgilizarProcesosMobile = () => {
  const { t } = useTranslation();
  const [play, setPlay] = useState(false);

  useEffect(() => {
    setPlay(true)
  }, []);

  useEffect(() => {
    setPlay(true)
  }, [play]);
  
  return (
    <ContFacilitarAgilizarProcesos id={'ContFacilitarAgilizarProcesosMobile'}>
      <div className={'container-fit'}>
        <ContInfoFacilitarAgilizarProcesos>
          <TitleFacilitarAgilizarProcesos>
          {t('Facilitamos y agilizamos tus procesos')}
          </TitleFacilitarAgilizarProcesos>
          <TextFacilitarAgilizarProcesos>
          {t('Creamos una plataforma 100% intuitiva donde tú eliges a los proveedores que mejor se adapten a tu estrategia, y te ayuden a agilizar operaciones internas y externas.')}
          </TextFacilitarAgilizarProcesos>
          <ContBtn id={'ContBtnMobile'}>
            <ButtonSpecial
              text={'Ir al marketplace'}
              type={'/'}
              location={'/marketplace'}
              theme={'primary'}
            />
          </ContBtn>
        </ContInfoFacilitarAgilizarProcesos>
          <ContVideoMobile id={'ContVideoMobile'}>
            <ReactPlayer
              url={VideoAgilProcessMobile}
              playing={play}
              loop={true}
              controls={false}
            />
          </ContVideoMobile>
      </div>
    </ContFacilitarAgilizarProcesos>
  );
};
