import React from 'react';
import Layout from '../components/Layout';
import Back1 from '../assets/images/back-home-1.webp';
import SEO from '../components/SEO';
import CardEmisionPage from '../pages/CardEmisionPage';

export const CardEmision = () => {

  return (
    <Layout backgroundimage={Back1} actionDemo={false}>
      <SEO
        title="Emisión de Tarjetas de Débito o Crédito"
        description="Facilita la emisión de tarjetas bancarias con nuestra plataforma. Ofrece a tus clientes productos financieros de forma rápida y segura."
        works='Core Financiero, Fintech, Onboardig digital, Aprobación de crédito, Gestión de cobranza, Análisis de crédito, Core Bancario, Automatización de procesos, Procesos digitales, Producto financiero, Servicios financieros, Digitalización de procesos, Software financiero, Dispersión, Reportes automatizados, Corebanking, Sector financiero, SOFIPO, SOFOM, CNBV, Condusef, Servicios financieros digitales, Plataforma financiera'
        bolImage={false}
        ogImage={`dynamicore-preview.png`}
        twitterImage={`dynamicore-preview.png`}
        canonicalUrl="https://dynamicore.io/emision-tarjetas"
      />
      <CardEmisionPage/>
    </Layout>
  )
}