import styled, { css } from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContStepServices = styled.div`
  margin-bottom: 100px;
`
export const ContStepServices2 = styled.div`
  margin-bottom: 100px;
  width: 90%;
  min-height: 0px;
  padding: 0px;
  @media(min-width: 100px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`

export const StepIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px; /* Ajustamos el espaciado entre los StepWrapper */
`;

export const StepIndicatorWrapper2 = styled.div`
  display: table;
  width: 100%;
  margin: 0px auto;
  @media(min-width: 100px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

export const StepIndicatorWrapper3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 130px; /* Ajustamos el espaciado entre los StepWrapper */
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const StepWrapper2 = styled.div`
  display: table-cell;
  position: relative;
  padding-top: 24px;
  ${props =>
    props.customWidth &&
    css`
      width: ${props.customWidth}%;
    `}
    
  @media(min-width: 100px) and (max-width: 767px) {
    width: 100%;
  }
`

export const ContCircle = styled.div`
  display: block;
  text-align: center;
  padding-top: 25px;
  z-index: 3;
`

export const Circle = styled.div`
  margin: 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #CEE1F8;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-family: ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorPrimaryDark};
  z-index: 2;
`;

export const Circle2 = styled.div`
  width: 60px;
  height: 60px;
  margin: 0px auto;
  background-color: #CEE1F8;
  border-radius: 50%;
  text-align: center;
  font-size: 24px;
  font-family: ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorPrimaryDark};
  display: flex;
  border-width: 0px;
  justify-content: center;
  align-items: center;
  z-index: 2;
`

export const Line = styled.div`
  width: calc(100% + 100px);
  height: 0;
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-1px);
  border-top: 2px dashed #CEE1F8; /* Cambiamos la línea completa por solo el borde superior */
  z-index: 1;
`;

export const Line2 = styled.div`
  position: absolute;
  top: 55px;
  height: 1px;
  border-top-style: dashed;
  border-top-width: 2px;
  border-top-color: #CEE1F8;
  right: 0px;
  left: 50%;
  margin-left: 40px;
  z-index: 0;
  @media(min-width: 100px) and (max-width: 767px){
    display: none;
  }
`;

export const Line22 = styled.div`
  position: absolute;
  top: 55px;
  height: 1px;
  border-top-color: #CEE1F8;
  border-top-style: dashed;
  border-top-width: 2px;
  right: -100px;
  left: 87%;;
  margin-left: 40px;
  z-index: 0;

  @media(min-width: 100px) and (max-width: 767px){
    display: none;
  }
  @media(min-width: 768px) and (max-width: 900px){
    left: 70%;;
  }
`;

export const Line3 = styled.div`
  width: calc(100% + 150px);
  height: 0;
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-1px);
  border-top: 2px dashed #CEE1F8; /* Cambiamos la línea completa por solo el borde superior */
  z-index: 0;
`;

export const Title = styled.span`
  font-family: ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorPrimaryDark};
  margin: 12px 0;
  font-size: 20px;
  text-align: center;
`;
export const Title2 = styled.span`
  font-family: ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorPrimaryDark};
  margin: 12px 0;
  font-size: 20px;
  text-align: center;
  display: block;
`;
export const ContSubtitle = styled.div`
  max-width: 50%;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100px; /* Ajusta este valor según tus necesidades, es la altura máxima que permitirá antes de desbordar */
`;
export const SubTitle = styled.span`
  font-family: ${GlobalFonts.fontRegular};
  color: #282828;
  font-size: 18px;
  text-align: center;
  padding-top: 0! important;
  display: block;
`;