import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material';
import '../../globals/services-styles.css';
import {ContDispersionPage, BtnOnbard, TextIdentitySummary, 
  ContButton, IndentityPhoneImg, ContTextDescHowWork,
} from './DispersionPage.styles';
import { IdentityCont, ContTextIdentity, ContCardIdentity, ContOnb, ContHowWork, TitleHowWork, TextDescHowWork } from '../globalpages.styles';
import ButtonSpecial from '../../components/ButtonSpecial';
import ActionDemo from '../../components/ActionDemo/index';
import DispersionImg from '../../assets/images/Servicios/img-dispersion.webp';
import DispersionService from '../../assets/images/Servicios/img-dispersion-beneficios.webp';
//import StepsServices from '../../components/StepsServices/index';
import ServicesIntegrations from '../../components/ServicesIntegrations/index';
import ServicesBeneficios from '../../components/ServicesBeneficios/index';
import Arcus from '../../assets/images/Servicios/arcus-services.webp';
import STP from '../../assets/images/Servicios/stp-services.webp';

const DispersionItems = [
  {
    title: 'Centraliza operaciones bancarias.',
  },
  {
    title: 'Seguridad en todas tus transacciones con STP',
  },
  {
    title: 'Programa dispersiones constantes.',
  },
  {
    title: 'Envía y recibe transferencias de pago.',
  },
  {
    title: 'Genera dispersiones a clientes, empleados y proveedores.',
  }
];

export const DispersionPage = () => {
  const { t } = useTranslation();
  const imagesToShow = [
    { src: Arcus, visible: true, alt: 'Logo Arcus by Masterdcard', },
    { src: STP, visible: true, alt: 'Logo STP', },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ContDispersionPage id={'ContDispersionPage'}>

      <ContCardIdentity id={'ContCardIdentity'}>
        <IdentityCont>
          <Grid container spacing={2} className={'ContCardIdentity GridBlueCard'}>
            <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 2 }} style={{paddingLeft: '5%'}}>
              <ContOnb>
                <BtnOnbard>
                {t('Dispersión')}
                </BtnOnbard>
              </ContOnb>
              <ContTextIdentity>
                {t('Realiza dispersiones instantáneas y seguras')}
              </ContTextIdentity>
              <TextIdentitySummary>
              {t('Olvídate de la conciliación manual y ten el control de todas tus dispersiones, procesos de pagos y transferencias desde un solo lugar.')}
              </TextIdentitySummary>
              <ContButton>
                <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'secondary'}/>
              </ContButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} 
              order={{ xs: 2, sm: 2, md: 1 }}
              className={'GridStylesCardImage'}
            >
              <IndentityPhoneImg src={DispersionImg} alt={'Dispersion Dynamicore'} width={400}/>
            </Grid>
          </Grid>
        </IdentityCont>
      </ContCardIdentity>

      <ContHowWork id={'ContHowWork'}>
        <TitleHowWork>
        {t('¿Cómo funciona?')}
        </TitleHowWork>
        <ContTextDescHowWork>
          <TextDescHowWork>
          {t('Al realizar tus dispersiones a través de SPEI agilizas todas tus operaciones bajo rigurosas medidas de seguridad de manera precisa.')}
          </TextDescHowWork>
        </ContTextDescHowWork>

        <ContButton>
          <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'primary'}/>
        </ContButton>
      
      </ContHowWork>

      <ServicesBeneficios items={DispersionItems} image={DispersionService} alt={'Abono de cuenta SPEI'} />

      <ServicesIntegrations imagesToShow={imagesToShow} text={'Dispersiones más ágiles y seguras con nuestros proveedores'}/>

      <ActionDemo />
    </ContDispersionPage>
  )
}
