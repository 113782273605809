import React from 'react';
import { Helmet } from "react-helmet"

export const SEO = (params) => {
  const { title = '', metaCia = 'Dynamicore', description = '', works = '', lang = 'es_MX', meta = {}, bolImage = false,
  ogImage = '', twitterImage = '', canonicalUrl = '',
  onlytitle = false, isContact = false, isUseCasesCredit = false,
  } = params;

  //const metaCia = 'Dynamicore';
  const metaUrl = window?.location?.origin;
  const metaTwitterSite = '';
  const metaTwitterCreator = '';

  const metaOgImage = `${metaUrl}/${ogImage}`;
  //console.log('metaOgImage=>', metaOgImage);
  const metaTwitterImage = `${metaUrl}/${twitterImage}`;

  //console.log('description=>', description);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={onlytitle ? `${title}` : `${title} | ${metaCia}`}
      titleTemplate={onlytitle ? `${title}` : `${title} | ${metaCia}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `robots`,
          content: 'all',
        },
        {
          name: `lang`,
          content: 'es',
        },
        {
          name: `keywords`,
          content: works,
        },
        {
          property: `og:title`,
          content: `${title} | ${metaCia}`,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          name: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: `${title} | ${metaCia}`,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:image`,
          content: metaOgImage,
        },
        {
          property: `og:local`,
          content: 'en_US',
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${metaCia}`,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:url`,
          content: metaUrl,
        },
        {
          name: `twitter:image`,
          content: metaTwitterImage,
        },
        {
          name: `twitter:site`,
          content: metaTwitterSite,
        },
        {
          name: `twitter:creator`,
          content: metaTwitterCreator,
        },
      ].concat(meta)}
    >
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="twitter:title" content={title} />
      <meta name="keywords" content={works} />
      <meta name="robots" content='INDEX, FOLLOW, MAX-SNIPPET:-1, MAX-VIDEO-PREVIEW:-1, MAX-IMAGE-PREVIEW:LARGE' />
      <meta name="author" content={'Dynamicore'} />
      <link rel="canonical" href={canonicalUrl} />
      <script src="https://www.googletagmanager.com/gtag/js?id=AW-10979614629"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-10979614629');
        `}
      </script>
      <script> 
        {`
          gtag('event', 'conversion', {'send_to': 'AW-10979614629/hJ5gCMCPpZgYEKW_vvMo'}); 
        `}
      </script>
      <script>
        {`
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3476732,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </script>
      
      <script>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NXK7DRW');
        `}
      </script>
      {isContact && (
        <script>
         {`fbq('track', 'CompleteRegistration');`}
        </script>
      )}
      {isUseCasesCredit && (
        
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-10979614629');
          `}
          </script>
        
      )}
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10979614629"></script>
    </Helmet>
  )
}
