import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material';
import '../../globals/services-styles.css';
import {ContPldPage, BtnOnbard, ContButton, IndentityPhoneImg } from './PldPage.styles';
import { IdentityCont, ContTextIdentity, TextIdentitySummary, ContCardIdentity, ContOnb, ContHowWork, ContTextDescHowWork, TitleHowWork, TextDescHowWork } from '../globalpages.styles';
import PldService from '../../assets/images/Servicios/img-pld-beneficios.webp';
import ButtonSpecial from '../../components/ButtonSpecial';
import ActionDemo from '../../components/ActionDemo/index';
import PldMethod from '../../assets/images/Servicios/pld-method.webp';
import ServicesBeneficios from '../../components/ServicesBeneficios/index';

const PldItems = [
  {
    title: 'Validación de identidad.',
  },
  {
    title: 'Búsqueda en listas negras OFAC, FBI, SAT, PEPs nacionales e internacionales.',
  },
  {
    title: 'Recibe alertas de fraude.',
  },
  {
    title: 'Revisión de transacciones inusuales, relevantes o preocupantes.',
  },
  {
    title: 'Reportes automatizados.',
  }
];

export const PldPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
 return(
  <ContPldPage id={'ContPldPage'}>
    <ContCardIdentity id={'ContCardIdentity'}>
      <IdentityCont>
        <Grid container spacing={2} className={'ContCardIdentity GridBlueCard'}>
          <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 1 }} style={{paddingLeft: '5%'}}>
            <ContOnb>
              <BtnOnbard adjustSize={true}>
                {t('Prevención de Lavado de Dinero (PLD)')}
              </BtnOnbard>
            </ContOnb>
            <ContTextIdentity>
              {t('Cumple con normativas y prevén riesgo de fraude.')}
            </ContTextIdentity>
            <TextIdentitySummary>
              {t('Conoce el perfil de tus clientes en las principales listas financieras a nivel mundial y cumple con todas las normativas con nuestro módulo de Prevención de Lavado de Dinero (PLD).')}
            </TextIdentitySummary>
            <ContButton>
              <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'secondary'}/>
            </ContButton>
          </Grid>
          <Grid item xs={12} sm={12} md={6} 
            order={{ xs: 2, sm: 2, md: 2 }}
            className={'GridStylesCardImage'}
          >
            <IndentityPhoneImg src={PldMethod} alt={'Prevención de lavado de dinero'} visible={true} width={430} />
          </Grid>
        </Grid>
      </IdentityCont>
    </ContCardIdentity>

    <ContHowWork id={'ContHowWork'}>
      <TitleHowWork>
        {t('¿Cómo funciona?')}
      </TitleHowWork>
      <ContTextDescHowWork>
        <TextDescHowWork>
          {t('Identifica y evalúa a tus clientes revisando las principales listas negras, PEPs nacionales e internacionales (Persona políticamente expuesta) y genera alertas para prevenir riesgo de fraude.')}
        </TextDescHowWork>
      </ContTextDescHowWork>
      
      <ContButton>
        <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'primary'}/>
      </ContButton>
    </ContHowWork>

    <ServicesBeneficios items={PldItems} image={PldService} alt={'PEPs nacionales e internacionales'} />

    <ActionDemo />

  </ContPldPage>
 )
};