import styled, { css } from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContDispersionPage = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 70px !important;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 120px !important;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding-top: 120px !important;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    padding-top: 110px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding-top: 120px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding-top: 130px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding-top: 130px !important;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    padding-top: 130px !important;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    padding-top: 130px !important;
  }
  @media(min-width: 1920px){
    padding-top: 150px !important;
  }
`
export const BtnOnbard = styled.div`
  height: 50px;
  background: #FFF;
  border-radius: 5px;
  padding: 0 20px;

  display: inline-flex;
  justify-content: center; /* Centrado horizontal */
  align-items: center; /* Centrado vertical */

  color: ${GlobalColors.colorPrimaryDark};
  font-family: ${GlobalFonts.fontBold};
  font-size: 22px;
  line-height: 22px;
`
export const TextIdentitySummary = styled.div`
  margin-bottom: 40px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 16px;
  line-height: 22px;
  color: ${GlobalColors.colorSecondary};
  
  & > span {
    font-family: ${GlobalFonts.fontBold};
  }

  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    position: relative;
    width: 100%;
    margin: 0 auto 35px;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 14px;
    line-height: 20px;
  }
  @media(min-width: 1080px) and (max-width: 1144px){
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  @media(min-width: 1145px) and (max-width: 1249px){
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  @media(min-width: 1250px) and (max-width: 1279px){
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 30px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  } 
`
export const ContButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 30px;
    justify-content: start;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 35px;
    justify-content: start;
  }
`
export const IndentityPhoneImg = styled.img`
  height: auto;
  margin-left: 0px;
  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `}

  @media(min-width: 300px) and (max-width: 399px){
    width: 240px;
    height: auto;
    margin-left: 15%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  @media(min-width: 400px) and (max-width: 499px){
    width: 240px;
    height: auto;
    margin-left: 20%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  @media(min-width: 500px) and (max-width: 659px){
    width: 240px;
    height: auto;
    margin-left: 25%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  @media(min-width: 660px) and (max-width: 899px){
    width: 300px;
    height: auto;
    margin-left: 30%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  @media(min-width: 900px) and (max-width: 1023px){
    width: 350px;
    height: auto;
    margin-left: 0px;
    text-align: start;
    align-items: flex-start;
    justify-content: flex-start;
  }
`
export const ContTextDescHowWork = styled.div`
  width: 60%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`