import styled from 'styled-components';

export const ContSlider = styled.div`
  position: relative;
  margin-bottom: 30px;
  
  & .slick-slide > div {
    padding: 0 15px;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    & .slick-slide > div {
      padding: 0 5px;
    }   
  }
  @media(min-width: 768px) and (max-width: 1023px){
    & .slick-slide > div {
      padding: 0 10px;
    }
  }
`
export const ImgItemSlider = styled.img`
  max-width: 300px;
  width: auto;
  margin: 0 10px;
  padding: 2%;
  @media(min-width: 300px) and (max-width: 767px){
    height: auto;
  }
  @media(min-width: 768px) and (max-width: 899px){
    height: 80px;
  }
  @media(min-width: 900px) and (max-width: 1023px){
    height: 120px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    height: 100px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    height: auto;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    height: auto;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    height: auto;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    height: auto;
  }
  @media(min-width: 1920px) and (max-width: 4919px){
    height: auto;
  }
`
export const ImgItemSliderClients = styled.img`
  margin: 0 10px;
  padding: 2%;
  height: 90px !important;
  width: 90px !important;
`