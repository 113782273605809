import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContBtn,
  ContFacilitarAgilizarProcesos, ContInfoFacilitarAgilizarProcesos, ContVideo,
  TextFacilitarAgilizarProcesos,
  TitleFacilitarAgilizarProcesos,
} from './FacilitarAgilizarProcesos.styles';
import ButtonSpecial from '../ButtonSpecial';
import VideoAgilProcess from '../../assets/videos/procesos.mp4';
//import VideoAgilProcessMobile from '../../assets/videos/asst-mobile.mp4';
//import {useWindowDimensions} from '../../utils';

export const FacilitarAgilizarProcesos = () => {
  const { t } = useTranslation();
  //const {width} = useWindowDimensions();
  //const isMobile = window.matchMedia('(max-width: 600px)').matches;
  const playPromise = () => {
    const promise = document.querySelector('video').play();

    // console.log('promise =>', promise);


    if (promise !== undefined) {
      promise.then(_ => {
        // Autoplay started!
        play();
      }).catch(error => {
        // Autoplay was prevented.
        // Show a "Play" button so that user can start playback.
      });
    }
  };

  const play = () => {
    const elV = document.getElementById('video');
    elV.play();
  };
  //const pause = () => {
  //  const elV = document.getElementById('video');
  //  elV.pause();
  //};

  useEffect(() => {
    playPromise();
    setTimeout(() => {
      playPromise();
    }, 5000);
  }, []);

  return (
    <ContFacilitarAgilizarProcesos id={'ContFacilitarAgilizarProcesos'}>
      <div className={'container-fit'}>
        <ContInfoFacilitarAgilizarProcesos>
          <TitleFacilitarAgilizarProcesos>
          {t('Facilitamos y agilizamos tus procesos')}
          </TitleFacilitarAgilizarProcesos>
          <TextFacilitarAgilizarProcesos>
          {t('Creamos una plataforma 100% intuitiva donde tú eliges a los proveedores que mejor se adapten a tu estrategia, y te ayuden a agilizar operaciones internas y externas.')}
          </TextFacilitarAgilizarProcesos>
          <ContBtn id={'ContBtn'}>
            <ButtonSpecial
              text={'Ir al marketplace'}
              type={'/'}
              location={'/marketplace'}
              theme={'primary'}
            />
          </ContBtn>
        </ContInfoFacilitarAgilizarProcesos>
          <ContVideo>
            <video id={'video'} autoPlay={true} controls={false} loop={true}>
              <source src={VideoAgilProcess} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </ContVideo>
      </div>
    </ContFacilitarAgilizarProcesos>
  );
};
