import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Back2, ContBack2, ContImg, ContTitleHome, ImageHome, SubTitleHome, ContButton} from './Home.styles';
import ButtonSpecial from '../ButtonSpecial';
import Image from '../../assets/images/core-bancario-2.webp';
import ImageBack2 from '../../assets/images/back-home-complete-2.webp';
import {useWindowDimensions} from '../../utils';
import TitleSpecial from '../TitleSpecial';

export const Home = () => {
  const { t } = useTranslation();
  const {width} = useWindowDimensions();
  const [imgBack, setImgBack] = useState();

  useEffect(() => {
    // console.log('width =>', width);
    setImgBack(parseInt(width, 10) >= 1024 ? ImageBack2 : ImageBack2);
  }, [width]);

  useEffect(() => {
    // console.log('imgBack =>', imgBack);
  }, [imgBack]);

  return (
    <div className={'section-first container-fit'} id={'section-first container-fit'}>
      <ContTitleHome>
      {width >= 600 ? (
        <TitleSpecial>
          {t('Transformamos tu financiera')} <br /> {t('desde el core')}
        </TitleSpecial>
      ) : (<TitleSpecial>
            {t('Transformamos tu financiera desde el core')}
          </TitleSpecial>)}
          <SubTitleHome>
            {t('El primer Core Financiero que te permite')} <span>{t('configurar productos financieros, administrar transacciones y simplificar operaciones')}</span> {t('de principio a fin.')}
        </SubTitleHome>
        <ContButton id={'ContButtonLeft'}>
          <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'}/>
        </ContButton>
        
      </ContTitleHome>

      <ContImg id={'ContImg'}>
        <ImageHome src={Image} alt={'Core Bancario'}/>
      </ContImg>

      
        <ContBack2 id={'ContBack2'}>
          <Back2 id={'imgBack2'} src={imgBack} alt={'Fondo de seccion'}/>
        </ContBack2>
    </div>
  );
};

