import React from 'react';
import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material';
import {
  ContAutomatizacionOperaciones,
  ContAutomatizacionOperacionesInner, ContButton, ContImgAutProc, ContInfoAutomatizacionOperaciones,
  TextAutoProcesos,
  TitleAutoProcesos,
} from './AutomatizacionOperaciones.styles';
import ButtonSpecial from '../ButtonSpecial';
import ImgAutProc from '../../assets/images/img-automatizacion-leo.webp';

export const AutomatizacionOperaciones = () => {
  const { t } = useTranslation();
  return (
    <ContAutomatizacionOperaciones id={'ContAutomatizacionOperaciones'}>
      <ContAutomatizacionOperacionesInner className={'container-fit z-index-cont'}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} order={{ xs: 2, sm: 2, md: 1 }}>
            <ContImgAutProc id={'ContImgAutProc'}>
              <img src={ImgAutProc} alt={'Automatización de mensajes'}/>
            </ContImgAutProc>
          </Grid>
          <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 2 }}>
            <ContInfoAutomatizacionOperaciones id={'ContInfoAutomatizacionOperaciones'}>
              <TitleAutoProcesos>
              {t('Automatización de operaciones')}
              </TitleAutoProcesos>
              <TextAutoProcesos>
              {t('Haz tus procesos más rápidos y facilita tu operación en nuestra plataforma, configurando tu flujo de crédito y generando reportes automatizados llevarás tus productos financieros a otro nivel.')}
              </TextAutoProcesos>
              <ContButton id={'contButtonSpecial'}>
                <ButtonSpecial text={'Ver más'} type={'/'} linkTo={''} theme={'secondary'}/>
              </ContButton>
            </ContInfoAutomatizacionOperaciones>
          </Grid>
        </Grid>
      </ContAutomatizacionOperacionesInner>
    </ContAutomatizacionOperaciones>
  )
}
