import React from 'react';
import {CardContainer, Card, CardTitle, CardDescription, Icon, Title} from './CardSlider.styles';

const CardComponent = ({ title, description, icon, isCardCenter }) => {
  return (
    <Card id={'CardComponent'} isCenter={isCardCenter} style={{textAlign: isCardCenter ?  'center' : 'flex-start'}}>
      <CardTitle isCenter={isCardCenter}>
        {icon && <Icon src={icon} alt="Ícono" />}
        <Title isCenter={isCardCenter}>{title}</Title>
      </CardTitle>
      <CardDescription isCenter={isCardCenter}>{description}</CardDescription>
    </Card>
  );
};

const CardSlider = ({cards, isCenter = false}) => {
  return (
    <CardContainer>
      {cards.map((card, index) => (
        <CardComponent key={index} {...card} isCardCenter={isCenter} />
      ))}
    </CardContainer>
  );
};

export default CardSlider;
