import styled, { css } from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';
import {Container} from '@mui/material';

export const ImgMenu = styled.img`
  margin-right: -15px;
  float: right;
`
export const ContMenuMobile = styled(Container)`
  z-index: 100;
  width: 64px;
  height: 64px;
  padding: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 1024px){
    display: none !important;
  }
`
export const ContBodyMenuMobile = styled(Container)`
  position: fixed;
  width: 100%;
  height: 93vh;
  top: 65px;
  left: 0;
  padding: 0 !important;
  background: ${GlobalColors.colorMenuMobileBG};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  overflow-y: auto; /* Habilita el scroll vertical cuando sea necesario */
`
export const BodyMenuMobile = styled.div`
  width: 100%;
  height: calc(100% - 190px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`
export const ContItemsMenuMobile = styled.div`
  width: 100%;
`
export const ContItemsMenuMobileBtn = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ExpandIco = styled.img`
  width: 50px;
  padding-top: 5px;
  transition: transform 0.2s ease;
  ${props =>
    !props.isContentVisible &&
    css`
      transform: rotate(-180deg);
      padding-bottom: 5px;
      padding-top: 0px;
    `}
`

export const ItemMenuMobile = styled.button`
  width: 100%;
  height: 110px;
  padding: 15px 0;
  background-color: transparent;
  border: none;
  border-bottom: ${props => props.borderBottom === true ? `none` : `1px solid ${GlobalColors.colorBorder}`};
  font: 24px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: ${GlobalColors.colorBtnTextSecondary};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContItemMenuMobileServices = styled.div`
  display: flex;
  align-items: center;
`

export const ContItemMenuMobileLenguagess = styled.div`
  display: block;
  align-items: center;
`

export const ItemMenuMobileServices = styled.button`
  width: 100%;
  height: 90px;
  padding: 15px 0;
  background-color: transparent;
  border: none;
  
  border-bottom: ${props => props.borderBottom === true ? `1px solid ${GlobalColors.colorBorder}` : `none`};
  font: 18px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: #CEE1F8;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  & > img {
    width: 40px;
    height: 40px;
  }
`
export const ContFooterMenuMobile = styled.div`
  /* position: absolute; */
  width: 100%;
  margin-top: 50px;
  height: auto;
  /* bottom: 200px; */
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font: 15px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  color: ${GlobalColors.colorSecondary};
`

export const ListaServiciosMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  overflow-y: auto; /* Agregar el scroll vertical */
  z-index: 1000;
`;

export const ContGLobe = styled.div`
  margin-right: 1px
`;
