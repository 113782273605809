import React from "react";
import {
  HeaderGlobalStyles,
  ContHeader,
  ContHeaderFirst,
  ContHeaderInner, ContBtn, ContBtnMenuMobile, ContLogo, ContMenu, ContLenguage,
} from './Header.styles';
import { Logo } from "../Logo";
import Menu from "../Menu";
import MenuMobile from "../MenuMobile";
import Language from '../Language/Language';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
const Header = () => {
  const { t } = useTranslation();
  return (
    <ContHeaderFirst id={'ContHeaderFirst'}>
      <HeaderGlobalStyles />
      <ContHeader>
        <ContHeaderInner>
          <ContLogo>
            <Logo />
          </ContLogo>
          <ContMenu>
            <Menu />
          </ContMenu>
          <ContBtn>
            <Button
              text={t('Contáctanos')}
              type={'/'}
              linkTo={'/contacto'}
              withIcon={true}
              target={'_self'}
            />
          </ContBtn>
          <ContBtnMenuMobile>
            <MenuMobile />
          </ContBtnMenuMobile>
          <ContLenguage>
            <Language />
          </ContLenguage>
        </ContHeaderInner>
      </ContHeader>
    </ContHeaderFirst>
  );
};

export default Header;
