import React from 'react';
import {
  ImagesContainer, ImagesPack, Image1, Image2,
} from './NosotrosImagesContainer.styles';
import Images from '../../assets/images/NosotrosImages/img-nosotros-pack.webp';
import Image11 from '../../assets/images/NosotrosImages/NosotrosImage1.webp';
import Image22 from '../../assets/images/NosotrosImages/Nosotros-Image22.webp';
//import Image33 from '../../assets/images/NosotrosImages/cont-img-3.png';
//import Image44 from '../../assets/images/NosotrosImages/Nosotros-img-4.png';

export const NosotrosImagesContainer = () => {
  
  return (
    <ImagesContainer>
      
      <Image1 src={Image11} alt="Oficinas Dynamicore" />
      <Image2 src={Image22} alt="Equipo 1 Dynamicore" />
      <ImagesPack src={Images} alt="Equipo Dynamicore" />
      
      {/*
      <Image1 src={Image11} alt="Imagen 1" />
      <Image2 src={Image22} alt="Imagen 2" />
      <Image3 src={Image33} alt="Imagen 3" />
      <Image4 src={Image44} alt="Imagen 4" />
    */}
    </ImagesContainer>
  );
};