import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {Grid, Card, CardContent} from '@mui/material';
import {useWindowDimensions} from '../../utils';
import {ContInfoMisionVision, ContImgMision, TitleDifine, SubTitleDifine, CardTitle, CardTitleGray, CardDescription, 
  ContCeoText, CornerImage, ContText, TextBoldCard, ProfileImage, NameCeoCont, TitleNameCeo,
  ContFinalImage, GradinatCont,
} from './NosotrosPage.styles';
//import { makeStyles } from '@material-ui/core/styles';
import '../../globals/services-styles.css';
import ImgeMision from '../../assets/images/NosotrosImages/img-mision-2.webp';
import ImgeVision from '../../assets/images/NosotrosImages/img-vision-2.webp';
import ComillasImage from '../../assets/images/NosotrosImages/comillas-card.webp';
import ImageBack2 from '../../assets/images/NosotrosImages/back-ceo.webp';
import Profile from '../../assets/images/NosotrosImages/elipse-ceo.webp';
//import StatisticsCounter from '../StatisticsCounter';

//const useStyles = makeStyles((theme) => ({
//  cardStyle: {
//    width: 'calc(70% - 30px)',
//    borderRadius: '20px !important',
//    height: '270px',
//    textAlign: 'center',
//    position: 'relative',
//    boxShadow: '0px 44px 40px 0px rgba(12, 58, 114, 0.3)',
//    [theme.breakpoints.up(768)]: {
//      width: 'calc(70% - 30px)',
//      borderRadius: '20px!important', // You can adjust this value for smaller screens
//    },
//    [theme.breakpoints.down(768)]: {
//      width: 'calc(95% - 30px)',
//      borderRadius: '20px!important', // You can adjust this value for smaller screens
//    },
//    [theme.breakpoints.down(510)]: {
//      height: '300px',
//    },
//    [theme.breakpoints.down(410)]: {
//      height: '320px',
//    },
//    [theme.breakpoints.down(390)]: {
//      height: '350px',
//    },
//    [theme.breakpoints.down(370)]: {
//      height: '370px',
//    },
//  },
//  widthCard: {
//    width: '90%',
//    [theme.breakpoints.down(768)]: {
//      width: '100%'
//    },
//  },
//}));

export const MisionVision = () => {
  const { t } = useTranslation();
  //const classes = useStyles();
  const {width} = useWindowDimensions();
  const [hoveredCard, setHoveredCard] = useState(1);
  const [imgBack, setImgBack] = useState();

  const handleCardHover = (cardNumber) => {
    setHoveredCard(cardNumber);
  };

  //const handleCardLeave = () => {
  //  setHoveredCard(null);
  //};

  useEffect(() => {
    // console.log('width =>', width);
    setImgBack(parseInt(width, 10) >= 1024 ? ImageBack2 : ImageBack2);
  }, [width]);

  useEffect(() => {
    // console.log('imgBack =>', imgBack);
  }, [imgBack]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 1 }}>
          <ContImgMision>
            <img
              id={'ImgeMision'}
              alt={hoveredCard === 1 ? 'Misión Dynamicore' : 'Visión Dynamicore'}
              style={{
                opacity: hoveredCard === 1 ? 1 : 1,
                transition: 'opacity 0.3s ease', // Transición de opacidad al cambiar la imagen
              }}
              src={hoveredCard === 1 ? ImgeMision : ImgeVision}
            />
          </ContImgMision>
        </Grid>
        <Grid item xs={12} sm={12} md={6} order={{ xs: 2, sm: 2, md: 2 }}>
          <ContInfoMisionVision id={'ContInfoMisionVision'}>
            <TitleDifine>
              {t('Conoce nuestra misión y visión')}
            </TitleDifine>
            <SubTitleDifine>
              {t('Nos comprometemos a proporcionar una plataforma que opera bajo todas las normas de seguridad y requerimientos solicitados por la Comisión Nacional Bancaria y de Valores (CNBV).')}
            </SubTitleDifine>
            <Card
              id={'cardVision'}
              onMouseEnter={() => handleCardHover(1)}
              //onMouseLeave={handleCardLeave}
              //className={`${classes.widthCard}`}
              style={{
                borderRadius: '20px',
                boxShadow: hoveredCard === 1 ? '0px 13px 30px 0px rgba(0, 0, 0, 0.09)' : 'none',
                marginBottom: '30px',
                opacity: hoveredCard === 1 ? 1 : 0.5,
                transition: 'opacity 0.3s ease, box-shadow 0.3s ease',
                minWidth: '90%',
                maxWidth: '90%',
              }}
            >
              <CardContent style={{padding: '20px 30px 30px 30px', maxWidth: '80%'}}>
              {hoveredCard === 1 ? (
                <CardTitle>{t('Nuestra Misión')}</CardTitle>) :
                (
                  <CardTitleGray>{t('Nuestra Misión')}</CardTitleGray>
                )}
                <CardDescription>
                  {t('Impulsar la transformación financiera a través de tecnología adaptable, escalable y configurable.')}
                </CardDescription>
              </CardContent>
            </Card>
            <Card
              id={'cardVision'}
              onMouseEnter={() => handleCardHover(2)}
              //onMouseLeave={handleCardLeave}
              //className={`${classes.widthCard}`}
              style={{
                borderRadius: '20px',
                boxShadow: hoveredCard === 2 ? '0px 13px 30px 0px rgba(0, 0, 0, 0.09)' : 'none',
                opacity: hoveredCard === 2 ? 1 : 0.5,
                transition: 'opacity 0.3s ease, box-shadow 0.3s ease',
                minWidth: '90%',
                maxWidth: '90%',
              }}
            >
              <CardContent style={{padding: '20px 30px 30px 30px', maxWidth: '80%'}}>
                {hoveredCard === 2 ? (<CardTitle>{t('Nuestra Visión')}</CardTitle>) 
                : 
                (<CardTitleGray>{t('Nuestra Visión')}</CardTitleGray>)
                }
                
                <CardDescription>
                {t('Ser el aliado tecnológico que transforma los servicios financieros.')}
                </CardDescription>
              </CardContent>
            </Card>
          </ContInfoMisionVision>
        </Grid>
      </Grid>

      <ContCeoText id={'ContCeoText'}>
        <Card style={{
          width: 'calc(70% - 30px)',
          borderRadius: '20px',
          height: '270px',
          textAlign: 'center',
          position: 'relative',
          boxShadow: '0px 44px 40px 0px rgba(12, 58, 114, 0.3)',
        }}>
          <CornerImage src={ComillasImage} alt="Imagen esquina superior izquierda" />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={2} sm={3} md={3} order={{ xs: 1, sm: 1, md: 1 }}>

              </Grid>
              <Grid item xs={10} sm={9} md={9} order={{ xs: 2, sm: 2, md: 2 }}>
                <ContText>
                {t('Desde el principio, nuestro objetivo fue integrar las ')}<TextBoldCard>{t('mejores prácticas digitales ')}</TextBoldCard>
                {t('donde fusionamos nuestros conocimientos en la implementación de big data con el procesamiento de información financiera, para otorgar a nuestros clientes  el respaldo tecnológico necesario para ')}
                  <TextBoldCard>{t('potencializar sus negocios y cumplir sus objetivos.')}</TextBoldCard>
                  <br/>
                  <br/>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: '10px',
                    }}>
                    <ProfileImage src={Profile} alt="CEO Dynamicore" />
                    <NameCeoCont>
                      <TitleNameCeo>Oswaldo López</TitleNameCeo>
                      <span>CEO Dynamicore</span>
                    </NameCeoCont>
                  </div>
                </ContText>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </ContCeoText>
      {/*
      <ContStatisticsCounters>
        <Grid container spacing={2} style={{width: 'calc(70% - 30px)'}}>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <ContItem>
              <StatisticsCounter small={true} counter={4} label={'Años en el mercado'}/>
              <ContVerticalLineV/>
              <ContVerticalLineH/>
            </ContItem>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <ContItem>
              <StatisticsCounter small={true} counter={42} label={'Interacciones con Dynamicore'}/>
              <ContVerticalLineV/>
              <ContVerticalLineH/>
            </ContItem>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <ContItem>
              <StatisticsCounter small={true} counter={8} label={'Clientes activos'}/>
              <ContVerticalLineV/>
              <ContVerticalLineH/>
            </ContItem>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <ContItem>
              <StatisticsCounter small={true} counter={537} label={'Créditos'}/>
              <ContVerticalLineV/>
            </ContItem>
          </Grid>
        </Grid>
      </ContStatisticsCounters> */}

      <ContFinalImage id={'ContFinalImage'}>
        <GradinatCont />
        {/*<BackFinalImage id={'finalImageback'} src={imgBack} alt={'finalImageback'}/>*/}
      </ContFinalImage>
    </>
  )
}
