import styled, { keyframes } from 'styled-components';
import {GlobalFonts} from '../../globals';
import {Card, CardContent} from '@mui/material';

export const ContImgMision = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media(min-width: 1px) and (max-width: 899px){
    & > img {
      display: none;
    }
  }
  @media(min-width: 900px) and (max-width: 1023px){
    & > img {
      width: 90%;
    }
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    & > img {
      width: 100%;
    }
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    & > img {
      width: 100%;
    }
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    & > img {
      width: 100%;
    }
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    & > img {
      width: 100%;
    }
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    & > img {
      width: 100%;
    }
  }
  @media(min-width: 1920px){
    & > img {
      width: 100%;
    }
  }
`

export const ContInfoMisionVision = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    flex-direction: column;
    margin: 0 auto;
  }
  @media(min-width: 768px) and (max-width: 899px){
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  @media(min-width: 900px) and (max-width: 1023px){
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 90%;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 95%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 95%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 93%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 95%;
  }
  @media(min-width: 1920px){
    width: 90%;
  }
`
export const CardContainer = styled(Card)`
  width: 100%;
  border-radius: 20px!important;
  margin-bottom: 15px;
  opacity: ${(props) => (props.isHovered ? 1 : 1)};
  color: rgba(0, 0, 0, 0.87);
  transition: opacity 0.3s ease, box-shadow 0.3s ease;
  cursor: default;
  overflow: hidden;
  box-shadow: none!important;
`;
export const CardContentContainer = styled(CardContent)`
  padding: 10px 25px 10px 25px;
  background-color: ${(props) => (props.isHovered ? '#F0F6FD' : 'white')};
  display: block;
  transition: block 0.9s ease;
`;
// Keyframes for the slide-down animation
const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
export const CardTitle = styled.div`
  display: block;
  font-family: ${GlobalFonts.fontBold};
  text-align: left;
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
    margin-top: 0; 
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 30px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 34px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 34px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 34px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 34px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 34px;
    marginTop: 0; 
  }
  @media(min-width: 1920px){
    font-size: 34px;
    marginTop: 0; 
  }
`
export const CardTitleGray = styled.div`
  display: block;
  font-family: ${GlobalFonts.fontBold};
  text-align: left;
  color: #8F8F8F;
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
    margin-top: 0; 
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 30px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 34px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 34px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 34px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 34px;
    margin-top: 0;
    padding: 0;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 34px;
    marginTop: 0; 
  }
  @media(min-width: 1920px){
    font-size: 34px;
    marginTop: 0; 
  }
`
export const CardDescription = styled.div`
  cursor: default;
  font-family: ${GlobalFonts.fontRegular};
  text-align: left;
  color: #4A4A4A;
  height: 50px;

  @media(min-width: 200px) and (max-width: 470px){
    font-size: 16px;
    height: 60px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 16px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 18px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 16px;
    height: 60px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 18px;
    height: 70px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 18px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 18px;
  }
  @media(min-width: 1920px){
    font-size: 18px;
  }
`

export const AnimatedCardContentContainer = styled(CardContent)`
  padding: 10px 25px 10px 25px;
  transition: background-color 0.3s ease, max-height 0.5s ease, opacity 0.5s ease;
  background-color: ${(props) => (props.isHovered ? '#F0F6FD' : 'white')};
  display: block;
  transition: block 0.9s ease;  
  animation: ${slideDown} 0.5s ease-in-out;
`;

export const AnimatedCardTitle = styled(CardTitle)`
  animation: ${slideDown} 0.5s ease-in-out;
  color: ${(props) => (props.isHovered ? '#282828' : '#8F8F8F')};
  transition: color 0.8s ease; 
`;


export const AnimatedCardDescription = styled(CardDescription)`
  animation: ${slideDown} 0.3s ease-in-out;
  display: ${(props) => (props.isHovered ? 'block' : 'none')};
  animation-delay: 0.2s;
  animation-fill-mode: both;
`;