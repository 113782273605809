import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import Back1 from '../assets/images/back-home-1.webp';
import SEO from '../components/SEO';
import NosotrosPage from '../pages/NosotrosPage';

export const Nosotros = () => {
  const { t } = useTranslation();
  return (
    <Layout backgroundimage={Back1} actionDemo={false}>
      <SEO
        title="Conoce Nuestra Experiencia en el sector financiero"
        description={t('Somos líderes en el desarrollo de soluciones digitales para entidades financieras. Descubre quiénes somos y cómo podemos ayudar a tu institución a prosperar en la era digital.')}
        works={t('Core Financiero, Core Bancario, Plataforma para sofom, Plataforma para sofipo, Software financiero, Tecnológica financiera, Dynamicore')}
        bolImage={false}
        ogImage={`dynamicore-preview.png`}
        twitterImage={`dynamicore-preview.png`}
        canonicalUrl="https://dynamicore.io/nosotros"
      />
      <NosotrosPage/>
    </Layout>
  )
}
