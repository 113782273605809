import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContServicesIntegrations = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(to right, #0C3A72, #0B6BDD);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media(min-width: 100px) and (max-width: 499px){
    padding: 60px 0px !important;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media(min-width: 500px) and (max-width: 767px){
    padding: 90px 0px !important;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media(min-width: 768px) and (max-width: 900px){
    padding: 90px 0 !important;
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding: 90px 0 !important;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding: 90px 0 !important;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    padding: 90px 0 !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding: 90px 0 !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding: 90px 0 !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding: 90px 0 !important;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    padding: 90px 0 !important;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    padding: 90px 0 !important;
  }
  @media(min-width: 1920px){
    padding: 90px 0 !important;
  }
`
export const ContTextIntegration = styled.h3`
  text-align: center;
  margin-bottom: 20px;
  font-family: ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorSecondary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
    line-height: 38px;
    text-align: left;
    width: 90%;
    margin: 0 auto 30px;
    padding-left: 0px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    position: relative;
    width: 70%;
    margin: 0 auto 35px;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 36px;
    line-height: 36px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 34px;
    line-height: 46px;
    
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 46px;
    line-height: 46px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 46px;
    line-height: 46px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 46px;
    line-height: 46px;
  }
  @media(min-width: 1920px){
    font-size: 46px;
    line-height: 46px;
  }
`
export const TextIntegrationSummary = styled.div`
  width: 50%;
  margin-bottom: 40px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;

  z-index: 1;
  
  & > span {
    font-family: ${GlobalFonts.fontBold};
  }

  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    margin: 0 auto;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
    padding-left: 0px;
  }
  @media(min-width: 768px) and (max-width: 900px){
    width: 80%;
    margin: 0 auto;
  }
  @media(min-width: 901px) and (max-width: 1023px){
    width: 100%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 80%;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 55%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 55%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 65%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 60%;
  }
  @media(min-width: 1920px){
    width: 50%;
  }
`
export const BoldIntegrationText = styled.b`
  color: #FFFFFF;
  font-family: ${GlobalFonts.fontBold};
  font-size: 20px;
`;

export const ContImagesIntegrations = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${props => (props.noSpace ? '0px' : '20px')};
  max-width: 1000px;
  margin: 0 auto;
  @media(min-width: 100px) and (max-width: 550px){
    flex-direction: column;
  }
`;

export const ImagesIntegration = styled.img`
  width: 250px !important;
  transition: opacity 0.1s;
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
  display: ${props => (props.visible ? 'flex' : 'none')};

  @media(min-width: 100px) and (max-width: 550px){
    width: 200px !important;
  }

`
export const ImagesIntegrationContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const ContButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  //margin-bottom: 120px;

  @media(min-width: 300px) and (max-width: 767px){
    display: none;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    //margin-bottom: 35px;
    justify-content: center;
  }
`