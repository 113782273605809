import React, {useState, useEffect} from 'react';
import {Grid} from '@mui/material';
import {ContInfoMisionVision, ContImgMision, CardContainer, CardContentContainer, CardDescription,
 CardTitleGray, AnimatedCardTitle, AnimatedCardDescription, AnimatedCardContentContainer} from './ModulesTabs.styles';
//import { makeStyles } from '@material-ui/core/styles';
import '../../globals/services-styles.css';
import IphoneDigital from '../../assets/images/UseCases/modulesTabs/iphone-digital.png';
import Analisis from '../../assets/images/UseCases/modulesTabs/module-analisis.png';
import Score from '../../assets/images/UseCases/modulesTabs/module-score.png';
import Pld from '../../assets/images/UseCases/modulesTabs/module-pld.png';
import Dispersion from '../../assets/images/UseCases/modulesTabs/module-dispersion.png';
import Notifications from '../../assets/images/UseCases/modulesTabs/module-campa-white2.png';
import Cobranza from '../../assets/images/UseCases/modulesTabs/module-cobranza.png';

const cardData = [
  {
    alt: 'Registro de información de clientes con Onboarding digital',
    ttl: 'Registra nuevos clientes con Onboarding Digital',
    title: 'Onboarding digital',
    description: 'Agiliza la integración de nuevos clientes, solicitando información de manera digital y validándola con el método más seguro.',
    image: IphoneDigital,
  },
  {
    alt: 'Realiza un análisis de crédito de tus clientes',
    ttl: 'Analiza el historial y el score crediticio de tus clientes',
    title: 'Análisis de crédito',
    description: 'Evalúa la capacidad de pago de tus clientes consultando su historial y score crediticio de manera fácil y rápida.',
    image: Analisis,
  },
  {
    alt: 'Evalúa el comportamiento digital de tus clientes',
    ttl: 'Obtén el score crediticio de tus clientes',
    title: 'Score Alternativo',
    description: 'Obten la puntuación crediticia de tus clientes por medio de su comportamiento digital.',
    image: Score,
  },
  {
    alt: 'Prevención de lavado de dinero',
    ttl: 'Cumple con normativas PLD',
    title: 'PLD',
    description: 'Cumple con normativas y prevén riesgo de fraude',
    image: Pld,
  },
  {
    alt: 'Dispersión de recursos a clientes, empleados y proveedores',
    ttl: 'Controla todas tus dispersiones desde una plataforma',
    title: 'Dispersión',
    description: 'Olvidate de la conciliación manual y ten control de todas tus dsipersiones.',
    image: Dispersion,
  },
  {
    alt: 'Configura tu campaña de mensajes para tus clientes',
    ttl: 'Campaña de mensajes automatizados',
    title: 'Campaña de notificaciones',
    description: 'Crea una estrategia de comunicación con tus clientes.',
    image: Notifications,
  },
  {
    alt: 'Ofrece los mejores medios de pago',
    ttl: 'Los mejores métodos de pago',
    title: 'Cobranza',
    description: 'Los mejores metodos de pago para tu cliente.',
    image: Cobranza,
  },
];

const ImageContent = ({ hoveredCard }) => {
  const backgroundImage = cardData[hoveredCard] ? cardData[hoveredCard].image : ''; // Obtenemos la imagen de fondo del card seleccionado

  return (
      <ContImgMision>
        <img
          id={'ImgeMision'}
          alt={cardData[hoveredCard]?.alt || ''}
          style={{
            opacity: hoveredCard === 0 ? 1 : 1,
            transition: 'opacity 0.3s ease', // Transición de opacidad al cambiar la imagen
          }}
          src={backgroundImage}
          title={cardData[hoveredCard]?.ttl || ''}
        />
      </ContImgMision>
  );
};

export const ModulesTabs = () => {
  //const classes = useStyles();
  const [hoveredCard, setHoveredCard] = useState(0);
  
  const handleCardHover = (cardNumber) => {
    setHoveredCard(cardNumber);
  };

  useEffect(() => {
    // Tu lógica para cambiar la imagen de fondo
  }, [hoveredCard]);

  return (
    <Grid container spacing={2} id={'GridPrincipal'}>
      <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 1 }}>
        <ImageContent hoveredCard={hoveredCard} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} order={{ xs: 2, sm: 2, md: 2 }}>
        <ContInfoMisionVision id={'ContInfoMisionVision'}>
          {cardData.map((card, index) => (
            <CardContainer
              key={index}
              //onClick={() => handleCardHover(index)}
              onMouseEnter={() => handleCardHover(index)}
            >
              <AnimatedCardContentContainer isHovered={hoveredCard === index}>
                <AnimatedCardTitle isHovered={hoveredCard === index}>{card.title}</AnimatedCardTitle>
                <AnimatedCardDescription isHovered={hoveredCard === index}>{card.description}</AnimatedCardDescription>
              </AnimatedCardContentContainer>
            </CardContainer>
          ))}
        </ContInfoMisionVision>
      </Grid>
    </Grid>
  );
}