import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import Back1 from '../assets/images/back-home-1.webp';
import BeneficiosPage from '../pages/BeneficiosPage';
import SEO from '../components/SEO';

export const Beneficios = () => {
  const { t } = useTranslation();
  return (
    <Layout backgroundimage={Back1} actionDemo={true}>
      <SEO
        title="Ventajas de Nuestra Plataforma de Core Bancario"
        description={t('Explore los beneficios de nuestra plataforma, desde la simplificación del onboarding hasta la mejora de la gestión de créditos y la eficiencia en la cobranza. Optimiza tus operaciones.')}
        works={t('Core Financiero, Fintech, Onboardig digital, Aprobación de crédito, Gestión de cobranza, Análisis de crédito, Core Bancario, Automatización de procesos, Procesos digitales, Producto financiero, Servicios financieros, Digitalización de procesos, Software financiero, Dispersión, Reportes automatizados, Corebanking, Sector financiero, SOFIPO, SOFOM, CNBV, Condusef, Servicios financieros digitales, Plataforma financiera')}
        bolImage={false}
        ogImage={`dynamicore-preview.png`}
        twitterImage={`dynamicore-preview.png`}
        canonicalUrl="https://dynamicore.io/beneficios"
      />
      <BeneficiosPage/>
    </Layout>
  )
}
