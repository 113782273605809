import styled, { css } from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

const hiddenStyle = css`
  display: none;
`;

export const ContLockPhonePage = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 70px !important;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 120px !important;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding-top: 120px !important;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    padding-top: 110px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding-top: 120px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding-top: 130px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding-top: 130px !important;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    padding-top: 130px !important;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    padding-top: 130px !important;
  }
  @media(min-width: 1920px){
    padding-top: 150px !important;
  }
`

export const BtnOnbard = styled.div`
  height: 50px;
  background: #FFF;
  border-radius: 5px;

  padding: 0 20px;

  display: inline-flex;
  justify-content: center; /* Centrado horizontal */
  align-items: center; /* Centrado vertical */

  color: ${GlobalColors.colorPrimaryDark};
  font-family: ${GlobalFonts.fontBold};
  font-size: 22px;
  line-height: 22px;
  ${props =>
    props.adjustSize &&
    css`
      width: 330px;
      font-size: 20px;
      line-height: 16px;
    `}
`
export const ContButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 30px;
    justify-content: start;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 35px;
    justify-content: start;
  }
`
export const IndentityPhoneImg = styled.img`
  ${props => !props.visible && hiddenStyle};
  height: auto;
  margin-left: 0px;
  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
  @media(min-width: 168px) and (max-width: 499px){
    width: 240px;
    height: auto;
    margin-left: 20%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  @media(min-width: 500px) and (max-width: 659px){
    width: 240px;
    height: auto;
    margin-left: 25%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  @media(min-width: 660px) and (max-width: 899px){
    width: 300px;
    height: auto;
    margin-left: 30%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  @media(min-width: 900px) and (max-width: 1023px){
    width: 420px;
    height: auto;
    margin-left: 0px;
    text-align: start;
    align-items: flex-start;
    justify-content: flex-start;
  }
`
