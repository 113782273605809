import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material';
import '../../globals/services-styles.css';
import { ContCardEmisionPage, BtnOnbard, ContButton, IndentityPhoneImg } from './CardEmisionPage.styles';
import { IdentityCont, ContTextIdentity, TextIdentitySummary, ContCardIdentity, ContOnb, ContHowWork, TitleHowWork, ContTextDescHowWork, TextDescHowWork } from '../globalpages.styles';
import ButtonSpecial from '../../components/ButtonSpecial';
import ActionDemo from '../../components/ActionDemo/index';
import cardIdentity from '../../assets/images/Servicios/img-emision.webp';
import emisionService from '../../assets/images/Servicios/img-emision-beneficios.webp';
//import StepsServices from '../../components/StepsServices/index';
import ServicesIntegrations from '../../components/ServicesIntegrations/index';
import ServicesBeneficios from '../../components/ServicesBeneficios/index';
import STP from '../../assets/images/Servicios/stp-services.webp';
import MasterCard from '../../assets/images/Servicios/mastercard-services.webp';
import Visa from '../../assets/images/Servicios/visa-services.webp';

const CarEmisionItems = [
  {
    title: 'Emisión de tarjetas para clientes y empleados',
  },
  {
    title: 'Tarjetas Visa y MasterdCard aceptadas en todos los comercios.',
  },
  {
    title: 'Dispersión de créditos, nóminas y pagos a proveedores. ',
  },
  {
    title: 'Centraliza operaciones bancarias.',
  },
];

export const CardEmisionPage = () => {
  const { t } = useTranslation();
  const imagesToShow = [
    { src: STP, visible: true, alt: 'Logo STP', },
    { src: MasterCard, visible: true, alt: 'Logo MasterCard', },
    { src: Visa, visible: true, alt: 'Logo Visa', },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ContCardEmisionPage id={'ContCardEmisionPage'}>

      <ContCardIdentity id={'ContCardIdentity'}>
        <IdentityCont>
          <Grid container spacing={2} className={'ContCardIdentity GridBlueCard'}>
            <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 1 }} style={{paddingLeft: '5%'}}>
              <ContOnb>
                <BtnOnbard>
                  {t('Emisión de Tarjetas')}
                </BtnOnbard>
              </ContOnb>
              <ContTextIdentity>
                {t('Emite tarjetas para tu empresa')}
              </ContTextIdentity>
              <TextIdentitySummary>
              {t('Genera cuentas y tarjetas VISA o MasterCard como medio de dispersión de crédito, nóminas y pagos a proveedores.')}
              </TextIdentitySummary>
              <ContButton>
                <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'secondary'}/>
              </ContButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} 
              order={{ xs: 2, sm: 2, md: 2 }}
              className={'GridStylesCardImage'}
            >
              <IndentityPhoneImg src={cardIdentity} alt={'Emisión de tarjetas de debito o crédito'} visible={true} width={430}/>
            </Grid>
          </Grid>
        </IdentityCont>
      </ContCardIdentity>

      <ContHowWork id={'ContHowWork'}>
        <TitleHowWork>
          {t('¿Cómo funciona?')}
        </TitleHowWork>
        <ContTextDescHowWork>
          <TextDescHowWork>
          {t('Centraliza y administra todas tus operaciones en una sola plataforma y enlaza tarjetas para tus clientes y empleados.')}
          </TextDescHowWork>
        </ContTextDescHowWork>

        <ContButton>
          <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'primary'}/>
        </ContButton>
      
      </ContHowWork>

      <ServicesBeneficios items={CarEmisionItems} image={emisionService} alt={'Administración de tarjetas Dynamicore'}/>

      <ServicesIntegrations imagesToShow={imagesToShow} text={'Genera tarjetas bajo la licencia de nuestros proveedores'}/>

      <ActionDemo />
    </ContCardEmisionPage>
  )
}
