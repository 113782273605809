import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import Back1 from '../assets/images/back-home-1.webp';
import SEO from '../components/SEO';
import AnalisisPage from '../pages/AnalisisPage';

export const AnalisisCredito = () => {
  const { t } = useTranslation();
  return (
    <Layout backgroundimage={Back1} actionDemo={false}>
      <SEO
        title="Analiza el Historial de Crédito y Score Crediticio de tus Clientes"
        description={t('Nuestra herramienta de análisis de crédito te brinda información precisa para decisiones financieras acertadas. Optimiza la calidad de tus productos financieros y reduce riesgos')}
        works={t('Core Financiero, Fintech, Onboardig digital, Aprobación de crédito, Gestión de cobranza, Análisis de crédito, Core Bancario, Automatización de procesos, Procesos digitales, Producto financiero, Servicios financieros, Digitalización de procesos, Software financiero, Dispersión, Reportes automatizados, Corebanking, Sector financiero, SOFIPO, SOFOM, CNBV, Condusef, Servicios financieros digitales, Plataforma financiera')}
        bolImage={false}
        ogImage={`dynamicore-preview.png`}
        twitterImage={`dynamicore-preview.png`}
        canonicalUrl="https://dynamicore.io/analisis-crediticio"
      />
      <AnalisisPage/>
    </Layout>
  )
}