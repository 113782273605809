import styled, {css} from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../../../globals/index';

export const ContButtonSend = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    justify-content: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    justify-content: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    justify-content: flex-start;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    justify-content: flex-start;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    justify-content: flex-start;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    justify-content: flex-start;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    justify-content: flex-start;
  }
  @media(min-width: 1920px){
    justify-content: flex-start;
  }
`
export const ContButton = styled.div`
  
  text-align: center;
  align-items: center;
  

  &:active{
    background: ${GlobalColors.colorSecondary};
  }
`
export const CfgBtnLink = css`
  background-color: transparent;
  border: none;
  
  /* Typografhy */
  text-decoration: none;
  font: 15px ${GlobalFonts.fontBold};
  color: ${props => props.theme === 'primary' ? GlobalColors.colorBtnTextPrimary : GlobalColors.colorBtnTextSecondary};
  cursor: pointer;
  
  z-index: 1;
  
  &:hover{
    font: 15px ${GlobalFonts.fontRegular};
    //color: ${GlobalColors.colorSecondary};
  }
  
  &[aria-current]{
    color: ${props => props.color};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding: 10px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    padding: 10px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    padding: 15px;
  }
  @media(min-width: 1920px){
    padding: 15px;
  }
`
export const Btn = styled.button`
  ${CfgBtnLink}
`
export const BtnAnchor = styled.button`
  //padding: 0 !important;
  ${CfgBtnLink}
`
export const BtnText = styled.div`
  margin-right: 10px;
  margin-left: 15px;
`
export const ButtonAnchorBtn = styled.button`
  ${CfgBtnLink}
`
export const ButtonAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`
export const StyledCTA = styled.div`
  position: relative;
  margin: auto;
  padding: 19px 22px;
  transition: all 0.2s ease;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 28px;
    background: #CEE1F8;
    width: 56px;
    height: 56px;
    transition: all 0.3s ease;
    opacity: ${ props => props.theme === 'primary' ? 'none' : props.theme === 'secondary' ? '0.2' : '0.2'}
  }

  span {
    position: relative;
    text-decoration: none;
    font: 15px ${GlobalFonts.fontBold};
    color: ${props => props.theme === 'primary' ? GlobalColors.colorBtnTextPrimary : GlobalColors.colorBtnTextSecondary};
    cursor: pointer;
    vertical-align: initial;
  }

  svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: ${props => props.theme === 'primary' ?  GlobalColors.colorBtnTextPrimary : GlobalColors.colorBtnTextSecondary};
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
  }

  &:hover {
    &:before {
      width: 100%;
      background: #9BC3F3;
    }

    svg {
      transform: translateX(0);
    }
  }

  &:active {
    transform: scale(0.96);
  }
`;

export const BtnCircle = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  left: 0;
  background-color: ${
    props => props.theme === 'primary'
        ? GlobalColors.colorBtnCirclePrimary
        : props.theme === 'secondary'
            ? GlobalColors.colorBtnCircleSecondary
            : GlobalColors.colorBtnCircleTertiary
  };
  opacity: ${
    props => props.theme === 'primary'
        ? 1
        : props.theme === 'secondary'
          ? 0.2
          : 1
  };
  border-radius: 50%;
  z-index: -1;
`
