import styled from 'styled-components';
import {GlobalFonts} from '../../globals/';
import {GlobalColors} from '../../globals';

export const ContHome = styled.div`
  
`
export const ContTitleHome = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  z-index: 1;
  
  @media(min-width: 300px) and (max-width: 600px){
    width: 100%;
    margin-bottom: 10px;
    
    justify-content: left;
    align-items: left;
    text-align: left;
  }

  @media(min-width: 601px) and (max-width: 767px){
    width: 100%;
    margin-bottom: 10px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 80%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const TitleHome = styled.h1`
  width: 60%;
  margin-bottom: 15px;
  font-family: ${GlobalFonts.fontBold};
  font-size: 64px;
  line-height: 70px;
  text-align: center;

  z-index: 1;

  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
    font-size: 40px;
    line-height: 46px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
    font-size: 50px;
    line-height: 60px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 80%;
    font-size: 50px;
    line-height: 60px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 70%;
    font-size: 50px;
    line-height: 60px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 80%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 70%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 70%;
  }
  @media(min-width: 1920px){
    width: 60%;
  }
`
export const SubTitleHome = styled.div`
  width: 60%;
  margin-bottom: 20px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: ${GlobalColors.colorText};

  z-index: 1;
  
  & > span {
    font-family: ${GlobalFonts.fontBold};
  }

  @media(min-width: 300px) and (max-width: 600px){
    width: 100%;
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 22px;
    text-align: left !important;
  }
  @media(min-width: 601px) and (max-width: 767px){
    width: 100%;
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 22px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
    margin-bottom: 40px;
    line-height: 32px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 80%;
    margin-bottom: 40px;
    line-height: 32px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 70%;
    margin-bottom: 40px;
    line-height: 32px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 70%;
    margin-bottom: 40px;
    line-height: 32px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 65%;
    margin-bottom: 40px;
    line-height: 32px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 60%;
    margin-bottom: 40px;
    line-height: 32px;
  }
  @media(min-width: 1920px){
    width: 50%;
    margin-bottom: 40px;
    line-height: 32px;
  }
`
export const ContImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  margin: 50px 0 70px;
  padding: 0 50px;
  
  z-index: 1;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin: 30px 0 30px;
    padding: 0;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding: 0;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin: 50px 0 70px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    margin: 50px 0 70px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin: 50px 0 150px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin: 50px 0 150px;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    margin: 50px 0 150px;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    margin: 50px 0 150px;
  }
  @media(min-width: 1920px){
    margin: 50px 0 150px;
  }
`
export const ImageHome = styled.img`
  width: 100%;
  height: auto;
  z-index: 3;
`


export const ContBack2 = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;
  
  @media(min-width: 300px) and (max-width: 767px){
    top: 570px;
  }
  @media(min-width: 300px) and (max-width: 529px){
    background-color: #0C3A72;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    top: 600px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    top: 900px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    top: 900px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    top: 950px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    top: 950px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    top: 950px;
  }
  @media(min-width: 1920px){
    top: 850px;
  }
`
export const Back2 = styled.img`
  width: 100%;

  @media(min-width: 300px) and (max-width: 529px){
    height: 630px;
    opacity: 0.1;
  }
  @media(min-width: 530px) and (max-width: 656px){
    height: 600px;
  }
  @media(min-width: 657px) and (max-width: 767px){
    height: 750px;
  }
  @media(min-width: 768px) and (max-width: 899px){
    height: 1150px;
  }
  @media(min-width: 900px) and (max-width: 1023px){
    height: 1450px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    height: auto;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    height: auto;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    height: 800px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    height: 800px;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    height: auto;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    height: auto;
  }
  @media(min-width: 1920px){
    height: auto;
  }
`
export const ContButton = styled.div`
@media(min-width: 300px) and (max-width: 320px){
  text-align: left !important;
  margin-left: -22%;
}
@media(min-width: 321px) and (max-width: 350px){
  margin-left: -25%;
}
@media(min-width: 351px) and (max-width: 370px){
  margin-left: -31%;
}
@media(min-width: 371px) and (max-width: 390px){
  margin-left: -33%;
}
@media(min-width: 391px) and (max-width: 410px){
  margin-left: -33%;
}
@media(min-width: 411px) and (max-width: 430px){
  margin-left: -36%;
}
@media(min-width: 431px) and (max-width: 450px){
  margin-left: -39%;
}
@media(min-width: 451px) and (max-width: 470px){
  margin-left: -41%;
}
@media(min-width: 471px) and (max-width: 490px){
  margin-left: -43%;
}
@media(min-width: 491px) and (max-width: 510px){
  margin-left: -45%;
}
@media(min-width: 511px) and (max-width: 530px){
  margin-left: -48%;
}
@media(min-width: 531px) and (max-width: 550px){
  margin-left: -50%;
}
@media(min-width: 551px) and (max-width: 570px){
  margin-left: -53%;
}
@media(min-width: 571px) and (max-width: 679px){
  margin-left: -55%;
}
@media(min-width: 580px) and (max-width: 600px){
  margin-left: -58%;
}
`