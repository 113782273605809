import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContTabsTransformationStartsNow,
  GlobalStylesTransform,
  ContPhone,
  TitleTransform,
  ContInfoTransform,
  TextTransform, ContButtonTransform, LabelTab,
} from './TabsTransformationStartsNow.styles';
import {Box, Tabs, Tab, Typography, Grid} from '@mui/material';
import ButtonSpecial from '../ButtonSpecial';
import {useWindowDimensions} from '../../utils';
import ImgTabPanel1 from '../../assets/images/onboardig-digital.webp';
import ImgTabPanel2 from '../../assets/images/plataforma-financiera.webp';
import ImgTabPanel3 from '../../assets/images/automatizacion-de-operaciones.webp';
import ImgTabPanel4 from '../../assets/images/dispersion-y-cobranza.webp';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      className={'vertical-tabpanel'}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  // console.log('index =>', index);
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export const TabsTransformationStartsNow = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const { width } = useWindowDimensions();

  const handleChange = (event, newValue) => {
    // console.log('event =>', event);
    // console.log('newValue =>', newValue);
    setValue(newValue);
  };

  return (
    <ContTabsTransformationStartsNow>
      <GlobalStylesTransform/>
      <Box
        className={'transform-tabs'}
        sx={{ flexGrow: 1, display: 'flex' }}
      >
        <Tabs
          orientation={width >= 1024 ? 'vertical' : 'horizontal'}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label=""
        >
          <Tab style={width >= 1024 ? { marginBottom: '2.5rem' } : { marginBottom: '0.5rem' }} label={<LabelTab id={'labeltap1'}>01</LabelTab>} {...a11yProps(0)} />
          <Tab style={width >= 1024 ? { marginBottom: '2.5rem' } : { marginBottom: '0.5rem' }} label={<LabelTab id={'labeltap2'}>02</LabelTab>} {...a11yProps(1)} />
          <Tab style={width >= 1024 ? { marginBottom: '2.5rem' } : { marginBottom: '0.5rem' }} label={<LabelTab>03</LabelTab>} {...a11yProps(2)} />
          <Tab style={width >= 1024 ? { marginBottom: '2.5rem' } : { marginBottom: '0.5rem' }} label={<LabelTab>04</LabelTab>} {...a11yProps(3)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <ContInfoTransform>
                <TitleTransform>
                {t('Onboarding Digital')}
                </TitleTransform>
                <TextTransform>
                  {t('Permite la verificación de identidad digital de tus clientes mediante biométricos, OCR, voz, en instituciones gubernamentales y las principales listas negras como SAT, OFAC, DEA, etc.')}
                </TextTransform>
                {/*<UlTextTransform>
                  <LiTextTransform>El cliente crea una cuenta</LiTextTransform>
                  <LiTextTransform>El cliente registra su información</LiTextTransform>
                  <LiTextTransform>El cliente toma captura de su ID y Face ID para verificar que su información es correcta</LiTextTransform>
                </UlTextTransform>*/}
                <ContButtonTransform>
                  <ButtonSpecial
                    text={'Ver más'}
                    type={'/'}
                    location={'/'}
                  />
                </ContButtonTransform>
              </ContInfoTransform>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ContPhone>
                <img src={ImgTabPanel1} alt={'Onboarding digital Dynamicore'} className={'ImgTabPanel1'} width={''} height={''}/>
              </ContPhone>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <ContInfoTransform>
                <TitleTransform>
                {t('Administración de clientes')}
                </TitleTransform>
                <TextTransform>
                {t('Administra y genera perfiles de tus clientes en un solo lugar con nuestros módulos de administración de clientes.')}
                </TextTransform>
                <ContButtonTransform>
                  <ButtonSpecial
                    text={'Ver más'}
                    type={'/'}
                    location={'/'}
                  />
                </ContButtonTransform>
              </ContInfoTransform>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ContPhone>
                <img src={ImgTabPanel2} alt={'Administración de clientes Dynamicore'} className={'ImgTabPanel2'}/>
              </ContPhone>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <ContInfoTransform>
                <TitleTransform>
                  {t('Automatización de procesos')}
                </TitleTransform>
                <TextTransform>
                {t('Deja de realizar largos procesos, hazlo todo de manera digital en cuestión de minutos.')}
                </TextTransform>
                <ContButtonTransform>
                  <ButtonSpecial
                    text={'Ver más'}
                    type={'/'}
                    location={'/'}
                  />
                </ContButtonTransform>
              </ContInfoTransform>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ContPhone>
                <img src={ImgTabPanel3} alt={'Automatización de procesos'} className={'ImgTabPanel3'}/>
              </ContPhone>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <ContInfoTransform>
                <TitleTransform>
                {t('Cobranza y dispersión')}
                </TitleTransform>
                <TextTransform>
                {t('Facilitamos la gestión de cobranza y dispersión con procesos confiables y seguros.')}
                </TextTransform>
                <ContButtonTransform>
                  <ButtonSpecial
                    text={'Ver más'}
                    type={'/'}
                    location={'/'}
                  />
                </ContButtonTransform>
              </ContInfoTransform>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ContPhone>
                <img src={ImgTabPanel4} alt={'Dispersión y cobranza'} className={'ImgTabPanel4'}/>
              </ContPhone>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </ContTabsTransformationStartsNow>
  )
}
