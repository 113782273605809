import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContTransformationStartsNow,
  ContTransformationStartsNowBack,
  GlobalStylesTransformStartNow,
  TitleTransform,
} from './TransformationStartsNow.styles';
import {Grid} from '@mui/material';
import TabsTransformationStartsNow from '../TabsTransformationStartsNow';

export const TransformationStartsNow = () => {
  const { t } = useTranslation();
  return (
    <ContTransformationStartsNow id={'ContTransformationStartsNow'}>
      <GlobalStylesTransformStartNow/>
      <ContTransformationStartsNowBack/>
      <div className={'container-fit'}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} className={'contZIndex'}>
            <TitleTransform>
            {t('Transformación financiera')}
            </TitleTransform>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TabsTransformationStartsNow/>
          </Grid>
        </Grid>
      </div>
    </ContTransformationStartsNow>
  )
}
