import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material';
import '../../globals/services-styles.css';
import {ContCobranzaPage, BtnOnbard, ContButton, IndentityPhoneImg } from './CobranzaPage.styles';
import { IdentityCont, ContTextIdentity, TextIdentitySummary, ContCardIdentity, ContOnb, ContHowWork, TitleHowWork, ContTextDescHowWork, TextDescHowWork } from '../globalpages.styles';
import ButtonSpecial from '../../components/ButtonSpecial';
import ActionDemo from '../../components/ActionDemo/index';
import phoneCobranza from '../../assets/images/Servicios/img-cobranza-example.webp';
import StepsServices from '../../components/StepsServices/index';
import ServicesIntegrations from '../../components/ServicesIntegrations/index';
import ServicesBeneficios from '../../components/ServicesBeneficios/index';
import PayServicesView from '../../assets/images/Servicios/img-cobranza-beneficios.webp';
import STP from '../../assets/images/Servicios/stp-services.webp';
import Pagaqui from '../../assets/images/Servicios/pagaqui-servcices.webp';
import OxxoCobranza from '../../assets/images/Servicios/oxxo-cobranza.webp';

const CobranzaItems = [
  {
    title: 'Agiliza la recepción de pagos.',
  },
  {
    title: 'Tus clientes tienen diferentes opciones de pago.',
  },
  {
    title: 'Confirmación en tiempo real.',
  }
];
const stepsCobranza = [
  { number: 1, title: 'Notificación', subtitle: 'El cliente recibe una notificación o recordatorio de pago.' },
  { number: 2, title: 'Medio de pago ', subtitle: 'El cliente entra a la plataforma y selecciona el medio de pago.' },
  { number: 3, title: 'Referencia ', subtitle: 'Se genera una referencia única para que el cliente acuda a su sucursal más cercana y realize el pago.'},
  { number: 4, title: 'Pago exitoso ', subtitle: 'Recibe la notificación de que tu cliente realizó el pago con éxito.' }
];

export const CobranzaPage = () => {
  const { t } = useTranslation();
  const imagesToShow = [
    { src: STP, visible: true, alt: 'Logo STP', },
    { src: Pagaqui, visible: true, alt: 'Logo Pagaqui', },
    { src: OxxoCobranza, visible: true, alt: 'Logo OXXO pay', },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ContCobranzaPage id={'ContCobranzaPage'}>

      <ContCardIdentity id={'ContCardIdentity'}>
        <IdentityCont>
          <Grid container spacing={2} className={'ContCardIdentity GridBlueCard'}>
            <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 1 }} style={{paddingLeft: '5%'}}>
              <ContOnb>
                <BtnOnbard>
                  {t('Cobranza')}
                </BtnOnbard>
              </ContOnb>
              <ContTextIdentity>
                {t('Los mejores medios de pago para tus clientes')}
              </ContTextIdentity>
              <TextIdentitySummary>
              {t('Extendemos medios de cobranza con referencia única para pagar en tiendas de conveniencia, abarrotes y bancos con confirmación en tiempo real.')}
              </TextIdentitySummary>
              <ContButton>
                <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'secondary'}/>
              </ContButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} 
              order={{ xs: 1, sm: 1, md: 1 }}
              className={'GridStylesCardImage'}
            >
              <IndentityPhoneImg src={phoneCobranza} alt={'Medios de pago Dynamicore'} width={430}/>
            </Grid>
          </Grid>
        </IdentityCont>
      </ContCardIdentity>

      <ContHowWork id={'ContHowWork'}>
        <TitleHowWork>
          {t('¿Cómo funciona?')}
        </TitleHowWork>
        <ContTextDescHowWork>
          <TextDescHowWork>
            {t('Tus clientes eligen el medio de pago que más se les facilite y se genera un número de referencia para el pago de tu producto financiero.')}
          </TextDescHowWork>
        </ContTextDescHowWork>

        <StepsServices steps={stepsCobranza} version={2} />

        <ContButton>
          <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'primary'}/>
        </ContButton>
      
      </ContHowWork>

      <ServicesBeneficios items={CobranzaItems} image={PayServicesView} alt={'Pago en plataforma Dynamicore'} />

      <ServicesIntegrations imagesToShow={imagesToShow} text={'Llega a distintos puntos de la república con nuestros proveedores'}/>

      <ActionDemo />
    </ContCobranzaPage>
  )
}
