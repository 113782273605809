import React from 'react';
import Layout from '../components/Layout';
import Back1 from '../assets/images/back-home-1.webp';
import SEO from '../components/SEO';
import Error404 from '../components/Error404';

export const NotFound = () => {

  return (
    <Layout backgroundimage={Back1} actionDemo={false}>
      <SEO
        title="404 - Página no encontrada"
        description="Página no encontrada, regresa al inicio"
        works='Core Financiero, Fintech, Onboardig digital, Aprobación de crédito, Gestión de cobranza, Análisis de crédito, Soluciones financieras, Automatización de procesos, Procesos digitales, Producto financiero, Servicios financieros, Digitalización de procesos,
Software financiero, Dispersión, Reportes automatizados, Corebanking, Sector financiero, SOFIPO, SOFOM, CNBV, Condusef, Servicios financieros digitales, Plataforma financiera'
        bolImage={false}
        ogImage={`dynamicore-preview.png`}
        twitterImage={`dynamicore-preview.png`}
        canonicalUrl="https://dynamicore.io/index.html"
      />
      <Error404/>
    </Layout>
  )
}
