const GlobalColors = {
  colorMenuPrimary: `#1F375B`,
  colorMenuSecondary: `#20BDFF`,
  colorMenuMobilePrimary: `#1F375B`,
  colorMenuMobileSecondary: `#20BDFF`,
  colorMenuMobileBG: `linear-gradient(49.73deg, #0C3A72 -1.74%, #0B6BDD 85.52%)`,
  colorBorder: `#20BDFF`,
  colorBorderDark: `#4DCAFF`,
  colorPrimary: `#0B6BDD`,
  colorPrimaryLight: `#6DA6EB`,
  colorPrimaryDark: `#0C3A72`,
  colorSecondary: `#FFFFFF`,
  colorSecondaryLight: `#FFFFFF`,
  colorSecondaryDark: `#FFFFFF`,
  colorSuccess: `#A5FECB`,
  colorDanger: `#EB5757`,
  colorDangerDark: `#EB5757`,
  colorWarning: `#f7b731`,
  colorInfo: `#5433FF`,
  colorLight: `#f7f9fc`,
  colorDark: `#4F4F4F`,
  colorGrayLight: `#8F8F8F`,
  colorGraySuperLight: `#c8c7c7`,
  colorShadowRGB: `75, 101, 132`,
  colorLink: `#1F375B`,
  colorLinkHover: `#79879D`,
  colorBtnTextPrimary: `#0C3A72`,
  colorBtnTextPrimaryHover: `#FFFFFF`,
  colorBtnTextSecondary: `#FFFFFF`,
  colorBtnTextSecondaryHover: `#0C3A72`,
  colorBtnCirclePrimary: `#CEE1F8`,
  colorBtnCircleSecondary: `#CEE1F8`,
  colorBtnCircleTertiary: `#2C6BDD`,
  colorText: `#282828`,
  colorTextSecondary: `#20BDFF`,
  colorTextDiscreet: `#BDBAE4`,
  colorMenuFooterPrimary: `#0054E5`,
  gradientColor: `linear-gradient(to top right, #5433ff 0%, #a5fecb 100%)`,
}
export default GlobalColors;
