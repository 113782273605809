import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContFooter,
  ContTextFooterCenter, ContTextFooterLeft, ContTextFooterRight, IcoFooter,
  TextFooter, TextFooterCenter, TextFooterLeft, TextFooterRight,
} from './Footer.styles';
import { Link } from "react-router-dom";
import {Grid} from '@mui/material';
import GithubIcon from '../../assets/images/ico-github.svg';
import Instagram from '../../assets/images/ico-instagram.svg';
import FacebookIcon from '../../assets/images/ico-facebook.svg';
import LinkedInIcon from '../../assets/images/ico-linkedin.svg';

const URL_TERMINOS = '/terminos-y-condiciones';
const URL_AVISO = '/aviso-de-privacidad';
const URL_GITHUB = 'https://github.com/fintcore';
const URL_INSTAGRAM = 'https://www.instagram.com/dynamicore_oficial';
const URL_FACEBOOK = 'https://facebook.com/dynamicore/';
const URL_LINKEDIN = 'https://linkedin.com/company/dynamicore-system/';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <ContFooter>
      <div className={'container-fit'}>
        <Grid container spacing={2} style={{marginLeft: '0px !important'}}>
          <Grid item xs={12} sm={12} md={4} className={'no-padding'}>
            <ContTextFooterLeft>
              <TextFooterLeft>
                <TextFooter>© 2024 DynamiCore, México City</TextFooter>
              </TextFooterLeft>
            </ContTextFooterLeft>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className={'no-padding'}>
            <ContTextFooterCenter>
              <TextFooterCenter>
                <TextFooter>
                  <Link to={URL_TERMINOS} id={'terms'} rel="noreferrer">
                  {t('Términos y condiciones')}
                  </Link>
                </TextFooter>
                <TextFooter>|</TextFooter>
                <TextFooter>
                  <Link to={URL_AVISO} id={'priv'} rel="noreferrer">
                  {t('Aviso de privacidad')}
                  </Link>
                </TextFooter>
                <TextFooter><a href={'/sitemap'} id={'priv'} rel="noreferrer"></a></TextFooter>
              </TextFooterCenter>
            </ContTextFooterCenter>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className={'no-padding'}>
            <ContTextFooterRight>
              <TextFooterRight>
                <IcoFooter><a href={URL_GITHUB} target={'_blank'} id={'btngithub'} rel="noreferrer"><img src={GithubIcon} width={'auto'} height={'auto'} alt={'Github Dynamicore'}/></a></IcoFooter>
                <IcoFooter><a href={URL_INSTAGRAM} target={'_blank'} id={'btnintagram'} rel="noreferrer"><img src={Instagram} width={30} height={'auto'} alt={'Instagram Dynamicore'}/></a></IcoFooter>
                <IcoFooter><a href={URL_FACEBOOK} target={'_blank'} id={'btnfacebook'} rel="noreferrer"><img src={FacebookIcon} width={'auto'} height={'auto'} alt={'Facebook Dynamicore'}/></a></IcoFooter>
                <IcoFooter><a href={URL_LINKEDIN} target={'_blank'} id={'btnLinkedin'} rel="noreferrer"><img src={LinkedInIcon} width={'auto'} height={'auto'} alt={'Linkedin Dynamicore'}/></a></IcoFooter>
              </TextFooterRight>
            </ContTextFooterRight>
          </Grid>
        </Grid>
      </div>
      <script type="text/javascript">
      {`
        _linkedin_partner_id = "5799980";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        </script><script type="text/javascript">
        (function(l) {
        if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
        window.lintrk.q=[]}
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);})(window.lintrk);
      `}
      </script>
      <noscript>
        <img height="1" width="1" style={{ display: 'none' }} alt="" src="https://px.ads.linkedin.com/collect/?pid=5799980&fmt=gif" />
      </noscript>
    </ContFooter>
  );
};

export default Footer;
