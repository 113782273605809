import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  ContStepServices,
  ContStepServices2,
  StepIndicatorWrapper,
  StepIndicatorWrapper2,
  StepIndicatorWrapper3,
  StepWrapper,
  StepWrapper2,
  ContCircle,
  Circle,
  Circle2,
  Line,
  Line2,
  Line22,
  Line3,
  Title,
  Title2,
  ContSubtitle,
  SubTitle,
} from './StepsServices.styles';

export const StepsServices = ({ steps, version }) => {
  const { t } = useTranslation();
  let content;
  const stepWidth = 100 / steps.length;
  if (version === 1) { 
    content = (
    <ContStepServices>
      <StepIndicatorWrapper>
      {steps.map((step, index) => (
        <StepWrapper key={index}>
          <ContCircle>
            <Circle>
              {step.number}
            </Circle>
            <Title>{t(step.title)}</Title>
            <ContSubtitle id={'ContSubtitle'}>
              <SubTitle>{t(step.subtitle)}</SubTitle>
            </ContSubtitle>
          </ContCircle>
          {index < steps.length - 1 && <Line />}
        </StepWrapper>
      ))}
      </StepIndicatorWrapper>
    </ContStepServices>
    );
  } else if (version === 2) {
    content = (
      <ContStepServices2>
        <StepIndicatorWrapper2>
        {steps.map((step, index) => (
          <StepWrapper2 key={index} customWidth={stepWidth}>
            {index < steps.length - 1 && <Line22 />}
            <Circle2>
              {step.number}
            </Circle2>
            <Title2>{t(step.title)}</Title2>
            <SubTitle>{t(step.subtitle)}</SubTitle>
            {index < steps.length - 1 && <Line2 />}
          </StepWrapper2>
        ))}
        </StepIndicatorWrapper2>
      </ContStepServices2>
    );
  } else if (version === 3) {
    content = (
      <ContStepServices>
        <StepIndicatorWrapper3>
        {steps.map((step, index) => (
          <StepWrapper key={index}>
            <ContCircle>
              <Circle>
                {step.number}
              </Circle>
              <Title>{t(step.title)}</Title>
              <ContSubtitle>
                <SubTitle>{t(step.subtitle)}</SubTitle>
              </ContSubtitle>
            </ContCircle>
            {index < steps.length - 1 && <Line3 />}
          </StepWrapper>
        ))}
        </StepIndicatorWrapper3>
      </ContStepServices>
    );
  }
  return content;
}

StepsServices.defaultProps = {
  steps: [],
  version: 1,
};

StepsServices.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    })
  ).isRequired,
  version: PropTypes.number,
};
