import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';
import ImgBack from '../../assets/images/back-action-demo.svg';

export const ContActionDemo = styled.div`
  width: 100%;
  height: auto;
  background-color: ${GlobalColors.colorPrimaryDark};
  background-image: url(${ImgBack});
  background-repeat: no-repeat;
  background-size: cover;
  //clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
  
  @media(min-width: 300px) and (max-width: 767px){
    padding: 80px 0;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding: 80px 0;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding: 80px 0;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    padding: 80px 0;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding: 85px 0;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding: 100px 0;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    padding: 100px 0;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    padding: 110px 0;
  }
  @media(min-width: 1920px){
    padding: 120px 0;
  }
`
export const TitleActionDemo = styled.div`
  position: relative;
  margin: 0 auto 20px;
  font-family: ${GlobalFonts.fontBold};
  font-size: 48px;
  line-height: 56px;
  color: ${GlobalColors.colorSecondary};
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
    line-height: 38px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 40px;
    line-height: 50px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 44px;
    line-height: 36px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 34px;
    line-height: 46px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 46px;
    line-height: 56px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 46px;
    line-height: 56px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 46px;
    line-height: 56px;
  }
  @media(min-width: 1920px){
    font-size: 46px;
    line-height: 56px;
  }
`
export const TextActionDemo = styled.div`
  position: relative;
  margin: 0 auto 40px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 20px;
  line-height: 28px;
  color: ${GlobalColors.colorSecondary};
  text-align: center;

  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 70%;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 60%;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 50%;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 50%;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 50%;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1440px) and (max-width: 1639px){
    width: 45%;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1640px) and (max-width: 1919px){
    width: 40%;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1920px){
    width: 34%;
    font-size: 20px;
    line-height: 28px;
  }
`
export const ContBtnActionDemo = styled.div`
  //padding-bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`
