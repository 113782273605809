import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContStatistics,
  ContStatisticsBack,
  TextStatistics,
  TitleStatistics,
  ContStatisticsCounters, ContVerticalLineV, ContVerticalLineH, ContItem,
} from './Statistics.styles';
import StatisticsCounter from '../StatisticsCounter';
import ButtonSpecial from '../ButtonSpecial';
import {Grid} from '@mui/material';

export const Statistics = () => {
  const { t } = useTranslation();
  return (
    <ContStatisticsBack>
      <div className={'section-first container-fit'}>
        <ContStatistics id={'ContStatistics'}>
          <TitleStatistics>
          {t('Plataforma centralizada')}
          </TitleStatistics>
          <TextStatistics>
          {t('Administra e integra productos financieros gestionando los módulos que mejor se adapten a las necesidades de tu empresa.')}
          </TextStatistics>
          <ContStatisticsCounters id={'ContStatisticsCounters'}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={2.4} lg={2.4} xl={2.4}>
                <ContItem>
                  <StatisticsCounter counter={3} label={'Años en el mercado'}/>
                  <ContVerticalLineV/>
                  <ContVerticalLineH/>
                </ContItem>
              </Grid>
              <Grid item xs={12} sm={6} md={2.4} lg={2.4} xl={2.4}>
                <ContItem>
                  <StatisticsCounter counter={30} label={'Clientes'}/>
                  <ContVerticalLineV/>
                  <ContVerticalLineH/>
                </ContItem>
              </Grid>
              <Grid item xs={12} sm={6} md={2.4} lg={2.4} xl={2.4}>
                <ContItem>
                  <StatisticsCounter counter={12} label={'Módulos'}/>
                  <ContVerticalLineV/>
                  <ContVerticalLineH/>
                </ContItem>
              </Grid>
              <Grid item xs={12} sm={6} md={2.4} lg={2.4} xl={2.4}>
                <ContItem>
                  <StatisticsCounter counter={189} label={'Productos financieros lanzados'}/>
                  <ContVerticalLineV/>
                  <ContVerticalLineH/>
                </ContItem>
              </Grid>
              <Grid item xs={12} sm={12} md={2.4} lg={2.4} xl={2.4}>
                <ContItem>
                  <StatisticsCounter counter={20} label={'Integraciones'}/>
                </ContItem>
              </Grid>
            </Grid>
          </ContStatisticsCounters>
          <ContStatisticsCounters>
            <ButtonSpecial text={'Conoce nuestros servicios'} type={'/'} location={'/servicios'}/>
          </ContStatisticsCounters>
        </ContStatistics>
      </div>
    </ContStatisticsBack>
  );
};
