import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContFooter = styled.footer`
  position: relative;
  width: 100%;
  height: auto;
  background-color: #FBFFFF;
  z-index: 1;
`
export const ContFooterInner = styled.div`
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 90%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 70%;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 70%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 70%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 70%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 70%;
  }
  @media(min-width: 1920px){
    width: 70%;
  }
`
export const ContTextFooterLeft = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  @media(min-width: 300px) and (max-width: 1023px){
    justify-content: center;
  }
`
export const ContTextFooterCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ContTextFooterRight = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media(min-width: 300px) and (max-width: 1023px){
    justify-content: center;
  }
`
export const TextFooter = styled.div`
  padding: 35px 0;
  font: 15px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: #0C3A72;
  
  & > a {
    color: #0C3A72;
    text-decoration: none;
  }
  
  @media(min-width: 300px) and (max-width: 1023px){
    padding: 20px 0;
  }
`
export const IcoFooter = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  & > a {
    color: ${GlobalColors.colorMenuFooterPrimary};
  }

  @media(min-width: 300px) and (max-width: 1023px){
    padding: 20px 0;
  }
`
export const TextFooterLeft = styled.div`
  width: auto;
  height: auto;
`
export const TextFooterCenter = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  
  & > div {
    padding: 0 5px;
  }
`
export const TextFooterRight = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`
