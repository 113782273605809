import {createGlobalStyle} from 'styled-components';
import GlobalColors from './Colors';
import {GlobalFonts} from './Fonts';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0px;
  }
  .z-index-cont {
    z-index: 9;
  }
  .full-width {
    width: 100% !important;
  }
  .center{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .center-in-column{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .right-in-column{
    display: flex;
    justify-content: flex-end;
  }
  .no-margin {
    margin: 0 !important;
  }
  .no-padding {
    padding: 0 !important;
  }
  .no-padding-desk {
    @media(min-width: 300px) and (max-width: 1023px){
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    @media(min-width: 1024px){
      padding: 0 !important;
    }
  }
  .no-padding-left {
    padding-left: 0 !important;
  }
  .no-padding-right {
    padding-right: 0 !important;
  }
  .no-padding-top {
    padding-top: 0 !important;
  }
  .no-padding-top-mobile {
    @media(min-width: 300px) and (max-width: 1023px){
      padding-top: 0 !important;
    }
  }
  .grid-fit{
    width: 100% !important;
    margin-left: 0 !important;
  }
  
  .no-show-mobile {
    @media(min-width: 300px) and (max-width: 1023px){
      display: none !important;
    }
    @media(min-width: 1024px){
      display: block !important;
    }
  }
  .no-show-desk {
    @media(min-width: 1024px){
      display: none !important;
    }
  }
  
  .section-first {
    padding-top: 120px !important;

    @media(min-width: 300px) and (max-width: 767px){
      padding-top: 70px !important;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      padding-top: 100px !important;
    }
    @media(min-width: 1024px) and (max-width: 1279px){
      padding-top: 120px !important;
    }
    @media(min-width: 1280px) and (max-width: 1407px){
      padding-top: 120px !important;
    }
    @media(min-width: 1408px) and (max-width: 1439px){
      padding-top: 120px !important;
    }
    @media(min-width: 1440px) and (max-width: 1639px){
      padding-top: 120px !important;
    }
    @media(min-width: 1640px) and (max-width: 1919px){
      padding-top: 120px !important;
    }
    @media(min-width: 1920px) and (max-width: 3200px){
      padding-top: 120px !important;
    }
  }
  .container-fit {
    @media(min-width: 300px) and (max-width: 767px){
      margin: 0 20px !important;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      margin: 0 12px !important;
    }
    @media(min-width: 1024px) and (max-width: 1279px){
      margin: 0 62px !important;
    }
    @media(min-width: 1280px) and (max-width: 1407px){
      margin: 0 92px !important;
    }
    @media(min-width: 1408px) and (max-width: 1439px){
      margin: 0 92px !important;
    }
    @media(min-width: 1440px) and (max-width: 1919px){
      margin: 0 92px !important;
    }
    @media(min-width: 1920px) and (max-width: 3200px){
      margin: 0 192px !important;
    }
  }
  .alice-carousel__stage-item > div.item {
    background-position: center center;
  }
  
  .button-gradient {
    font: 15px ${GlobalFonts.fontBold};
    color: ${GlobalColors.colorBtnTextPrimary};
    color: #fff;
    display: block;
    margin-right: 20px;
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    transition: 0.3s ease all;
    background: linear-gradient(
        73.69deg,
          #5433ff -18.91%,
        #20bdff 65.98%,
        #a5fecb 124.27%
    );
    border-radius: 10px;

    &:hover {
      background: linear-gradient(
          298.69deg,
            #5433ff -18.91%,
          #20bdff 65.98%,
          #a5fecb 124.27%
      );
    }
    &:active{
      background: ${GlobalColors.colorSecondary};
    }
    @media (min-width: 300px) and (max-width: 767px) {
      margin: 0px;
      font-size: 13px;
      width: 215px;
      height: 50px;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 13px;
      width: 200px;
      height: 50px;
    }

    @media (min-width: 1024px) and (max-width: 1079px) {
      font-size: 13px;
      width: 200px;
      height: 50px;
    }
    @media (min-width: 1080px) and (max-width: 1279px) {
      font-size: 13px;
      width: 200px;
      height: 60px;
    }
    @media (min-width: 1280px) and (max-width: 1407px) {
      font-size: 13px;
      width: 200px;
      height: 70px;
    }
    @media (min-width: 1408px) and (max-width: 1439px) {
      font-size: 13px;
      width: 200px;
      height: 70px;
    }
    @media (min-width: 1440px) and (max-width: 1919px) {
      font-size: 15px;
      width: 300px;
      height: 70px;
    }
    @media (min-width: 1920px) {
      width: 300px;
      height: 70px;
    }
  }
`

export default GlobalStyles;
