import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContOurClients = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding: 30px 0 100px !important;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding: 30px 0 100px !important;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding: 30px 0 100px !important;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    padding: 30px 0 100px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding: 30px 0 100px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding: 30px 0 100px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding: 30px 0 100px !important;
  }
  @media(min-width: 1920px){
    padding: 30px 0 100px !important;
  }
`
export const TitleOurClients = styled.h6`
  margin-top: 0px;
  margin-bottom: 35px;
  font-family: ${GlobalFonts.fontBold};
  font-size: 48px;
  line-height: 56px;
  color: ${GlobalColors.colorPrimaryDark};
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
    line-height: 35px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 40px;
    line-height: 35px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font-size: 36px;
    line-height: 38px;
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    font-size: 34px;
    line-height: 46px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 46px;
    line-height: 56px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font-size: 46px;
    line-height: 56px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font-size: 46px;
    line-height: 56px;
  }
  @media(min-width: 1920px){
    font-size: 46px;
    line-height: 56px;
  }
`
export const ContItemsClients = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 70px;
  @media(min-width: 200px) and (max-width: 1024px){
    flex-wrap: wrap;
  }
`
export const ContItemClients = styled.div`
  width: auto;
  height: auto;
  margin: 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img{
    padding-bottom: 35px;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 40%;

    & > img{
      width: 100%;
    } 
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 40%;

    & > img{
      width: 60%;
    }
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 15%;

    & > img{
      width: 100%;
    }
  }
  @media(min-width: 1080px) and (max-width: 1279px){
    width: 15%;

    & > img{
      width: 100%;
    }
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 15%;

    & > img{
      width: 100%;
    }
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 15%;

    & > img{
      width: 100%;
    }
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 15%;

    & > img{
      width: 100%;
    }
  }
  @media(min-width: 1920px) and (max-width: 3000px){
    width: 15%;
    & > img{
      width: 100%;
    }
  }
`

